
.dl-popup-documents {
	position: fixed;
	background: rgba(4, 0, 16, 0.8);
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 3;
}

.dl-popup-documents__card {
	display: flex;
	position: fixed;
	flex-direction: column;
	background: var(--light-orchid);
	padding: 12px 16px 33px 33px;
	border-radius: 6px 0 0 6px;
	top: 20%;
	right: 0;
	gap: 16px;
}

.dl-popup-documents__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 21px;
}

.dl-popup-documents__icon-container {
	display: flex;
	padding-right: 0;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.dl-popup-documents__icon {
	font-size: 19px;
}

.dl-popup-documents__title {
	font-size: var(--font-h3);
	color: var(--blue-dark);
}

.dl-popup-documents__core {
	display: flex;
}

.dl-popup-documents__core__card {
	padding-left: 16px;
}
