@import "../../../core/theme/variables";

.drive-log-card__container {
	background-color: var(--true-white);
	box-shadow: var(--list-box-shadow);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 16px;
	padding: 19px 24px;
	min-width: 842px;
	min-height: 86px;
	margin-bottom: 16px;
}

.drive-log-card__container--selected {
	border: 2px solid var(--blue-dark);
}

.drive-log-card__left {
	display: flex;
	align-items: center;
}

.drive-log-card__container-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 54px;
	height: 54px;
	background-color: var(--icon-light-purple);
	border-radius: 50%;
}

.drive-log-card__icon {
	width: 32px;
	height: 32px;
}

.drive-log-card__text {
	font-size: var(--font-text-m);
	margin-left: 16px;
}

.drive-log-card__cta {
	font-size: var(--font-text-sm);
}
