.satisfaction-survey {
	height: 100%;
}

.satisfaction-survey__icon-container {
	display: flex;
	flex-direction: row-reverse;
}

.satisfaction-survey__icon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.satisfaction-survey__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.satisfaction-survey__header__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 12px;
}

.satisfaction-survey__header__title--secondary {
	font-size: var(--font-text-l);
	font-weight: normal;
	color: var(--grey-dark);
	margin-bottom: 25px;
}

.satisfaction-survey__form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.satisfaction-survey__form__container {
	display: flex;
	flex-direction: column;
}

.satisfaction-survey__form__radio-list {
	display: flex;
	flex-direction: row;
}

.satisfaction-survey__list-item {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-h2);
	width: 67px;
	height: 67px;
	margin: 0 10px;
	border-radius: 69px;

	&--0 {
		background-color: #800A00;

		&--checked {
			background: transparent;
			border: 2px solid #800A00;
		}
	}
	&--1 {
		background-color: #B30E00;

		&--checked {
			background: transparent;
			border: 2px solid #B30E00;
		}
	}
	&--2 {
		background-color: #E61200;

		&--checked {
			background: transparent;
			border: 2px solid #E61200;
		}
	}
	&--3 {
		background-color: #FF1400;

		&--checked {
			background: transparent;
			border: 2px solid #FF1400;
		}
	}
	&--4 {
		background-color: #FF5B4D;

		&--checked {
			background: transparent;
			border: 2px solid #FF5B4D;
		}
	}
	&--5 {
		background-color: #FF8C4D;

		&--checked {
			background: transparent;
			border: 2px solid #FF8C4D;
		}
	}
	&--6 {
		background-color: #FFB133;

		&--checked {
			background: transparent;
			border: 2px solid #FFB133;
		}
	}
	&--7 {
		background-color: #FFD14D;

		&--checked {
			background: transparent;
			border: 2px solid #FFD14D;
		}
	}
	&--8 {
		background-color: #F2DA2C;

		&--checked {
			background: transparent;
			border: 2px solid #F2DA2C;
		}
	}
	&--9 {
		background-color: #42B82E;

		&--checked {
			background: transparent;
			border: 2px solid #42B82E;
		}
	}
	&--10 {
		background-color: #338F24;

		&--checked {
			background: transparent;
			border: 2px solid #338F24;
		}
	}
}

.satisfaction-survey__label {
	color: var(--true-white);

	&--0 {
		&.selected {
			color: #800A00;
			background: transparent;
		}
	}

	&--1 {
		&.selected {
			color: #B30E00;
			background: transparent;
		}
	}

	&--2 {
		&.selected {
			color: #E61200;
			background: transparent;
		}
	}

	&--3 {
		&.selected {
			color: #FF1400;
			background: transparent;
		}
	}

	&--4 {
		&.selected {
			color: #FF5B4D;
			background: transparent;
		}
	}

	&--5 {
		&.selected {
			color: #FF8C4D;
			background: transparent;
		}
	}

	&--6 {
		&.selected {
			color: #FFB133;
			background: transparent;
		}
	}

	&--7 {
		&.selected {
			color: #FFD14D;
			background: transparent;
		}
	}

	&--8 {
		&.selected {
			color: #F2DA2C;
			background: transparent;
		}
	}

	&--9 {
		&.selected {
			color: #42B82E;
			background: transparent;
		}
	}

	&--10 {
		&.selected {
			color: #338F24;
			background: transparent;
		}
	}
}

.satisfaction-survey__form__legend {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 12px;
	margin-bottom: 32px;
	padding: 0 9px;
}

.satisfaction-survey__form__legend__text {
	color: var(--grey-dark);
	font-size: var(--font-text-sm);
}

.satisfaction-survey__comment {
	display: flex;
	flex-direction: column;
	width: 935px;
	margin-bottom: 12px;
}

.satisfaction-survey__comment__text {
	color: var(--grey-dark);
	font-size: var(--font-text-sm);
	padding-bottom: 12px;
}

.satisfaction-survey__comment__text--bold {
	font-weight: bold;
}

.satisfaction-survey__comment__text-area {
	width: 935px;
	height: 80px;
	background-color: var(--catskill-white);
	margin-bottom: 24px;
}
