.station-row {
	display: flex;
	align-items: center;
	justify-content: center;
}

.station-row-container {
    display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	background: white;
	padding: 12px 19px;
	border-radius: 16px;
	font-size: var(--font-text-l);
	font-weight: bold;
	box-shadow: var(--list-box-shadow); 
    width: 60vw;
	margin-bottom: 16px;
}

.station-row-container__not-served {
    background-color: var(--grey-medium-light);
}

.station-row-container__border-red {
	border: 2px solid #FF1400;
}

.station-row-container__border-blue {
	border: 2px solid #3C91DC;
}

.station-row-container__border-dashed-blue {
	border: 2px dashed #3C91DC;
}

.station-row-container__border-dashed-red {
	border: 2px dashed #FF1400;
}

.station-row__disabled {
	background-color: #EDEDF0;
}

.station-row-hour {
    background: black;
	color: var(--yellow);
	font-size: var(--font-text-xs);
	font-weight: bold;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9px;
}

.station-row-hour__disabled {
	visibility: hidden;
}

.station-row-text {
    font-weight: bold;
    font-size: var(--font-text-large);
    color: var(--black);
    margin-left: 20px;
}

.station-row-text-color__disabled {
	color: #737373;
 }

.station-row-text-color {
	color: var(--black);
}

.station-row__not-served {
	color: var(--alert-red);
}

.station-row-info {
    flex-grow: 1;
    flex-shrink: 1;
}

.station-row-button {
    margin-left: 20px;
}

.station-row-icon {
    margin-right: 10px;
}

.station-row-button-icon {
	margin-bottom: 16px;
    background: transparent;
    border: none;
    cursor: pointer;

    &__delete {
        color: var(--red-light);
        transform: rotate(45deg);
    }

    &__add {
        color: var(--green-dp);
    }
}
