@import "../../../../core/theme/variables";

.drive-log-details-supervisor__content {
	margin: 0 150px;
}

.drive-log-details-supervisor__content__warning-delays {
	display: flex;
	align-items: flex-end;
	margin: 40px 0 60px;
}

.warning-delays__icon {
	font-size: var(--font-text-xxl);
	margin-right: 10px;
}

.warning-delays__text {
	font-size: var(--font-text-s);
	color: var(--black);
}

.drive-log-details-supervisor__controls {
	margin: 40px 8px;
}

.drive-log-details-supervisor__controls__action {
	display: flex;
	justify-content: space-between;
	padding: 0;
}

.drive-log-details-supervisor__controls__pdf {
	font-size: var(--font-text-ml);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	color: var(--blue-dark);
	cursor: pointer;
	margin: 40px 0;
}

.drive-log-details-supervisor__controls__action__main {
	display: flex;
}

.drive-log-details-supervisor__feedback-button {
	top: 300px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.drive-log-details-supervisor__content {
		margin: 0 15px;
	}

	.drive-log-details-supervisor__content__warning-delays {
		display: flex;
		align-items: center;
		margin: 20px 0 30px;
	}
	.warning-delays__text {
		text-align: justify;
	}

	.content__comment--read {
		text-align: justify;
	}
}

@media print {
	.drive-log-details-supervisor__content {
		margin: 0 30px;
	}

	.drive-log-details-supervisor__controls__action {
		display: none;
	}
}
