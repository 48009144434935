@import "../../theme/variables";

.search-bar {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.search-bar__field {
	font-size: var(--font-text-m);
	color: var(--black);
	background-color: white;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
	border: 2px solid transparent;
	border-radius: 12px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	max-height: 50px;
	line-height: 1;

	&:focus-within {
		border: 2px solid var(--blue-light);
	}

	&--error, &--error:focus-within {
		border: 2px solid var(--red-light);
	}
}

.search-bar__columns {
	background: transparent;
	border: none;
	padding: 15px 15px 15px 5px;
	font-size: inherit;
	color: inherit;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	white-space: nowrap;
	position: relative;
}

.search-bar__columns__search {
	font-size: var(--font-text-l);
	color: var(--blue);
	opacity: 0.4;
	margin-right: 20px;
	margin-left: 5px;
}

.search-bar__columns__arrow {
	transform: rotate(0.25turn);
	margin-left: 15px;
	margin-right: 5px;
	font-size: var(--font-text-s);
	color: var(--blue);
}

.search-bar__columns__selected {
	min-width: 140px;
	text-align: left;
}

.search-bar__columns__dropdown {
	position: absolute;
	top: calc(100% + 2px);
	left: -2px;
	width: 100%;
	background-color: white;
	border-radius: 0 0 12px 12px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
	border: 2px solid var(--blue-light);
	border-top: 2px none transparent;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	z-index: 2;
}

.search-bar__columns__dropdown__option {
	width: 100%;
	height: 100%;
	padding: 15px 50px 15px 65px;
	text-align: left;

	&:hover, &:focus {
		background: rgba(244, 224, 240, 1);
		color: var(--blue-light)
	}
}

.search-bar__columns__dropdown__option:last-child {
	border-radius: 0 0 12px 12px;
}

.search-bar__field--open {
	border-radius: 12px 12px 12px 0;
}

.search-bar__input {
	padding: 15px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	white-space: nowrap;
	font-size: inherit;
	min-width: 400px;
}

.search-bar__input::before {
	content: "";
	background-color: var(--blue-light);
	width: 1px;
	height: 100%;
	min-height: 25px;
	display: block;
	margin-left: -15px;
	margin-right: 15px;
}

.search-bar__input__input {
	outline: none;
	font-size: inherit;
	border: none;
	min-width: 350px;
}
