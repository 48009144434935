.dl-to-be-archived-page {
	padding: 25px 50px;
	display: flex;
	flex-direction: column;
}

.dl-to-be-archived-page__title {
	font-size: var(--font-h2);
	font-weight: bold;
	color: var(--black);
}

.dl-to-be-archived-page__controls {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.dl-to-be-archived-page__empty-dls {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dl-to-be-archived-page__searching-mouse-img {
	width: 300px;
	height: auto;
}

.dl-to-be-archived-page__empty-dls-label {
	margin-top: 40px;
	font-size: 28px;
	color: var(--black);
	font-weight: bold;
}
