@import "../../core/theme/variables";

.disable-line-sheets-page__wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px 50px 0 50px;
}

.popup-full-page__content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.disable-line-sheets-page__wrapper__block {
	display: flex;
	flex-direction: column;

	&:first-child {
		margin-bottom: 140px;
	}
}

.disable-line-sheets-page__wrapper__block__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 8px;
}

.disable-line-sheets-page__wrapper__block__sub-title {
	font-size: var(--font-text-xs);
	color: var(--black);
}

.disable-line-sheets-page__wrapper__block__cards {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}


