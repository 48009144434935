@import "../../../../../../core/theme/variables";

.finalize-message {
	display: flex;
	align-items: center;
	margin: 30px 35px 0;
	padding: 12px 15px;
	border-radius: 27px;
	background-color: var(--alert-red-bg);
	font-size: var(--font-text-sm);
}

.finalize-message__icon {
	margin-right: 10px;
	font-size: 24px;
}

@media screen and (max-width: $breakpoint-mobile) {
	.finalize-message {
		margin: 30px 15px 0;
	}
}
