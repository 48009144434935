.feedback-page {
	.feedback-page__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 40px 48px 0 48px;
	}

	h1 {
		font-size: var(--font-h2);
		color: var(--black);
		margin-bottom: 30px;
	}

	.feedbacks-list {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.feedbacks-list__rows {
		display: flex;
		flex-direction: column;
	}

	.feedbacks-list__empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 50px; /* Vous pouvez ajuster cela selon vos besoins */
	}

	.no-feedbacks__image {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		height: 250px;
		width: 300px;
	}

	.no-feedbacks__text {
		margin-top: 20px;
		font-size: 18px;
		color: var(--black);
		text-align: center;
	}
}
