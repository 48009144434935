.survey-form__header {
	display: flex;
	justify-content: space-between;
	min-width: 700px;
	align-items: center;
}

.survey-form__header__title-container {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
}

.survey-form__header__title {
	font-size: var(--font-h2);
	display: inline-block;
}

.survey-form__header__details {
	font-size: var(--font-h4);
	display: inline-block;
}

.survey-form__mission_code {
	flex: 120px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-lilas);
    color: var(--blue-light);
    border-radius: 9px;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
}

.survey-form__header__separator {
	color: var(--green);
	font-weight: bold;
}
