@import "../../../../../../core/theme/variables";

.empty-dl-list {
	display: flex;
	flex-direction: column;
	padding: 30px;
}

.empty-dl-list__controls {
	display: flex;
	justify-content: center;
	margin-bottom: 15px;
	.button:not(:last-of-type) {
		margin-right: 40px;
	}
}

.empty-dl-list__content__title {
	text-align: center;
	font-size: var(--font-h1);
	margin: 60px 0 60px 0;
	color: var(--black);
}

.empty-dl-list__content {
	margin-bottom: 75px;

	.finalize-message {
		margin: 0 -50px 0 -20px;
	}
}

.empty-dl-list__empty-splash {
	background-repeat: no-repeat;
	background-position: center center;
	height: 250px;
}



@media screen and (max-width: $breakpoint-mobile) {
	.empty-dl-list__controls {
		flex: 0 0;
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 10px;
		margin: 0;
		.drive-log-detail__finalize-dl-btn {
			grid-row: 1;
			grid-column: 1 / 3;
		}
		.dl-add-mission-button-form  {
			grid-row: 2;
			grid-column: 1 / 3;
		}
		.add-observation-without-mission-button {
			grid-row: 3;
			grid-column: 1 / 3;
		}

		.button:not(:last-of-type) {
			margin: 0;
		}
	}
}
