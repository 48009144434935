.export-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.export-form__button {
	display: flex;
	justify-content: center;
	margin: 40px 0 47px 0;
}
