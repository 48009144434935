.comment-cell {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--font-text-m);
}

.comment-cell__delay-value {
	font-weight: bold;
	margin-left: 5px;
	font-size: var(--font-text-m);
}

.comment-cell__observations {
	background: var(--blue-very-light);
	color: var(--blue-light);
	font-size: var(--font-text-xs);
	border-radius: 4px;
	padding: 5px;
	flex: 0 0 120px;
	text-align: center;
}
