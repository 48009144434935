@import "../../../../core/theme/variables";

.returned-dl-list-page {
	padding: 60px 65px;
}
.returned-dl-list-page__title {
	color: var(--black);
	font-size: var(--font-h2);
	margin-bottom: 30px;
}

.returned-dl-list-page__controls {
	display: flex;
	justify-content: center;
}

.returned-dl-table {
	width: 100%;
}

.returned-dl__empty-table-warning {
	font-size: var(--font-h2);
	color: var(--grey-dark);
	display: flex;
	justify-content: center;
}

@media screen and (max-width: $breakpoint-mobile) {
	.returned-dl-table {
		padding: 25px 0;
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.returned-dl-list-page {
		padding: 25px 15px;
	}
	.returned-dl-list-page__title {
		text-align: center;
	}
	.returned-dl-list-page .dl-month__title {
		margin: 0 -15px;
	}
}
