@import "../../../../../../../core/theme/variables";

.survey-radio-button {
	width: 100%;
	font-size: var(--font-h3);
	color: var(--grey-dark);
}

.survey-radio-button__title-container {
	display: flex;
	flex-direction: column;
}

.survey-radio-button__question {
	align-self: flex-start;
}

.survey-radio-button__question-explanation {
	font-style: italic;
	align-self: flex-start;
	font-size: small;
	margin-top: 12px;
}

.survey-radio-button__choices {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 25px;

	.survey-radio-button__choices__radio {
		font-size: var(--font-text-s);
		padding: 16px;
		border-radius: 8px;
		margin-right: 32px;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.survey-radio-button__choices {
		flex-direction: column;
	}

	.survey-radio-button__choices {
		.survey-radio-button__choices__radio {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		.radio-button__label {
			justify-content: flex-start;
		}
	}
}
