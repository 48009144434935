.pcc-notice-card__container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	background-color: #FFFFFF;
	width: 522px;
	height: auto;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: 0 8px 24px 0 #0A00820F;
	padding: 10px;
	margin-top: 20px;
}

.pcc-notice-card__container__title {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 10px 0;
}

.pcc-notice-card__sub-title {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.pcc-notice-card__main {
	font-weight: bold;
	font-size: 16px;
	color: #000000;
}

.pcc-notice-card__secondary {
	font-size: 14px;
	color: #5A5A5A;
}

.pcc-notice-card__date {
	font-size: 14px;
	color: #5A5A5A;
	text-align: right;
	align-items: flex-end;
	display: flex;
	font-style: italic;
}

.pcc-notice-card__middle-text {
	font-weight: bold;
	font-size: 21px;
	text-align: center;
	background-color: #000000;
	padding: 15px;
	color: #FFFFFF;
	width: 100%;
	margin: 10px 0;
	border-radius: 4px;
}

.pcc-notice-card__container-footer {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
	padding: 10px 0;
}

.pcc-notice-card__footer-text {
	font-size: 17px;
	color: #38383D;
	font-weight: 400;
	margin: 5px 0;
}

.pcc-notice-card__footer-text__bold {
	font-weight: bold;
}
