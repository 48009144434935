.button {
	// Color theme button
	--btn-color: var(--blue-dark);
	--front-color: white;

	// Elements button colors
	--btn-text-color: var(--front-color);
	--btn-bg-color: var(--btn-color);
	--btn-border-color: var(--btn-color);

	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--btn-text-color);
	background: var(--btn-bg-color);
	border: 2px solid var(--btn-border-color);
	border-radius: 5px;
	appearance: none;
	padding: 12px 15px;
	font-size: var(--font-text-ml);
	font-weight: normal;

	&:disabled {
		color: var(--grey-light);
		background: var(--btn-disabled);
		border: 2px solid var(--btn-disabled);
		cursor: not-allowed;
	}

	&.button--outline {
		--btn-text-color: var(--btn-color);
		--btn-bg-color: var(--white);
		--btn-border-color: var(--btn-color);
		border-width: 3px;
	}

	&.button--light {
		--btn-text-color: var(--blue);
		--btn-bg-color: var(--blue-very-light);
		--btn-border-color: var(--blue-very-light);
		border-width: 3px;
	}

	&.button--link {
		font-size: var(--font-text-xs);
		text-decoration: underline;
		color: var(--blue-dark);
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;

		&:disabled {
			color: var(--grey-light);
			cursor: not-allowed;
		}
	}

	&:focus {
		outline: none;
	}

	&.button--alert {
		background: var(--alert-red);
		border: 2px solid var(--alert-red);
	}
}
