@import "../../../../core/theme/variables";

.edit-dl-page .popup-full-page__content {
	align-items: stretch;
}

.drive-log-detail {
	display: flex;
	flex-direction: column;
}
.drive-log-detail__dl-list {
	flex: 1 1;
	padding: 0 82px 48px 48px; // padding: 32px 82px 48px 48px;
}

.drive-log-detail__actions {
	display: flex;
	flex: 0 1;
	justify-content: center;
	margin: 0 82px 48px 48px;

	.button:not(:last-of-type) {
		margin-right: 30px;
	}

	.separator {
		display: none;
	}
}

.dl-mission-list__mission__document {
	position: fixed;
	top: 118px;
	right: 0;
	background-color: var(--pink);
	border: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 2;
}

.dl-mission-list__mission__document::before {
	content: "";
	position: absolute;
	background-color: transparent;
	bottom: -20px;
	height: 20px;
	right: 0px;
	width: 10px;
	border: none;
	border-top-right-radius: 5px;
	box-shadow: 0 -5px 0 0 var(--pink);
}

@media screen and (max-width: $breakpoint-mobile) {
	.drive-log-detail__actions {
		flex: 0 0;
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 10px;
		background: var(--dark-pink);
		padding: 15px;
		margin: 0;
		.drive-log-detail__finalize-dl-btn {
			grid-row: 1;
			grid-column: 1 / 3;
		}
		.dl-add-mission-button {
			grid-row: 2;
			grid-column: 1 / 3;
			background: var(--dark-pink);
		}
		.add-observation-without-mission-button {
			grid-row: 3;
			grid-column: 1 / 3;
			background: var(--dark-pink);
		}
		.button:not(:last-of-type) {
			margin: 0;
		}
	}

	.drive-log-detail__dl-list {
		padding: 15px;
	}
}


