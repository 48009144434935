.wrapper__dropdown {
	display: flex;
	flex-direction: column;
	width: 100%;

	.dropdown__label {
		font-size: var(--font-text-s);
	}

	.dropdown__select {
		width: 100%;
		margin-top: 10px;
		position: relative;
		background: transparent;
		border: none;
		display: flex;
		flex-direction: column;
		cursor: pointer;

		.dropdown__select__arrow {
			position: absolute;
			right: 25px;
			top: 20px;
			transform: rotate(90deg);
			color: var(--blue);
		}

		.dropdown__select__option {
			background: var(--grey-blue-light);
			border: 1px solid transparent;
			border-radius: 12px;
			font-size: var(--font-text-m);
			color: var(--grey-medium-dark);
			padding: 15px;
			width: 100%;
			min-height: 55px;
		}

		.dropdown__select__choices {
			position: absolute;
			top: calc(100% + 2px);
			left: -2px;
			width: 100%;
			background-color: var(--grey-blue-light);
			border-radius: 0 0 12px 12px;
			box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
			border-top: 2px none transparent;
			display: flex;
			flex-flow: column nowrap;
			align-items: flex-start;
			z-index: 1;
			max-height: 25vh;
			overflow-y: auto;
		}

		.dropdown__select__choices__option {
			width: 100%;
			height: 100%;
			padding: 10px 35px;
			text-align: left;
			font-size: var(--font-text-m);

			&:hover, &:focus {
				background: rgba(244, 224, 240, 1);
				color: var(--blue-light)
			}
		}

		.dropdown__select__choices__option:last-child {
			border-radius: 0 0 12px 12px;
		}

		.dropdown__select__choices::-webkit-scrollbar {
			display: none;
		}
	}
}

