.consultant-page__content {
	flex: 1;
}

.consultant-page__menu__feedback-button {
	top: 183px;
}

@media print {
	.consultant-page__menu__feedback-button {
		display: none;
	}
}
