@import "../../../core/theme/variables";

.feedbacks-list-header {
	display: grid;
	grid-template-columns: 280px 240px 360px 180px;
	background: transparent;
	box-shadow: none;
	font-weight: bold;
	padding: 0 24px;
	min-height: max-content;
	border-radius: 16px;
	justify-content: space-between;
	color: var(--black);
	font-size: var(--font-text-m);
	&:not(:last-child) {
		margin-bottom: 15px;
	}
}

.feedbacks-list-header__cell {
	display: flex;
	align-items: center;
}
