@import "../../../../../../core/theme/variables";

.delays-title {
	font-size: var(--font-h2);
	margin: 60px 0 40px 0;
}

.delays-table {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
}

.delays__total {
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
	border-radius: 16px;
	overflow: hidden;

	.delay-line {
		background: var(--dark-pink);
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.delays-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: var(--font-h3);
		margin: 25px 0;
	}
}

@media print {
	.delays-table {
		box-shadow: none;
	}

	.delays__total {
		display: block;
		margin-bottom: 30px;
	}

	.delay-line {
		background: none;
		padding: 10px 15px;
	}
}
