@import "../../../../../../core/theme/variables";

.no-mission-observations {
	background-color: white;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	margin: 0 82px 48px 48px;
}

.no-mission-observations__title {
	// only title to be 22px
	font-size: 22px;
	font-weight: bold;
	margin-left: 40px;
}

.no-mission-observations__observation {
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
	.no-mission-observations {
		padding: 10px;
		margin: 10px;
	}

	.no-mission-observations__title {
		font-size: var(--font-text-m);
		margin: 15px 0 0;
	}
}

@media print {
	.no-mission-observations.mission-list-and-obs__no-mission-obs {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		margin: 20px 30px;
	}
}
