@import "../../../../../../core/theme/variables";

.mission__observations {
	flex: 0 1 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
	.mission__observations {
		grid-row: 3;
		grid-column: 1/3;
	}
}
