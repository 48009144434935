// Animations
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(3rem);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: (0);
	}

	100% {
		opacity: 0;
		transform: (-3rem);
	}
}
