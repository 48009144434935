.add-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-mission__button {
  margin-top: 40px;
}

.add-mission .input-cell__field {
	text-transform: uppercase;
}

.add-mission__no-mission-code-link {
	display: flex;
	align-items: center;
	color: var(--blue-dark);
	font-size: var(--font-text-xs);
	margin-top: 16px;
	text-decoration-line: underline;
	cursor: pointer;
}
