@import "../../../../theme/variables";

.feedback-modal__form__container {
	display: flex;
	flex-direction: column;
}

.feedback-modal__form__container__header{
	display: flex;
	flex-direction: column;
}

.feedback-modal__form__container__header__title {
	color: var(--grey-dark);
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-l);
	text-align: center;
	margin-bottom: 15px;
}

.feedback-modal__form__container__content__sub-title {
	color: var(--grey-dark);
	display: flex;
	flex: 1;
	font-weight: bold;
	font-size: var(--font-text-m);
	text-align: start;
	margin-bottom: 15px;

}

.feedback-modal__form__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.feedback-modal__form__container__content{
	display: flex;
	flex-direction: column;
	padding: 30px 10px;
}

.modal__content__comment {
	color: var(--grey-blue-light);
}

.feedback-modal__form__button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
}





