@import "../../../core/theme/variables";

.survey-list {
	background-color: white;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 25px;
	margin-top: 60px;
}

.survey-list__title {
	font-size: 22px;
	font-weight: bold;
	margin-left: 0;
	align-self: self-start;
	margin-bottom: 20px;
	color: var(--black);
	break-after: avoid;
}

.survey-list__survey {
	display: flex;
	flex-direction: column;
	width: 100%;
}

