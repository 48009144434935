@media print {
	.survey-details-list__item {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.survey-details-list {
	break-before: page;
}
