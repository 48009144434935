@import "../../../core/theme/variables";

.nudges-distributed-page {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 40px 48px 0 48px;
}

.nudges-distributed-page__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 64px;
}

.nudges-distributed-page__header__separator {
	border-left: 1px solid var(--blue-light);
	width: 0px;
	height: 50px;
	margin-left: 24px;
	margin-right: 24px;
}

.nudges-distributed-page__list {
	width: 100%;
	display: flex;
	flex-direction: column;
}
