@import "../../../core/theme/variables";

.overlay__background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: var(--overlay-opacity)
}
