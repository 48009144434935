@import "../../../core/theme/variables";

.error-message {
	padding: 8px 0;
	font-size: var(--font-text-xs);
	color: var(--red);
}

@media only screen and (max-width: $breakpoint-mobile) {
	.error-message {
		text-align: center;
	}
}
