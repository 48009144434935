@import "../../../core/theme/variables";

.responsive-text {
	display: block;
}

.responsive-text--mobile {
	display : none;
}

@media screen and (max-width: $breakpoint-mobile) {
	.responsive-text {
		display: none;
	}

	.responsive-text--mobile {
		display: block;
	}
}
