@import "../../../core/theme/variables";

.attention-alerts-page {
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 40px 48px 0 48px;
}

.attention-alerts-page__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 64px;
}

.attention-alerts-page__header__title {
    font-weight: bold;
    font-size: var(--font-text-ml);
    color: var(--grey-dark);
    flex: 1
}

.attention-alerts-page__header__link {
    flex: 1
}

.attention-alerts-page__list {
	width: 100%;
	display: flex;
	flex-direction: column;
}