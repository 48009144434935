@import "../../theme/variables";

.comment-text-area-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.comment-text-area-wrapper__header {
	display: flex;
	flex-flow: nowrap;
	margin-bottom: 5px;
	align-items: center;
}

.comment-text-area-wrapper__title {
	color: var(--grey-dark);
	font-size: var(--font-h3);
	font-weight: bold;
}

.comment-text-area-wrapper__label {
	color: var(--grey-dark);
	font-size: var(--font-text-s);
	align-self: flex-end;
	margin: 5px 0;
}

.comment-text-area-wrapper__icon{
	display: flex;
	margin-left: auto;
	cursor: pointer;
	color: var(--color-text);
}

.comment-text-area-wrapper__icon--on {
	transform: rotate(-90deg);
}

.comment-text-area-wrapper__icon--off {
	transform: rotate(90deg);
}

@media only screen and (max-width: $breakpoint-mobile) {
	.comment-text-area-wrapper__header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 10px;
	}

	.comment-text-area-wrapper__label {
		align-self: flex-end;
	}
}
