.drive-log-delay-details-table-row {
	background-color: var(--white);
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	margin-bottom: 16px;
	padding: 20px 20px;
	font-size: var(--font-text-s);
	color: var(--black);
}

.drive-log-delay-details-table-row__box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.drive-log-delay-details-table-row__box-left-side {
	display: flex;
	flex-direction: column;
	min-width: 180px;
	border-right: var(--blue-light) 1px solid;
	align-items: flex-start;
}

.drive-log-delay-details-table-row__name {
	margin-bottom: 4px;
	font-weight: bold;
}

.drive-log-delay-details-table-row__id {
	margin-bottom: 24px;
}

.drive-log-delay-details-table-row__attachment {
	margin-bottom: 4px;
}

.drive-log-delay-details-table-row__attachment-name {
	margin-bottom: 24px;
	font-weight: bold;
}

.drive-log-delay-details-table-row__service {
	background-color: rgba(228, 178, 218, 0.3);
	color: var(--blue-light);
	font-size: var(--font-text-m);
	font-weight: bold;
	padding: 12px;
	border-radius: 8px;
	justify-content: center;
}

.drive-log-delay-details-table-row__service-date {
	display: flex;
	align-items: center;
	padding-top: 16px;
	font-size: var(--font-text-s);
	color: var(--blue-light);

	.row__hours {
		border: 1px solid var(--blue-light);
		border-radius: 9px;
		padding: 8px;
		font-weight: bold;
	}

	.arrow-right {
		margin: 0 10px;
	}
}

.drive-log-delay-details-table-row__box-middle-side {
	display: flex;
	width: 100%;
	padding-right: 51px;
}

.drive-log-delay-details-table-row__box-middle-side__cell-column {
	display: flex;
	flex-direction: column;
	padding-left: 40px;
	width: 50%;
	&:first-child {
		justify-content: space-between;
	}
	&:last-child {
		justify-content: flex-start;
	}
}

.drive-log-delay-details-table-row__box-right-side {
	display: flex;
	flex-direction: column;
	margin-right: 40px;
}

.drive-log-delay-details-table-row__button {
	min-width: 139px;
	min-height: 48px;
	padding: 4px 16px;
	margin-bottom: 35px;
}

.drive-log-delay-details-table-row__button:first-child {
	margin-top: 40px;
}

.drive-log-delay-details-table-row__comment__separator{
	margin: 24px 0;
	border-top: 1px solid var(--blue-intense);
}

.drive-log-delay-details-table-row__comment__input{
	background-color: var(--catskill-white);
	border-radius: 15px;
	padding: 20px;
}

