.simple-search-bar {
	background-color: white;
	border-radius: 12px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0 10px 0;
	padding: 15px;
}

.simple-search-bar__input {
	border: none;
	outline: none;
	font-size: var(--font-text-ml);
	min-width: 400px;
}

.simple-search-bar__icon {
	transform: scale(1.8);
	margin-right: 20px;
	color: var(--light-white);
}

.simple-search-bar__button-reset {
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.simple-search-bar__button-reset__icon {
	transform: scale(0.8);
}
