@import "../../../../core/theme/variables";

.drive-log-details-driver__content {
	margin: 0 150px;
}

.drive-log-details-driver__controls {
	margin: 40px auto;
}

.drive-log-details-driver__content__comment {
	margin: 60px 0;
	color: var(--black);

	.content__comment__title {
		font-weight: bold;
		font-size: var(--font-text-m);
		margin-bottom: 25px;
	}

	.content__comment--read {
		font-size: var(--font-text-m);
	}
}

.drive-log-details-driver__menu__feedback-button {
	top: 187px;
}

.drive-log-details-driver__hr-comment{
	background-color: var(--catskill-white);
	border-radius: 15px;
	display: flex;
	padding: 24px;
	margin-top: 60px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.drive-log-details-driver__content {
		margin: 0 15px;
	}
}

@media print {
	.drive-log-details-driver__content {
		grid-area: print-content;
		display: grid; // display grid fixes page breaks not working properly and allows margin to be set
		margin: 0 30px;
	}

	.drive-log-details-driver--missions {
		display: grid;
		background: linear-gradient(111.29deg, rgba(156, 188, 223, 0.16) -0.86%, rgba(217, 153, 201, 0.096) 148.84%);
		margin: 0 -30px;
	}

	.drive-log-details-driver__menu__feedback-button {
		display: none;
	}
}
