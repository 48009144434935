@import "../../../../core/theme/variables";

.drive-log-row-container {
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	min-height: 70px;


	&:not(:last-child) {
		margin-bottom: 15px;
	}
	&:not(.drive-log-row--headers) {
		cursor: pointer;
	}
}

.drive-log-row {
	justify-content: space-between;
	padding: 15px;
	color: var(--black);
	font-size: var(--font-text-m);
}

.drive-log-row__cell {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@media screen and (max-width: $breakpoint-mobile) {
	.drive-log-row {
		grid-template-columns: 1fr 2px 1fr;
		grid-template-rows: repeat(5, auto);
		grid-row-gap: 10px;
		grid-column-gap: 15px;

		&:after {
			content: "";
			grid-column: 2;
			grid-row: 1 / 5;
			background: var(--blue-light);
			width: 2px;
		}
	}

	.dl-table .drive-log-list__row.drive-log-row--driver-view {
		grid-template-columns: 1fr 10px 1fr;
		grid-template-rows: repeat(5, auto);
	}
	.service-number-cell {
		grid-column: 1;
		grid-row: 2;
		text-align: left;
		font-size: var(--font-text-xs);
	}
	.service-start-cell {
		grid-column: 1;
		grid-row: 1;
	}
	.sheet-line-number-container {
		grid-column: 1;
		grid-row: 3 / 5;
		.sheet-line-number-container__sheet-line-number {
			flex: 1;
			text-align: center;
		}
	}
	.state-cell__state {
		grid-column: 1 / 4;
		grid-row: 5;
	}
	.hours-cell__hours {
		grid-column: 3;
		grid-row: 1 / 3;
	}
	.comment-cell {
		grid-column: 3;
		grid-row: 3 / 5;
		flex-direction: column;
		align-items: stretch;
		.comment-cell__observations {
			flex: auto;
			margin-top: 10px;
		}
	}
}
