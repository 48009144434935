.identity-cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: var(--font-text-m);
	text-align: center
}

.identity-cell__identity {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	font-size: var(--font-text-s);

	&__name {
		font-weight: bold;
		text-transform: capitalize;
	}
}
