@import "../../../../../../../../core/theme/variables";

.dl-mission-list__mission__delete__btn {
	background: transparent;
	border: 0;
}
.dl-mission-list__mission__delete__icon {
	color: var(--black);
}

@media screen and (max-width: $breakpoint-mobile) {
	.dl-mission-list__mission__delete {
		font-size: var(--font-text-mobile-s);
		font-weight: normal;
	}
	.dl-mission-list__mission__delete__icon {
		display: none;
	}
	.dl-mission-list__mission__delete__text {
		color: var(--blue-dark);
		font-size: var(--font-text-mobile-s);
	}
}
