.observation-delay-wrapper {
	display : flex;
	flex-direction: column;
	width: 100%;
}
.observation-delay-wrapper__title {
	font-size: var(--font-h3);
	font-weight: bold;
}
.observation-delay-wrapper__label {
	font-size: var(--font-text-m);
	display: flex;
	align-items: center;
}
.observation-delay-wrapper__input {
	max-width: 80px;
	margin: 25px 20px 20px 0;
	font-size: var(--font-text-m);

	.input-cell__field {
		font-size: var(--font-text-m);
	}
}
