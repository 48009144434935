@import "../../../../../../core/theme/variables";

.update-delay-modal {
	min-width: 360px;
}

.update-delay-modal__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
}

.update-delay-modal__content {
	display: flex;
	flex-direction: column;
}

.update-delay-modal__title {
	flex: 1;
	font-size: var(--font-text-m);
	font-weight: bold;
	text-align: center;
	color: var(--dark);
	margin-bottom: 30px;
}

.update-delay-modal__delay {
	display: flex;
	align-items: center;
	justify-content: center;

	.update-delay-modal__input-cell {
		min-width: 55px;
		max-width: 55px;

		&:focus-within {
			border: 2px solid
		}

		.input-cell__field {
			font-size: var(--font-text-m);
			font-weight: normal;
			color: var(--dark);
			border-bottom: none;
		}
	}
}


.update-delay-modal__minutes {
	color: var(--black);
	font-size: var(--font-text-m);
	margin-left: 10px;
}

.update-delay-modal__error-message {
	min-height: 25px;
	color: var(--alert-red);
	margin-top: 15px;
	visibility: visible;
	text-align: center;

	&--hidden {
		visibility: hidden;
	}
}

.update-delay-modal__content__btn {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}
.update-delay-modal__validate-btn {
	display: flex;
	justify-content: center;
	font-size: var(--font-text-m);
}

@media only screen and (max-width: $breakpoint-mobile) {
	.update-delay-modal {
		min-width: 260px;
		max-width: 400px;
	}

	.update-delay-modal__close {
		font-size: 20px;
	}

}

