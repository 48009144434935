@import "../../../core/theme/variables";

.dl-error-message {
  color: var(--red);
  margin-top: 20px;
  font-size: var(--font-text-xs);
}

@media only screen and (max-width: $breakpoint-mobile) {
  .dl-error-message {
    text-align: center;
  }
}
