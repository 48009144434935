@import "../../../../../core/theme/variables";

.service-start-cell {
	display: flex;
	align-items: center;
	font-size: var(--font-text-m);
}

@media screen and (max-width: $breakpoint-mobile) {
	.service-start-cell {
		font-weight: bold;
	}
}
