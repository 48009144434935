@import "../../../../core/theme/variables";

.schedule-sheet {
	overflow: auto;
	height: 60vh;
	margin-bottom: 24px;
	background-color: var(--true-white);
	font-size: 16px;
}

.schedule-sheet-table {
	border-collapse: separate;
	border-spacing: 0;
}

.schedule-sheet-table-header {
	background-color: var(--true-white);
	position: sticky;
	top: 0;
}

.schedule-sheet-table-header-cell {
	padding: 4px 8px;
	border-top: 2px solid var(--blue);
	border-bottom: 2px solid var(--blue);
	border-right: 2px solid var(--blue);
}

.schedule-sheet-table-header-cell--red {
	background-color: var(--red-sheet);
	color: white;
}

.schedule-sheet-table-header-cell-content {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.schedule-sheet-table-header-cell-content--reverse {
	flex-direction: column-reverse;
	display: flex;
}

.schedule-sheet-table-header-cell-icon {
	transform: rotate(-90deg);
}

.schedule-sheet-table-header-cell-icon--reverse {
	transform: rotate(90deg);
}

.schedule-sheet-table-header-cell-code {
	margin: 4px 0;
}

.schedule-sheet-table-header-train-type {
	padding: 4px 8px;
	border-radius: 10px;
	font-size: 13px;
	background-color: var(--blue);
	color: white;
}

.schedule-sheet-table-header-train-type--red {
	background-color: white;
	color: var(--red-sheet);
}

.schedule-sheet-table .schedule-sheet-table-header-cell:first-child,
.schedule-sheet-table .schedule-sheet-table-content-cell:first-child {
	/* Apply a left border on the first content cell or header cell in a row */
	border-left: 2px solid;
}

