@import "../../../core/theme/variables";

.section__header {
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
}

.section__header__title {
	color: var(--black);
	line-height: 22.78px;
	font-weight: 400;
	font-size: var(--font-text-ml);
}
.section__header__title--bold {
	font-weight: 700;
	padding-right: 5px;
}
