@import "../../../../../app";

.input-textarea {
	display: block;
	background: white;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
	border: 2px solid transparent;
	border-radius: 12px;
	line-height: 1;
	padding: 15px;

	&:focus-within {
		border: 2px solid var(--blue-light);
	}

	&--error, &--error:focus-within {
		border: 2px solid var(--red-light);
	}
}

.input-textarea__field {
	font-family: var(--font-family-parisine);
	font-size: var(--font-text-m);
	color: var(--black);
	border: none;
	margin: 0;
	padding: 0;
	background: none;
	box-shadow: none;
	resize: none;

	&:focus {
		outline: none;
	}
}
