@import "../../../core/theme/variables";

.dl-subheader {
  color: var(--black);
  font-size: var(--font-h1);
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

@media only screen and (max-width: $breakpoint-mobile) {
  .dl-subheader {
    font-size: var(--font-text-mobile-l);
  }
}
