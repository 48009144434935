.observation-list__item {
	margin: 30px 0;
	break-inside: avoid;
}

.observation-list__item__heading {
	background-color: var(--blue-very-light);
	color: var(--blue);
	font-size: var(--font-h2);
	padding: 25px;
	border-radius: 15px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	&__title {
		font-weight: bold;
	}

	&__mission span {
		font-weight: bold;
		margin-left: 10px;
	}

}

@media print {
	.observation-details-list {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}

	.observation-list__item {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, auto);
	}
}
