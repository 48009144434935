@import "../../../../core/theme/variables";

.signed-dl-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.signed-dl-page__title {
	font-size: var(--font-h1);
	color: var(--black);
	margin-bottom: 55px;
}
.signed-dl-page__empty-splash {
	width: 300px;
	min-height: 275px;
	background-size: 300px auto;
	background-repeat: no-repeat;
	background-position: center center;
	margin-bottom: 80px;
}

.signed-dl-page__controls {
	display: flex;
	.button:not(:last-of-type), .button:first-of-type {
		margin-right: 24px;
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.signed-dl-page__title {
		font-size: var(--font-text-mobile-l);
		text-align: center;
		margin: 40px 0 30px 0;
	}
	.signed-dl-page__empty-splash {
		width: 210px;
		min-height: 175px;
		background-size: 210px 175px;
		margin-bottom: 50px;
	}
	.signed-dl-page__controls {
		flex-direction: column;

		.button:not(:last-of-type), .button:first-of-type {
			margin-right: 0;
			margin-bottom: 24px;
		}
	}
}
