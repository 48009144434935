@import "../../../../core/theme/variables";

.delay-list-page {
	display: flex;
	justify-content: center;
	background: linear-gradient(111.29deg, rgba(156, 188, 223, 0.16) -0.86%, rgba(217, 153, 201, 0.096) 148.84%);
	padding: 60px;
}
.delay-list-page__content {
	min-width: 575px;
	max-width: 575px;
	display: flex;
	flex-direction: column;
}

.delay-list-page__title {
	color: var(--black);
	font-size: var(--font-h1);
	margin-bottom: 60px;
	font-weight: bold;
	text-align: center;
}

.delay-list-page__controls {
	display: flex;
	justify-content: center;
	margin-top: 60px;
}

.delay-list-page__controls__validate {
	flex: 280px 0;
}

@media screen and (max-width: $breakpoint-mobile) {
	.delay-list-page__content {
		min-width: 280px;
		max-width: 280px;
	}
	.delay-list-page__title {
		font-size: var(--font-text-mobile-l);
	}
	.delay-list-page__controls__validate {
		font-size: var(--font-text-m);
	}
}
