@import "../../../core/theme/variables";

.schedule__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.schedule__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

.schedule__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.schedule__field__input {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	&:last-child {
		margin-left: 48px;
	}
}

.schedule__field__block {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 12px;
}

.schedule__field__block .datePicker {
	font-size: var(--font-text-xl);
	padding: 12px 16px;
	width: auto;
}

.schedule__field__block .input-cell.input__hours,
.schedule__field__block .input-cell.input__minutes {
	padding: 8px 2px;

	.input-cell__field {
		font-size: var(--font-text-xl);
	}
}
.schedule__field__block .input-cell.input__hours {
	margin-left: 24px;
}

.schedule__field__block .time__cells__separator {
	font-size: var(--font-text-xxl);
	color: var(--blue-light);
	margin: 0 6px;
	padding-bottom: 4px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.schedule__field__container {
		flex-direction: column;
	}

	.schedule__field__input {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&:last-child {
			margin-left: 0;
			margin-top: 24px;
		}
	}

	.schedule__field__input .input-cell.input__hours .input-cell__field,
	.schedule__field__input .input-cell.input__minutes .input-cell__field {
		width: 42px;
	}
}
