.supervisor-page__content {
	flex: 1;
}

.supervisor-page__menu__feedback-button {
	top: 187px;
}

@media print {
	.supervisor-page__menu__feedback-button {
		display: none;
	}
}
