@import "../../../../core/theme/variables";

.drive-log-list {
	display: flex;
	flex-direction: column;
	padding: 0 50px 40px 50px;
}

.drive-log-list__attachment-banner {
	margin: 0 -50px 32px -50px;
}

.drive-log-list__complex-dls {
	margin-bottom: 50px;
}

.drive-log-list__no-drive-log-to-validate {
	flex : 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.drive-log-list__filters {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}
.drive-log-list__searching-mouse-img {
	width: 300px;
	height: auto;
	margin-bottom: 30px;
}
.drive-log-list__label-no-drive-log-to-validate {
	font-size: 28px;
	color: var(--black);
	font-weight: bold;
}

@media screen and (max-width: $breakpoint-mobile) {
	.drive-log-list {
		padding: 25px 15px;
	}
}
