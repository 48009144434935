@import "../../../core/theme/variables";

.modal {
	position: fixed;
	background: rgba(4, 0, 16, 0.8);
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.confirm-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--white);
	min-height: 200px;
	min-width: 400px;
	max-width: 420px;
	padding: 40px 32px;
	border-radius: 16px;
	position: relative;
	line-height: 25px;
}

.confirm-modal__close-icon {
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.confirm-modal__action-icon {
	width: 58px;
	height: 58px;
}

.confirm-modal__title {
	flex: 1;
	font-size: var(--font-text-m);
	font-weight: bold;
	text-align: center;
	color: black;
	margin-top: 16px;
	margin-bottom: 24px;
}

.confirm-modal__title--large {
	font-size: var(--font-h2);
}

.confirm-modal__text {
	display: flex;
	flex: 1;
	justify-content: center;
	text-align: center;
	font-size: 15px;
	color: var(--black);
	min-height: 32px;
	margin-top: 24px;
	margin-bottom: 32px;
}

.confirm-modal__text--bold {
	font-weight: 700;
}

.confirm-modal__controls {
	display: flex;
	justify-content: center;

	:nth-child(2) {
		margin-left: 25px;
	}
	.confirm-modal__cancel-btn {
		text-transform: capitalize;
		font-size: var(--font-text-m);
	}
	.confirm-modal__confirm-btn {
		font-size: var(--font-text-m);
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.confirm-modal {
		min-width: 260px;
		max-width: 300px;
	}

	.confirm-modal__icon {
		font-size: 20px;
	}
}

