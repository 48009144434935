@import "../../../theme/variables";

.popup-full-page {
	background: var(--linear-background);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.popup-full-page__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 64px;
	flex: 1 1 auto;
	justify-content: space-between;
}

.popup-full-page__content__instructions {
	margin-top: 30px;
	font-size: 2rem;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.popup-full-page__content {
		margin-top: 25px;
	}
}
