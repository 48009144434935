.satisfaction-survey-modal {
	height: 63vh;
	padding: 12px;
};

.satisfaction-survey-modal__icon-close {
	display: flex;
	flex-direction: row-reverse;
}

.icon-close {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
