@import "../../../core/theme/variables";

.service-infos__top {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.service-infos__title {
	font-size: var(--font-h2);
	margin: 60px 0 40px 0;
}

.service-infos__controls {
	max-height: 48px
}

.service-infos__title__date {
	margin-left: 5px;
}

.service-infos__table {
	display: flex;
	flex-direction: column;
	font-size: var(--font-text-m);
	color: var(--black);
}

.service-infos__table__row {
	width: 100%;
	display: grid;
	grid-template-columns: 12% 12% 20% 15% 30% 10%;
	padding: 15px 15px 15px 25px;
}

.service-infos__table__headers {
	font-weight: bold;
	padding: 0 25px;
}

.service-infos__table__values {
	background-color: var(--blue-dark);
	border-radius: 15px;
	margin-top: 15px;

	.service-infos__table__row__number, .service-infos__table__row__hours,
	.service-infos__table__row__duration, .service-infos__table__row__attachment, .service-infos__table__row__action {
		background-color: white;
		padding: 20px 20px;
		display: flex;
		align-items: center;
	}

	.service-infos__table__row__number {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		padding-left: 25px;
	}

	.table__row__number__title {
		display: none;
	}

	.service-infos__table__row__attachment {
		font-weight: bold;
	}

	.service-infos__table__row__action {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.table__row__action__edit {
		font-size: var(--font-text-xs);
		text-decoration: underline;
		cursor: pointer;
		color: var(--color-text);
	}

	.service-infos__table__row__duration {
		font-weight: bold;
		color: var(--blue);

		.row__duration__value {
			position: relative;
		}
		.row__duration__icon {
			font-size: 1rem;
			position: absolute;
			top: -5px;
			left: -10px;
		}
	}
}

.service-infos__table__row__number, .service-infos__table__row__hours,
.service-infos__table__row__duration, .service-infos__table__row__attachment {
	padding-left: 20px;
}

.service-infos__table__row__service {
	display: flex;
	align-items: center;
}

.service-infos__table__values__row {
	min-height: 100px;

	& .service-infos__table__row__service {
		color: white;
	}
}

.service-infos__table__row__hours {
	display: flex;
	padding-left: 30px;
	font-size: var(--font-text-xs);

	.row__hours-start {
		background: black;
		color: var(--yellow);
		border-radius: 9px;
		padding: 8px;
		font-weight: bold;
	}

	.row__hours-end {
		background: black;
		color: var(--yellow);
		border-radius: 9px;
		padding: 8px;
		font-weight: bold;
	}

	.arrow-right {
		margin: 0 10px;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.service-infos__title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: var(--font-h3);
		margin: 25px 0;
	}

	.service-infos__table {
		margin: 0 -15px;
	}

	.service-infos__table__headers {
		display: none;
	}

	.service-infos__table__row {
		padding: 15px;
	}

	.service-infos__table__values__row {
		display: grid;
		grid-template-columns: min(80px) auto 50px;
		grid-template-rows: auto;
		grid-template-areas:
			"service . ."
    		"number hours duration"
    		"number attachment duration";
	}

	.service-infos__table__values {
		border-radius: 0;
		margin-top: 0;

		.service-infos__table__row__number, .service-infos__table__row__hours,
		.service-infos__table__row__duration, .service-infos__table__row__attachment {
			padding: 0;
			justify-content: center;
		}

		.service-infos__table__row__service {
			grid-area: service;
			margin-bottom: 10px;
		}

		.service-infos__table__row__number {
			grid-area: number;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 0;
			min-height: 100px;

			.table__row__number__title {
				display: block;
				font-size: var(--font-text-xs);
				font-weight: bold;
			}

			.table__row__number__value {
				font-size: var(--font-text-m);
			}
		}

		.service-infos__table__row__hours {
			grid-area: hours;
		}

		.service-infos__table__row__duration {
			grid-area: duration;
			justify-self: center;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			min-width: 60px;
		}

		.service-infos__table__row__attachment {
			grid-area: attachment;
			text-align: center;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			display: flex;
			align-items: flex-start;
		}
	}
}

@media print {
	.service-infos__table__row, .service-infos__table__values__row {
		grid-template-columns: 1fr 1fr 2fr 1fr 3fr 0fr;
		padding: 10px 10px 10px 15px;
	}

	.service-infos__controls, .table__row__action__edit {
		display: none;
	}
}
