@import "../../../../core/theme/variables";

.signature-dl-page .popup-full-page__content {
	align-items: stretch;
	margin-top: 0;
}

.signature-dl-page__button-group {
	justify-content: center;
	margin-top: 40px;
	margin-bottom: 70px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}


.signature-dl-page__button-group__button {
	margin-right: 30px;
}

.signature-page-sign-modal {
	min-width: 430px;
}

.signature-page-sign-modal__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	cursor: pointer;
}

.signature-page-sign-modal__title {
	font-size: var(--font-text-m);
	color: var(--black);
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.signature-page-sign-modal__title-warning {
	color: var(--alert-red);
}

.signature-page-sign-modal__button-group {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.signature-page-sign-modal__button {
	margin-right: 30px;
}

.signature-page-sign-modal__instruction{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--grey-dark);
	font-size: var(--font-text-s);
	margin-top: 16px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.signature-page-sign-modal {
		min-width: 260px;
		max-width: 400px;
	}

	.signature-page-sign-modal__close {
		font-size: 20px;
	}

}
