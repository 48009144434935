@import "../../../../core/theme/variables";

.drive-log-row.drive-log-row--headers {
	color: var(--black);
	font-size: var(--font-text-s);
	background: transparent;
	box-shadow: none;
	font-weight: bold;

	.drive-log-row__service-number {
		text-align: center;
	}
	.drive-log-row__sheet-line-number {
		text-align: center;
	}
	.drive-log-row__hours {
		font-size: var(--font-text-s);
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	 .dl-table .drive-log-row.drive-log-row--headers {
		display: none;
	}
}
