@import "../../../core/theme/variables";

.service-number-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.service-number-page__cells {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.service-number__icon-close {
	font-size: 24px;
	position: absolute;
	right: -50px;
}

.service-number-page__button {
	margin-top: 60px;
}

.service-number-page__cell {
	width: 170px;

	.input-cell__field {
		width: 100%;

		&:focus {
			border-bottom: none;
		}
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.service-number-page {
		padding: 0 20px;
	}

	.service-number-page__cell {
		width: 40%;
	}

	.service-number__icon-close {
		font-size: 20px;
		right: 20px;
	}

	.service-number-page__button {
		width: 100%;
	}
}
