@import "../../../../../app";

.input-field__container {
	display: flex;
	flex-direction: column;
}

.input-field__container--short {
	display: flex;
	flex-direction: column;
}

.input-field {
	background: white;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
	border: 2px solid transparent;
	border-radius: 12px;
	line-height: 1;
	padding: 15px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	&:focus-within {
		border: 2px solid var(--blue-light);
	}

	&--error, &--error:focus-within {
		border: 2px solid var(--red-light);
	}
}

.input-field__input {
	font-size: var(--font-text-m);
	color: var(--black);
	border: none;
	margin: 0;
	padding: 0;
	background: none;
	box-shadow: none;
	outline: none;
}

.input-field__icon {
	font-size: var(--font-text-l);
	color: var(--blue);
	opacity: 0.4;
	margin-right: 10px;
}

.input-field__title {
	font-size: var(--font-text-sm);
	color: var(--black);
	margin: 12px 0;
}
