@import "../../../../core/theme/variables";

.dbl-header {
	display: grid;
	grid-template-columns: auto 1fr auto;
	background: var(--blue);
	color: white;
	justify-content: space-between;
	padding: 30px 20px 20px;
	position: relative;
	border-radius: 0 0 25px 25px;
}

.dbl-header__logo-wrapper {
	font-size: 18px;
	display: flex;
	align-items: center;
	margin-right: 4vw;
}

.dbl-header__icon-logo {
	font-size: 25px !important;
	margin-right: 0.5vw;
}

.dbl-header__badge {
	margin-bottom: 8px;
	margin-left: 2px;
}

.dbl-header__title {
	font-weight: bold;
	font-size: var(--font-h4);
}

.dbl-header__links {
	display: flex;
}

.dbl-header__link {
	display: flex;
	font-size: var(--font-text-m);
	align-items: center;
	margin-right: 2.5vw;
}

.dbl-header__link--active {
	font-weight: bold;
	position: relative;
	&:after {
		position : absolute;
		content: "";
		width: 100%;
		bottom: -20px;
		left: 0;
		border-bottom: 5px solid var(--green-jade);
		border-radius: 25px;
	}
}

.dbl-header__link--with-badge {
	&:after {
		width: 90%;
	}
}

.dbl-header__user-info {
	align-self: center;
}

@media screen and (max-width: $breakpoint-mobile) {
	.dbl-header {
		padding: 26px 15px 15px 15px;
		grid-template-columns: auto auto;
		grid-template-rows: auto auto;
		grid-row-gap: 10px;
		position: relative;
	}
	.dbl-header__logo-wrapper {
		grid-column: 1;
		grid-row: 1;
		margin-top: 0;
		margin-right: 100px;
	}
	.dbl-header__user-info {
		grid-column: 2;
		grid-row: 1;
	}
	.dbl-header__links {
		grid-row: 2;
		grid-column: 1 / 3;
		justify-content: space-around;
	}
	.dbl-header__link {
		justify-content: center;

		&:not(:last-of-type) {
			margin-right: 0;
		}
	}
	.dbl-header__link--active:after {
		bottom: -15px;
	}

}

@media print {
	.dbl-header {
		display: none;
	}
}
