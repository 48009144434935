@import "../../../../../core/theme/variables";

.hours-cell__hours {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--font-text-m);

	.arrow-right {
		font-size: 10px;
	}
}

.hours-cell__hours__header {
	width: 52px;
}

.hours-cell__hour-start {
	background: black;
	color: var(--yellow);
	border-radius: 9px;
	padding: 8px;
	font-weight: bold;
}

.hours-cell__hour-end {
	background: black;
	color: var(--yellow);
	border-radius: 9px;
	padding: 8px;
	font-weight: bold;
}


@media screen and (max-width: $breakpoint-mobile) {
	.hours-cell__hours {
		font-size: var(--font-text-xs);
		justify-content: center;
	}

	.hours-cell__hours .arrow-right {
		margin: 8px;
	}
}
