@import '../../../core/theme/variables';

.spinner {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	margin-left: 20px;
	font-size: 10px;
	position: relative;
	border-top: 0.5em solid rgba(255, 255, 255, 1);
	border-right: 0.5em solid rgba(255, 255, 255, 1);
	border-bottom: 0.5em solid rgba(255, 255, 255, 1);
	border-left: 0.5em solid var(--blue-dark);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}


.modify-stations {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background: var(--catskill-white);
	padding: 4% 8%;
	border-radius: 8px;

	.observation-search {
		margin-bottom: 40px;
	}

	.observation-time .time-picker {
		justify-content: center;
	}

	.modify-stations__button {
		align-items: center;
	}

	.dl-error-message {
		width: 250px;
	}
}

.modify-stations__time {
	margin-bottom: 50px;
	width: 250px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto;
	grid-template-areas:
		'label reset'
		'time-picker time-picker';

	.observation-time__label {
		grid-area: label;
	}

	.time-picker {
		grid-area: time-picker;
	}

	.time-picker__reset {
		grid-area: reset;
		justify-self: start;
	}
}

.subheader__code {
	background-color: var(--secondary-lilas);
	color: var(--blue-light);
	font-size: var(--font-h4);
	border-radius: 10px;
	padding: 15px;
	font-weight: bold;
	margin-left: 20px;
}

.loading-message {
	margin-top: 10px;
	font-size: var(--font-m);
	color: gray;
}

.modify-stations__rfn-station-station__text-alert {
	color: var(--alert-red);
}

@media only screen and (max-width: $breakpoint-mobile) {
	.modify-stations {
		width: 100%;
		background: none;
		align-items: unset;

		.observation-search {
			background: var(--catskill-white);
			align-items: center;
			border-radius: 8px;
			margin-bottom: 30px;
			padding: 15px 0;
		}

		.observation-time {
			background: var(--catskill-white);
			border-radius: 8px;
			padding: 15px 0 40px 0;
			margin-bottom: 40px;
		}

		.modify-stations__button {
			align-items: center;
		}
	}

	.modify-stations__time {
		.time-picker__reset {
			padding-right: 20px;
		}
	}

	.subheader__code {
		margin-left: 10px;
	}
}
