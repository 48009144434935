.confirm-train-composition-modal {
	padding: 45px;
}

.confirm-train-composition-modal__content {
	min-width: 360px;
	color: var(--dark);
	padding: 0 32px
}

.confirm-train-composition-modal__content__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	cursor: pointer;
}

.confirm-train-composition-modal__content__block__title {
	flex: 1;
	font-size: var(--font-text-large);
	color: var(--klein-blue);
	font-weight: bold;
	margin: 15px 0 40px 0;
	text-transform: uppercase;
}

.confirm-train-composition-modal__content__block__subtitle {
	font-size: var(--font-text-ml);
	color: var(--grey-dark);
	font-weight: bold;
	text-align: center;
}

.confirm-train-composition-modal__content__block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.confirm-train-composition-modal__button-group {
	display: flex;
	justify-content: center;
	margin: 24px 0 16px 0;
	width: 100%
}

.confirm-train-composition-modal__button {
	margin: 0 8px;
}

.confirm-train-composition-modal__instruction {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--grey-dark);
	font-size: var(--font-text-s);
}

.confirm-train-composition-modal__instruction-counter {
	font-weight: bold;
}
