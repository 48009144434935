.psg-modal__container {
	display: flex;
	flex-direction: column;
	max-width: 450px;
}

.psg-modal__container__content {
	color: var(--grey-dark);
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-h3);
	text-align: center;
	margin-bottom: 15px;
}

.psg-modal__container__sub-content {
	color: var(--grey-dark);
	display: flex;
	flex: 1;
	font-size: var(--font-text-xs);
	text-align: start;
	justify-content: center;
}

.psg-modal__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.psg-modal__container__button {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 60%;
	margin: 40px 0 0 75px;
}
