@import "../../../core/theme/variables";

.mission-list-and-obs__title {
	font-size: var(--font-h2);
	margin: 60px 0 40px 0;
	break-after: avoid;
}
.mission-list-and-obs__missions {
	margin-bottom: 60px;
}

.mission-list-and-obs__no-mission-obs {
	margin: 0;

	.no-mission-observations__title {
		margin-left: 0;
		align-self: self-start;
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.mission-list-and-obs__title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: var(--font-h3);
		margin: 25px 0;
	}

	.mission-list-and-obs__missions {
		margin-bottom: 30px;
	}

	.mission-list-and-obs__no-mission-obs {
		.no-mission-observations__title {
			align-self: center;
			margin-bottom: 10px;
		}
	}

}

@media print {
	.mission-list-and-obs__title {
		display: grid;
		margin: 20px 30px;
	}

	.dl-mission-list {
		display: grid;
		margin: 20px 30px;
	}
}
