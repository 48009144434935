@import "../../../../core/theme/variables";

.returned-drive-log-row-container {
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	padding: 24px;
	min-height: 70px;
	color: var(--black);
	font-size: var(--font-text-m);

	&:not(:last-child) {
		margin-bottom: 16px;
	}
	&:not(.returned-drive-log-row--headers) {
		cursor: pointer;
	}
}

.returned-drive-log-row {
	display: grid;
	grid-template-columns: 100px 50px 90px 175px 240px 210px;
	justify-content: space-between;
}

.returned-drive-log-row__cell {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.returned-drive-log-row__comment-container {
	background-color: var(--catskill-white);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	margin-top: 16px;
}

.returned-drive-log-row__comment {
	display: flex;
	flex-direction: column;
	align-self: start;
	padding-bottom: 0;
	width: 100%;
}

.returned-drive-log-row__comment-title {
	color: var(--grey-dark);
	font-size: var(--font-h3);
	font-weight: bold;
	padding-bottom: 16px;
}

.returned-drive-log-row__comment-text {
	color: var(--black);
	font-size: var(--font-text-m);
	display: block;
	background: white;
	box-shadow: 0 8px 24px rgb(10 0 130 / 9%);
	border: 2px solid transparent;
	border-radius: 12px;
	line-height: 1;
	padding: 16px;
}

@media screen and (max-width: $breakpoint-mobile) {
	.returned-drive-log-row {
		grid-template-columns: 1fr 2px 1fr;
		grid-template-rows: repeat(5, auto);
		grid-row-gap: 10px;
		grid-column-gap: 15px;

		&:after {
			content: "";
			grid-column: 2;
			grid-row: 1 / 5;
			background: var(--blue-light);
			width: 2px;
		}
	}

	.dl-table .drive-log-list__row.returned-drive-log-row--driver-view {
		grid-template-columns: 1fr 10px 1fr;
		grid-template-rows: repeat(5, auto);
	}
	.service-number-cell {
		grid-column: 1;
		grid-row: 2;
		text-align: left;
		font-size: var(--font-text-xs);
	}
	.service-start-cell {
		grid-column: 1;
		grid-row: 1;
	}
	.sheet-line-number-container {
		grid-column: 1;
		grid-row: 3 / 5;
		.sheet-line-number-container__sheet-line-number {
			flex: 1;
			text-align: center;
		}
	}
	.state-cell__state {
		grid-column: 1 / 4;
		grid-row: 5;
	}
	.hours-cell__hours {
		grid-column: 3;
		grid-row: 1 / 3;
	}
	.comment-cell {
		grid-column: 3;
		grid-row: 3 / 5;
		flex-direction: column;
		align-items: stretch;
		.comment-cell__observations {
			flex: auto;
			margin-top: 10px;
		}
	}
}
