.icon {
  flex: 0 0 auto;
  display: inline-flex;
  width: 1em;
  height: 1em;

  svg {
    display: block;
    width: 100%;
    color: inherit;
    fill: currentColor;
  }
}
