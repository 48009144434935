.dl-schedule-document-list-page {
	.popup-full-page__content {
		margin-top: 0;
	}
	.pfp__header {
		position: sticky;
		top: 0;
		z-index: 2;
	}
}

.dl-schedule-document-list-page__attachment-banner {
	width: 100vw;
}

.dl-schedule-document-list-page__button--fixed {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 2;
	border-radius: 0;
	height: 90px;
}

.no-result__searching-mouse-img {
	background-repeat: no-repeat;
	background-position: center center;
	height: 250px;
	width: 300px;
	margin: 40px 0;
}

.no-result__text {
	text-align: center;
	font-size: var(--font-text-xl);
	font-weight: bold;
	margin: 60px 0;
	color: var(--black);
}
