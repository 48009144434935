@import "../../../../core/theme/variables";

.returned-drive-log-header {
	display: grid;
	grid-template-columns: 100px 50px 90px 175px 240px 210px;
	justify-content: space-between;
	padding: 15px 25px;

	color: var(--black);
	font-size: var(--font-text-s);
	background: transparent;
	box-shadow: none;
	font-weight: bold;

	.returned-drive-log-row__service-number {
		text-align: center;
	}
	.returned-drive-log-row__sheet-line-number {
		text-align: center;
	}
	.returned-drive-log-row__hours {
		font-size: var(--font-text-s);
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.returned-dl-table .returned-drive-log-header {
		display: none;
	}
}
