@import "../../../../../../core/theme/variables";

.document-pdf-screen__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 115vh;
	width: 100%;
	height: 100%;
	overflow: hidden;
	margin-bottom: 20px;
}

.document-pdf-screen__content__result {
	display: flex;
	align-items: center;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
	max-height: 100%;

	/* hide scrollbar */
	&::-webkit-scrollbar {
		display: none;
	}
}

.document-pdf-screen__content__result__row-layout {
	flex-direction: row;
}

.document-pdf-screen__content__result__column-layout {
	flex-direction: column;
}


.document-pdf-screen__content__no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;

	.no-result__title {
		text-align: center;
		font-size: var(--font-text-xl);
		font-weight: bold;
		margin: 10px 0;
		color: var(--black);
	}

	.no-result__searching-mouse-img {
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;
		width: 300px;
	}
}
