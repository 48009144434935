.delete-user-modal {
	padding-bottom: 32px;
	max-width: 450px;
}

.delete-user-modal__container {
	display: flex;
	flex-direction: column;
}

.delete-user-modal__container__header {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 360px;
}

.delete-user-modal__container__header__title {
	text-align: center;
	font-size: var(--font-h2);
	font-weight: bold;
	color: var(--grey-dark);
}

.delete-user-modal__container__header__icon {
	margin-top: 24px;
	width: 58px;
	height: 58px;
}

.delete-user-modal__container__content {
	display: flex;
	flex: 1;
	justify-content: center;
	text-align: center;
	font-size: 15px;
	color: var(--grey-dark);
	min-height: 32px;
	margin-top: 24px;
	margin-bottom: 32px;
}

.delete-user-modal__container__content--bold {
	font-weight: 700;
}

.delete-user-modal__container__button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.delete-user-modal__container__button-container__item {
	&:not(:last-child) {
		margin-right: 24px;
	}
}
