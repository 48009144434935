@import "../../../core/theme/variables";

.card {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background: var(--catskill-white);
	border-radius: 8px;
	padding: 20px 16px;
	margin: 12px;
	width: 640px;
}

.card__title {
	width: 100%;
	text-align: center;
	color: var(--dark);
	font-size: var(--font-text-m);
	font-weight: bold;
	margin-bottom: 32px;
}

