.toggle-switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	margin: 0 10px;
}

.toggle-switch__input {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle-switch__slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 34px;
}

.toggle-switch__slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 2.5px;
	bottom: 2.5px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 50%;
}

.toggle-switch__input:checked + .toggle-switch__slider:before {
	transform: translateX(20px);
}
