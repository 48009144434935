.line-sheet-page {
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	min-width: 60vw;
}

.line-sheet-page__content {
	flex: 1 1 auto;
}

.line-sheet-page__mobile-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 auto;
}

.line-sheet-page__mobile-content__title {
	color: var(--grey-dark);
	font-weight: bold;
	font-size: var(--font-text-ml);
	padding: 48px 0 10px 0;
}

.line-sheet-page__mobile-content__subtitle {
	color: var(--grey-dark);
	font-size: var(--font-text-ml);
	padding: 10px 0 48px 0;
}

.line-sheet-page__tab__button_container{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: var(--blue);
	border-radius: 5px;
	border: 3px solid var(--blue);
	height:54px;
	margin-bottom: 24px;
	min-width: 50vw
}

.line-sheet-page__tab__button {
	display: flex;
	width: 50%;
	border: 0;
	background-color: var(--blue);
	color: white;
}

.line-sheet-page__tab__button--selected {
	background-color: white;
	color: var(--blue);
}

.line-sheet-page__tab__icon {
	margin-left: 16px;
}

.line-sheet-page__actions {
	display: flex;
	justify-content: center;
	margin: 24px 0;
	.button:not(:last-of-type) {
		margin-right: 40px;
	}
}

.line-sheet-page__content__no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;

	.no-result__title {
		text-align: center;
		font-size: var(--font-text-xl);
		font-weight: bold;
		margin: 10px 0;
		color: var(--black);
	}

	.no-result__searching-mouse-img {
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;
		width: 300px
	}
}

.line-sheet-page__content__result {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
	max-height: 100%;

	/* hide scrollbar */
	&::-webkit-scrollbar {
		display: none;
	}
}
