@import "../../../core/theme/variables";

.attachment-picker__section {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
}

.attachment-picker__section__header {
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
}

.attachment-picker__section__header__title {
	color: var(--black);
	line-height: 22.78px;
	font-weight: 400;
	font-size: var(--font-text-ml);
}
.attachment-picker__section__header__title--bold {
	font-weight: 700;
	padding-right: 5px;
}

.attachment-picker__section__line {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 32px;
}

.attachment-picker__section__content {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 24px;
	border-radius: 16px;
	background-color: var(--catskill-white);
}

.attachment-picker__section__content__button {
	border: 0;
	text-decoration: underline;
	cursor: pointer;
	font-size: var(--font-text-xs);
	background: none;
}

.attachment-picker__section__content_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 24px;
}

.attachment-picker__section__content__header__subtitle {
	color: var(--black);
	line-height: 21px;
	font-weight: 700;
	font-size: var(--font-text-sm);
	padding-left: 20px;
}


.attachment-picker__section__content__list__item {
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 282px;
	margin-bottom: 8px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.attachment-picker__section__content__list__item__text {
	font-size: var(--font-text-s);
	color: var(--grey-dark-border);
	padding-left: 8px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.attachment-picker__section {
		display: flex;
		justify-content: center;
		align-items: center;
		// width: 350px;
	}

	.attachment-picker__section__content__list__item {
		padding-right: 40px;
	}
}
