@import "../../../../../core/theme/variables";

.observation-search {
	display: flex;
	flex-direction: column;
	width: 100%;

	.observation-search__label {
		font-size: var(--font-h3);
		color: var(--grey-dark);
		font-weight: bold;
		margin-bottom: 20px;
	}

	.observation-search__subLabel {
		font-size: var(--font-text-s);
		color: var(--grey-dark);
		margin-top: 15px;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.observation-search__subLabel {
		display: none;
		margin-top: 0;
	}

	.observation-search {
		width: 100%;

		.observation-search__label {
			margin-bottom: 25px;
		}
	}
}
