.survey-modal__content__container {
	display: flex;
	flex-direction: column;
}

.survey-modal__content {
	color: var(--grey-dark);
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-l);
	text-align: center;
	margin-bottom: 15px;
}

.survey-modal__sub-content {
	color: var(--grey-dark);
	display: flex;
	flex: 1;
	font-size: var(--font-text-s);
	text-align: start;
	margin-bottom: 15px;

}

.survey-modal__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.survey-modal__button__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 60%;
	margin-left: 75px;
}
