@import "../../../../../core/theme/variables";

.dl-month {
	padding-bottom: 60px;
}

.dl-month__title {
	background: rgba(#D4E2F6, 0.8);
	color: var(--blue-dark);
	font-size: var(--font-text-l);
	padding: 15px 25px;
	border-radius: 8px;
	text-transform: capitalize;
	margin-bottom: 30px;
}

.dl-month__dl-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-row-gap: 16px;
}
.dl-month__dl-line {
	background: white;
	padding: 25px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
}

.dl-month__empty-table-warning {
	font-size: var(--font-h2);
	color: var(--grey-dark);
	display: flex;
	justify-content: center;
}

@media screen and (max-width: $breakpoint-mobile) {
	.dl-month {
		padding-bottom: 0;
	}

	.dl-month__title {
		border-radius: 0;
		text-align: center;
	}
}
