.create-user-modal {
	padding: 30px 30px;
}

.create-user-modal__content {
	min-width: 360px;
	color: var(--dark);
	padding: 0 50px
}

.create-user-modal__content__block__id-field {
	margin-top: 30px;
	align-self: start;
}

.create-user-modal__content__block__id-field__label {
	font-size: var(--font-text-s);
}

.create-user-modal__content__block__id-field__input {
	margin-top: 10px;
	background: var(--grey-blue-light);
}

.create-user-modal__content__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	cursor: pointer;
}

.create-user-modal__content__block__title {
	flex: 1;
	font-size: var(--font-text-l);
	font-weight: bold;
	margin-bottom: 10px;
}

.create-user-modal__content__block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.create-user-modal__content__two-choices {
	margin-top: 30px;

	.two-choices-buttons__label {
		font-size: var(--font-text-m);
		font-weight: normal;
	}

	.two-choices-buttons__choices {
		margin-top: 10px;
		align-items: center;
		justify-content: space-between;
	}

	.two-choices-buttons__choices__radio {
		display: flex;
		align-items: center;
		padding: 15px 10px;
		margin-right: 0;
	}

	.two-choices__line--label {
		margin: 0 10px 0 5px;
	}

	.two-choices__line--icon {
		font-size: 24px;
	}

	.radio-button {
		background-color: var(--blue-very-light);

		&--checked {
			background-color: var(--klein-blue);
		}
	}

	.radio-button__label {
		display: flex;
		align-items: center;
	}
}

.create-user-modal__content__toggle-status, .create-user-modal__content__toggle {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	align-self: start;
	width: 100%;
	margin-top: 35px;
	font-size: var(--font-text-s);
}

.toggle__label, .toggle-status__label {
	flex-grow: 1;
}

.create-user-modal__content__toggle-status {
	.toggle-status__slider {
		background-color: var(--blue-dark);

		&.checked {
			background-color: var(--red-light);
		}
	}
}

.create-user-modal__content__toggle {
	.toggle-status__slider {
		background-color: var(--blue-dark);

		&.checked {
			background-color: var(--grey-very-light);
		}
	}
}

.create-user-modal__content__btn {
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.create-user-modal__content__validate-btn {
	display: flex;
	justify-content: center;
	font-size: var(--font-text-m);
}

.create-user-modal__content__block__id-field__error {
	display: inline-block;
	margin-top: 10px;
	font-size: var(--font-text-xs);
	color: var(--red-light);
	min-height: 20px;
}

.create-user-modal__content__dropdown {
	margin-top: 15px;
}

