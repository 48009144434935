@import "../../../theme/variables";
 
.pfp__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    color: var(--color-text);
    border-radius: 0 0 25px 25px;
    box-shadow: 0px -5px 24px rgba(6, 0, 83, 0.07);
    height: 90px;
    padding: 0 60px;
}
 
.pfp__header__link {
    border-width: 0;
    background-color: #FFFFFF;
    color: inherit;
    margin-right: auto;
    font-size: 24px;
}
 
.pfp__header__title {
    text-transform: uppercase;
    font-size: var(--font-h3);
    margin-left: -30px;
    margin-right: auto;
    color: var(--blue);
}
 
.pfp__subheader {
    display: flex;
    align-items: center;
}
 
@media only screen and (max-width: $breakpoint-mobile) {
    .pfp__header {
        padding: 0 10px;
    }
 
    .pfp__header__title {
        font-size: var(--font-mobile-h2);
        text-align: center;
        margin-left: -10px;
    }
 
    .pfp__header__link {
        margin-left: 10px;
        font-size: 20px;
    }
}