.drive-log-delay-details-table__container {
	padding-top: 32px;
}

.drive-log-delay-details-table__box-by-day {
	margin-bottom: 32px;
}

.drive-log-delay-details-table__subtitle_date {
	background-color: var(--blue-very-light);
	opacity: 0.8;
	font-size: var(--font-h2);
	color: var(--blue);
	font-weight: bold;
	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 28px;
	border-radius: 8px;
	margin-bottom: 32px;
}
