.sheet-line-number-container {
	width: 90px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.sheet-line-number-container .sheet-line-number {
	width: 100%;
	text-align: center;
	background: rgba(228, 178, 218, 0.3);
	color: var(--blue-light);
	border-radius: 8px;
	padding: 12px 10px;
	font-size:	var(--font-text-m);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.drive-log-row--headers, .returned-drive-log-header {
	.sheet-line-number {
		display: flex;
		align-items: center;
		font-size: var(--font-text-m);
	}
}
