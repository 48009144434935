@import "../../../core/theme/variables";

.special-notice__form__container {
    display: flex;
    align-items: center;
    flex-direction: column;
	width: 100%;
}

.special-notice__form__button--cancel {
	padding: 11px 15px;
	margin-right: 12px;
}

.special-notice__form__submit-block {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 20px;
	margin: 12px;
	z-index: 1;
}

.special-notice__form__field__input,
.special-notice__form__field__textarea {
	width: 100%;
}

.special-notice__form__error-row {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.special-notice__form__error-column {
	width: 240px;

	&:not(last-child) {
		margin-right: 24px;
	}
}

// Shared
.special-notice__form__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.special-notice__form__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.special-notice__form__field__label.no-margin-top {
	margin-top: 0;
}

.special-notice__form__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.special-notice__form__field__container {
		flex-direction: column;
	}
}
