.state-cell__state {
	display: flex;
	align-items: center;
	font-size: var(--font-text-sm);
	text-align: center;
	line-height: 1.5em;
}

.state-cell__status-icon {
	margin-right: 5px;

	&--validate {
		color: var(--green-jade);
	}

	&--ink-pen {
		color: var(--blue-dark);
	}

	&--empty-pen {
		color: var(--blue-dark);
	}
}
