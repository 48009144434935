.error-page__header {
	display: flex;
	justify-content: center;

	.dbl-header__logo-wrapper {
		margin-right: 0;
	}
}

.error-page__content {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.error-page__heading {
	font-size: var(--font-text-xl);
	font-weight: bold;
	color: var(--grey-dark);
	margin-top: 80px;
}

.error-page__message {
	font-size: var(--font-text-l);
	font-weight: normal;
	color: var(--blue);
	margin-top: 10px;
	text-align: center;
}

.error-page__error {
	font-size: var(--font-text-xl);
	font-weight: normal;
	color: var(--blue);
	margin-top: 50px;
	text-align: center;
}

.error-page__image {
	margin-top: 40px;
}

.error-page__button {
	margin-top: 60px;
	background-color: var(--blue-dark);
	color: var(--white);
	font-size: var(--font-text-m);
	font-weight: normal;
	padding: 20px;
	border-radius: 5px;
}
