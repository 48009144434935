@import "../../../../core/theme/variables";

.attachment-card__content {
	background: white;
	flex: 0 0 210px;
	margin: 0 32px 40px 0;
	border-radius: 10px;
	border:2px solid #9792DA;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	min-height: 110px;
	text-align: center;
}

.attachment-card__content--selected {
	border: 2px solid var(--blue-dark);
	filter: drop-shadow(0px 8px 24px rgba(10, 0, 130, 0.06));

	.content__attachment-name {
		opacity: 1;
	}

	.content__attachment-button {
		color: #FFFFFF;
		background: var(--blue-dark);
		border-top: 2px solid var(--blue-dark);
	}

}

.content__attachment-name {
	padding: 30px 0;
	opacity: 0.5;
	font-size: var(--font-text-m);
}

.content__attachment-button {
	height: 30px;
	border-top: 2px solid #9792DA;
	padding-top: 4px;
	font-size: var(--font-text-m);
}

.name__content {
	color: var(--black);
}

@media only screen and (max-width: $breakpoint-mobile) {
	.attachment-card__content {
		width: 100%;
		border-color: #FFFFFF;
		border-radius: 8px;
		margin-bottom: 18px;

		&:not(:last-child) {
			margin-right: 0;
		}
	}

	.attachment-card__content--selected {
		border: 2px solid var(--blue-light);
	}

	.content__attachment-name {
		opacity: 1;
		font-size: var(--font-text-mobile-l);
		padding: 20px 0;
	}

	.content__attachment-button {
		display: none;
	}
}
