.feedbacks-export-btn {
	margin-left: auto;
	background-color: var(--blue);
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;

	.fa-upload-icon {
		font-size: 20px;
		margin-right: 8px;
	}

	&:hover {
		background-color: darken(#007bff, 10%);
	}
}
