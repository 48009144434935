.attachment-picker__section__line__selector {
	background-color: var(--true-white);
	display: flex;
	align-items: center;
	padding: 17px 16px;
	border-radius: 8px;
}

.attachment-picker__section__line__selector--multiple {
	margin-right: 32px;
}

.attachment-picker__section__line__selector__text {
	padding: 0 8px;
	color: var(--grey-dark-border);
	font-size: var(--font-text-s);
}

.attachment-picker__section__line__selector__icon {
	width: 24px;
	height: 24px;
}
