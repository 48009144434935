@import "../../../core/theme/variables";

.resource-picker__section {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
}

.resource-picker__section__header {
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
}

.resource-picker__section__header__title {
	color: var(--black);
	line-height: 22.78px;
	font-weight: 400;
	font-size: var(--font-text-ml);
}
.resource-picker__section__header__title--bold {
	font-weight: 700;
	padding-right: 5px;
}

.resource-picker__section__content {
	display: flex;
	flex-direction: column;
}

.resource-picker__section__content__list {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
}

.resource-picker__section__content__list__item {
	display: flex;
	align-items: center;
}

.resource-picker__section__content__list-item__text {
	font-size: var(--font-text-s);
	color: var(--grey-dark-border);
	padding-left: 4px;
}

.resource-picker__section__content__observations {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 24px;
	border-radius: 16px;
	background-color: var(--catskill-white);
}

.resource-picker__section__content__observations__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 24px;
}

.resource-picker__section__content__observations__header__subtitle {
	color: var(--black);
	line-height: 21px;
	font-weight: 700;
	font-size: var(--font-text-sm);
	padding-left: 20px;
}

.resource-picker__section__content__observations__button {
	border: 0;
	text-decoration: underline;
	cursor: pointer;
	font-size: var(--font-text-xs);
	background: none;
}

.resource-picker__section__content__observations__list__item {
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 282px;
	margin-bottom: 8px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.resource-picker__section__content__observations__list__item__text {
	font-size: var(--font-text-s);
	color: var(--grey-dark-border);
	padding-left: 8px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.resource-picker__section__content__observations__list__item {
		padding-right: 40px;
	}
}
