@import "../../../../core/theme/variables";

.full-date__cells-wrapper {
	display: flex;

	&:last-child {
		margin-left: 65px;
	}
}

.full-date__cells-wrapper__date__label, .full-date__cells-wrapper__time__label {
	font-size: var(--font-text-m);
	margin-bottom: 10px;
	color: var(--grey-dark);
}

.full-date__cells-wrapper__date, .full-date__cells-wrapper__time {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.full-date__cells-wrapper__time {
	margin-left: 50px;
}

.time__cells__separator {
	font-size: var(--font-h1);
	color: var(--blue-light);
	margin: 0 10px;
}

.duration__cells__separator {
	margin: 20px 20px 0 0;
	font-size: var(--font-text-m);
}

.input-cell.input__hours, .input-cell.input__minutes {
	font-size: var(--font-text-xxl);
	padding: 22px 10px;

	.input-cell__field {
		width: 45px;
	}
}

.input-cell.input__duration__minutes, .input-cell.input__duration__seconds {
	max-width: 80px;
	margin: 25px 20px 0 0;
	padding: 10px 20px;

	.input-cell__field {
		font-size: var(--font-text-m);
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.full-date__cells-wrapper:last-child {
		margin-left: 0;
		margin-top: 25px;
	}

	.time__cells__separator {
		font-size: var(--font-text-mobile-l);
	}

	.duration__cells__separator {
		margin: 20px 20px 0 0;
		font-size: var(--font-text-m);
	}

	.input-cell.input__hours, .input-cell.input__minutes {
		padding: 12px 5px;

		.input-cell__field {
			width: 25px;
		}
	}
}
