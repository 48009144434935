.mission-details-page__header {
    display: flex;
    justify-content: center;
}

.mission-details-page__header__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

.mission-details-page__header__line {
    font-size: var(--font-text-large);
    font-weight: bold;
    color: var(--black);
}

.mission-details-page__header__line__icon {
    width: 24px;
	height: 24px;
	margin: 0 0 0 12px;
}

.mission-details-page__header__code {
    font-size: var(--font-text-l);
    font-weight: bold;
    color: var(--blue-light);
    margin: 0 38px;
}

.mission-details-page__header__station {
    font-size: var(--font-text-large);
    color: var(--grey-dark-border);
}

.mission-details-page__header__station__icon {
    margin: 0 12px;
    height: 14px;
    width: 11px;
}

.mission-details-page__content {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    &:first-child {
        margin-top: 0;
    }
}
