.add-train-number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-train-number__button {
  margin-top: 60px;
}

.add-train-number__button__link {
	margin-top: 15px;
}
