@import "../../../core/theme/variables";

.schedule {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 15%;
}

.schedule__content {
	display: flex;
}

.schedule__button {
	margin-top: 60px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.schedule {
		padding: 0 20px;
	}

	.schedule__content {
		flex-direction: column;
	}

	.schedule__button {
		width: 100%;
	}
}
