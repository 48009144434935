@import "../../../core/theme/variables";

.observation-form {
	max-width: 700px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	color: var(--grey-dark);
	font-size: var(--font-text-m);
	min-width: 480px;
}

.spinner {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	margin-left: 20px;
	font-size: 10px;
	position: relative;
	border-top: .5em solid rgba(255, 255, 255, 1);
	border-right: .5em solid rgba(255, 255, 255, 1);
	border-bottom: .5em solid rgba(255, 255, 255, 1);
	border-left: .5em solid var(--blue-dark);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}


.observation-form__validate-button {
	align-self: center;
	margin: 60px 0;
}

.observation-form__error-message {
	align-self: center;
	color: red;
	margin: 60px 0 0 0;
}

.observation__subfields {
	margin-top: 40px;
	width: 100%;
	background-color: var(--catskill-white);
	padding: 24px 32px 32px;
	border-radius: 8px;
}

.observation__repeat-button {
	margin-top: 40px;
}

.observation__group {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	margin-top: 55px;

	.observation__field {
		margin-top: 0;
	}

	&__separator {
		align-self: flex-end;
		margin: 20px;
	}
}

.observation__group:first-of-type {
	margin-top: 15px;
}

.observation__group--column {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 55px;
}


@media screen and (max-width: $breakpoint-mobile) {
	.observation-form {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		color: var(--grey-dark);
		min-width: 300px;
		margin: 0 15px;
	}

	.observation-form__validate-button {
		width: 100%;
		margin: 30px 0;
	}
}
