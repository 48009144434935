@import "../../../core/theme/variables";

.disable-line-sheets-page__modal {
	min-width: 430px;
}

.disable-line-sheets-page__modal__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	cursor: pointer;
}

.disable-line-sheets-page__modal__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 60px;
}

.disable-line-sheets-page__modal__content__title {
	font-size: var(--font-text-large);
	color: var(--blue-intense);
	font-weight: bold;
}

.disable-line-sheets-page__modal__content__text {
	font-size: var(--font-text-ml);
	color: var(--black);
	font-weight: bold;
	margin-top: 40px;
	line-height: 30px;
	text-align: center;
}

.disable-line-sheets-page__modal__button-group {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.disable-line-sheets-page__modal__button {
	&:first-child {
		margin-right: 30px;
	}
}

.disable-line-sheets-page__modal__instruction{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--grey-dark);
	font-size: var(--font-text-s);
	margin-top: 16px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.disable-line-sheets-page__modal {
		min-width: 260px;
		max-width: 400px;
	}

	.disable-line-sheets-page__modal__close {
		font-size: 20px;
	}

}
