@import "../../../../../../core/theme/variables";

.delay-line {
	background: var(--white);
	padding: 20px 25px;
	display: flex;
	justify-content: space-between;
	position: relative;

	&:not(:last-of-type)::after {
		display: block;
		content: "";
		height: 1px;
		position: absolute;
		bottom: 0;
		left: 25px;
		right: 25px;
		background: var(--blue-light);
	}
}

.delay-line__description {
	font-size: var(--font-text-m);
	color: var(--black);
	font-weight: bold;
	flex: 1;
	display: flex;
	align-items: center;
}

.delay-line__description-title {
	flex-shrink:0;
}

.delay-line__description-details {
	font-size: var(--font-text-s);
	font-weight: normal;
	padding-left: 12px;
}

.delays__total {
	.delay-line__description {
		text-transform: uppercase;
	}
}

.delay-line__recap {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 120px 0;
}
.delay-line__link {
	margin-right: 15px;
	font-size: var(--font-text-xs);
	text-decoration: underline;
	flex: 35px 0;
	cursor: pointer;
}

.delay-line__time {
	color: var(--blue-light);
	font-size: 2.2rem;
	font-weight: bold;
	flex: 1;
	display: flex;
	justify-content: flex-end;
	min-height: 2.6rem;
}

.delay-line__previous-value {
	font-size: var(--font-text-m);
	text-decoration: line-through;
}

.delay-line__arrow-right {
	margin: 0 30px;
}

.delay-line__boolean_value {
	font-size: var(--font-text-s);
	color: var(--black);
}

.delay-line .toggle-switch {
	margin-left: 26px;
	margin-right: 0;
}

.delay-line .toggle-status__slider {
	background-color: var(--grey-very-light);
}

.delay-line .toggle-status__slider.checked {
	background-color: var(--blue-dark);
}

@media screen and (max-width: $breakpoint-mobile) {
	.delay-line {
		padding: 15px;

		&:not(:last-of-type)::after {
			left: 15px;
			right: 15px;
		}
	}
	.delay-line__description {
		font-size: var(--font-text-xs);
	}
	.delay-line__description-details {
		font-size: var(--font-text-mobile-s);
	}
	.delay-line__recap {
		flex: 90px 0;
	}
	.delay-line__link {
		font-size: var(--font-text-mobile-s);
		flex: 45px 0;
		margin-right: 12px;
	}

	.delay-line__time {
		min-height: var(--font-text-mobile-m);
		font-size: var(--font-text-mobile-m);
	}

	.delay-line__previous-value {
		font-size: var(--font-text-xs);
	}

	.delay-line__arrow-right {
		margin: 0 20px;
	}

	.delay-line__boolean_value {
		font-size: var(--font-text-mobile-s);
	}

	.delay-line .toggle-switch {
		margin-left: 10px;
	}
}

@media print {
	.delay-line__link {
		display: none;
	}
}
