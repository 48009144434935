.consistency-report-page {
	display: flex;
	flex-direction: column;
	padding: 0 65px 40px 65px;
}

.consistency-report__attachment-banner {
	margin: 0 -65px 32px -65px;
}

.consistency-report__header {
	display: flex;
	flex-direction: column;
}

.consistency-report__header__content {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
}

.consistency-report__header__content__title {
	font-size: var(--font-h1);
	color: var(--black);
}

.consistency-report__header__content__separation {
	content: "";
	background-color: var(--blue-light);
	width: 0.5px;
	height: 55px;
	min-height: 25px;
	margin-left: 19px;
}

.consistency-report__header__date {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.consistency-report__header__date__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 8px;
}

.consistency-report__services__no-services__searching-mouse-img {
	width: 300px;
	height: auto;
	margin-bottom: 30px;
}

.consistency-report__services__no-services__label-no-drive-log-to-validate {
	font-size: 28px;
	color: var(--black);
	font-weight: bold;
}

.consistency-report__services__no-services {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


