.time-picker {
	display: flex;
	align-items: center;
}

.time-picker__reset {
	background: transparent;
	border: 0;
	text-decoration: underline;
	color: var(--blue-dark);
	font-size: var(--font-text-s);
}
