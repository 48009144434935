@import "../../../../../core/theme/variables";

.observation-car-selector-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.observation-car-selector-wrapper__title {
	color: var(--grey-dark);
	font-size: var(--font-h3);
	font-weight: bold;
}

.observation-car-selector-wrapper__field {
	margin-top: 25px;
}
