@import "../../../core/theme/variables";

.comment-file-attachment {
	background-color: var(--catskill-white);
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	margin-top: 60px;
}

.comment-file-attachment__content__comment__read {
	display: flex;
	flex-direction: column;
	align-self: start;
	padding-bottom: 30px;
	width: 100%;
}

.comment-file-attachment__content__comment__read-title {
	color: var(--grey-dark);
	font-size: var(--font-h3);
	font-weight: bold;
	padding-bottom: 10px;
}

.comment-file-attachment__content__comment__read-text {
	color: var(--black);
	font-size: var(--font-text-m);
	display: block;
	background: white;
	box-shadow: 0 8px 24px rgb(10 0 130 / 9%);
	border: 2px solid transparent;
	border-radius: 12px;
	line-height: 1;
	padding: 15px;
}

.comment-file-attachment__content__comment {
	color: var(--black);
	padding-bottom: 20px;

	.content__comment__title {
		font-weight: bold;
		font-size: var(--font-text-m);
		margin-bottom: 24px;
	}
}
.comment-file-attachment__content__button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--blue-dark);
	background: var(--white);
	border: 3px solid var(--blue-dark);
	border-radius: 5px;
	appearance: none;
	padding: 12px 15px;
	font-size: var(--font-text-ml);
	font-weight: normal;
}

.comment-file-attachment__content__button--disabled {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--red);
	background: var(--btn-disabled);
	border: 2px solid var(--btn-disabled);
	border-radius: 5px;
	appearance: none;
	padding: 12px 15px;
	font-size: var(--font-text-ml);
	font-weight: normal;
	cursor: not-allowed;
}

.comment-file-attachment__content__input {
	display: none;
}

.comment-file-attachment__content__error-message {
	color: var(--red);
	font-size: var(--font-text-m);
	margin-bottom: 15px;
}

.comment-file-attachment__content__instruction {
	color: var(--grey-medium);
	font-size: var(--font-text-m);
	margin-bottom: 20px;
	align-self: center;
}

.comment-file-attachment__content__card {
	--padding-bottom-size: 30px;

	padding-bottom: var(--padding-bottom-size);
	align-self: flex-start;
	display: flex;
	flex-direction: row;
	width: 100%;

	&.comment-file-attachment__content__card--read-only {
		--padding-bottom-size: O;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.comment-file-attachment__content__card {
		--padding-bottom-size: 30px;

		padding-bottom: var(--padding-bottom-size);
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;

		&.comment-file-attachment__content__card--read-only {
			--padding-bottom-size: O;
		}
	}
}

@media print {
	.comment-file-attachment {
		display: none;
	}
}
