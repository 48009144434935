@import "../../../core/theme/variables";

.disable-line-sheets__card {
	width: 80%;
	max-width: 600px;
	height: 152px;
	display: flex;
	flex-direction: column;
	border-radius: 24px;
	background-color: var(--true-white);
	margin-top: 40px;
	position: relative;

	&:first-child {
		margin-right: 100px;
	}
}

.disable-line-sheets__card__header {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 16px;
}

.disable-line-sheets__card__content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 32px 0 0 25px;
}

.disable-line-sheets__card__content__button {
	right: 16px;
	bottom: 16px;
	position: absolute;
}

.disable-line-sheets__card__header__icon {
	width: 40px;
	height: 40px;
	margin: 0 25px 0 25px;
}

.disable-line-sheets__card__header__tag {
	border-radius: 24px;
	border: 2px solid var(--green-dp);
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	background-color: var(--white);
	right: 16px;
	position: absolute;

	&--disabled {
		border: 2px solid var(--red);
	}
}

.disable-line-sheets__card__header__tag__title {
	font-size: var(--font-text-s);
	color: var(--green-dp);
	margin: 8px 12px 8px 8px;

	&--disabled {
		color: var(--red);
	}
}

.disable-line-sheets__card__content__text {
	font-size: var(--font-text-m);
	margin-bottom: 6px;
	color: var(--black);

	&--bold {
		font-weight: bold;
	}
}

.disable-line-sheets__card__header__tag__dot {
	border-radius: 100%;
	background-color: var(--green-dp);
	width: 16px;
	height: 16px;
	margin: 8px;

	&--disabled {
		background-color: var(--red);
	}
}

.disable-line-sheets__card__header__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 8px;
}

@media only screen and (max-width: $breakpoint-tablet) {
	.disable-line-sheets__card {
		width: 80%;
		max-width: 400px;
		height: 152px;
		display: flex;
		flex-direction: column;
		border-radius: 24px;
		background-color: var(--white);
		margin-top: 40px;
		position: relative;


		&:first-child {
			margin-right: 0;
		}
	}

	.disable-line-sheets__card__header__title {
		font-size: var(--font-h3);
		color: var(--black);
		margin-bottom: 8px;
	}

	.disable-line-sheets__card__header__tag__title {
		font-size: var(--font-text-s);
		color: var(--green-dp);
		margin: 8px 12px 8px 0px;

		&--disabled {
			color: var(--red);
		}
	}

	.disable-line-sheets__card__header__icon {
		width: 40px;
		height: 40px;
		margin: 0 15px 0 25px;
	}
}
