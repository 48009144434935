.survey__row {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
}

.survey__mission_code {
	flex: 120px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--secondary-lilas);
	color: var(--blue-light);
	border-radius: 9px;
	padding: 15px;
	margin-right: 40px;
	font-size: 20px;
	font-weight: bold;
}

.survey__row__full-date {
	font-style: italic;
	font-size: var(--font-text-xs);
}

.survey__row__station-and-type {
	margin-left: 30px;
	font-size: var(--font-text-sm);

	&::before, &::after {
		content: "\"";
	}
}

.survey__row__full-date, .survey__row__station-and-type {
	display: flex;
	align-items: center;
	color: var(--black);

}

.survey__row__actions {
	display: flex;
	align-items: center;
	margin-left: auto;

}

.survey__row__actions__update,
.survey__row__actions__delete,
.survey__row__actions__read {
	font-size: var(--font-text-s);
	text-decoration: underline;
}

.survey__row__actions__delete {
	margin-left: 25px;
	cursor: pointer;
}

.survey__separator:not(:last-child) {
	border-bottom: var(--blue-light) 1px solid;
	margin-top: 15px;
}

@media print {
	.survey__row__actions__read {
		display: none;
	}
}
