@import "../../../core/theme/variables";

.export-list-container {
	display: flex;
	flex-direction: column;
}

.export-list-container--horizontal {
	margin-bottom: 32px;
}

.export-list-container--vertical {
	height: 100%;
	padding: 24px;
	border-radius: 16px;
	background-color: var(--catskill-white);
}

.export-list-container__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 24px;
}

.export-list-container__header__subtitle {
	color: var(--black);
	line-height: 21px;
	font-weight: 700;
	font-size: var(--font-text-sm);
	padding-left: 20px;
}

.export-list-container__header__button {
	border: 0;
	text-decoration: underline;
	cursor: pointer;
	font-size: var(--font-text-xs);
	background: none;
}

.export-list-container__resources__list {
	display: flex;
	justify-content: space-between;
	padding: 0 28px;
};

.export-list-container__resources__list__item {
	display: flex;
	align-items: center;
	background-color: var(--true-white);
	padding: 17px 16px;
	border-radius: 8px;
	box-shadow: 0px 8px 24px rgba(10, 0, 130, 0.06);
}

.export-list-container__resources__list__item__text {
	font-size: var(--font-text-s);
	color: var(--grey-dark-border);
	padding-left: 8px;
}

.export-list-container__attachments__list,
.export-list-container-observations__list {
	margin: 0 3px;
}

.export-list-container__attachments__list__item,
.export-list-container__observations__list__item {
	display: flex;
	align-items: center;
	padding: 17.5px 282px 17.5px 16px;
	margin-bottom: 8px;
	background-color: var(--true-white);
	border-radius: 8px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.export-list-container__attachments__list__item--checked,
.export-list-container__observations__list__item--checked,
.export-list-container__resources__list__item--checked {
	background-color: var(--blue-intense);

	.export-list-container__attachments__list__item__text,
	.export-list-container__observations__list__item__text,
	.export-list-container__resources__list__item__text {
		color: var(--true-white);
	}

}

.export-list-container__attachments__list__item__text,
.export-list-container__observations__list__item__text {
	font-size: var(--font-text-s);
	color: var(--grey-dark-border);
	padding-left: 8px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.export-list-container__resources__list__item {
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}
	}

	.export-list-container__resources__list__item--checked {
		margin-right: 8px;
	}

	.export-list-container__attachments__list__item,
	.export-list-container__observations__list__item {
		padding-right: 40px;
	}
}
