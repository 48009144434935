.custom-date-picker-filter {
	background: var(--blue-very-light);
	padding: 15px;
	border-radius: 12px;
	font-size: var(--font-text-m);
	color: var(--blue);
	border: none;
	display: flex;
	align-items: center;
}

.search-dl-dates {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	margin-left: 25px;
}

.custom-date-picker-filter__icon-close {
	margin-left: 5px;
}

.search-dl-dates__label {
	display: flex;
	align-items: flex-end;
	background: var(--blue-very-light);
	padding: 15px;
	border-radius: 12px;
	font-size: var(--font-text-m);
	color: var(--blue);
	border: none;
	cursor: pointer;
}

.custom-date-picker-filter__icon-calendar {
	font-size: 20px;
	margin-right: 10px;
}
