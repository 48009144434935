@import "../../../../../../core/theme/variables";

.observation__row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 5px;
	background-color: var(--blue-very-light-transp);
	border: 1px solid var(--blue-very-light-transp);
	border-radius: 20px;
	font-size: var(--font-text-xs);
	font-weight: normal;
	padding: 10px 16px;

	&.uncompleted {
		border: 1px solid var(--alert-red);
	}

	&:first-child {
		margin-top: 16px;
	}
}

.observation__row__description {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.observation__row__full-date {
	font-style: italic;
	font-size: var(--font-text-xs);
}

.observation__row__type-and-delay {
	margin-left: 30px;
	font-size: var(--font-text-sm);

	&::before, &::after {
		content: "\"";
	}
}

.observation__row__full-date, .observation__row__type-and-delay {
	display: flex;
	align-items: center;
	color: var(--black);

}

.observation__row__draft {
	margin-left: 10px;
	color: white;
	background-color: var(--alert-red);
	border-radius: 4px;
	padding: 5px 7px;
}

.observation__row__actions {
	display: flex;
	align-items: center;
	font-size: var(--font-text-s);
	margin-left: 25px;
	cursor: pointer;
	text-decoration: underline;
}

.observation__row__details--on {
	transform: rotate(-90deg);
}

.observation__row__details--off {
	transform: rotate(90deg);
}

@media screen and (max-width: $breakpoint-mobile) {

	.observation__row {
		flex-direction: column;
	}

	.observation__row > :not(:first-child) {
		margin-top: 10px;
	}

	.observation__row__type-and-delay {
		margin-left: 0;
	}

	.observation__row__actions__update-delete {
		margin-left: 15px;
		font-size: var(--font-text-mobile-s);
	}

	.observation__row__draft {
		margin-left: 0;
		display: flex;
		align-self: flex-start;
	}
}

@media print {
	.observation__row__details--on {
		display: none;
	}

	.observation__row__details--off {
		display: none;
	}

	.observation__row .observation-details {
		display: none;
	}

}
