.observation__read-only {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	width: 100%;
	background: var(--secondary-lilas);
	color: var(--black);
	border-radius: 8px;
	font-size: var(--font-text-m);
	padding: 15px;
	margin-top: 40px;
}

.observation__read-only__value {
	color: var(--blue-light);
	font-weight: bold;
}
