@import "../../../core/theme/variables";

.consistency-overlaps-info-modal {
    align-items: center;
	min-height: 180px;
    min-width: 400px;
    max-width: 420px;
	padding: 40px 32px;
    border-radius: 16px;
    line-height: 25px; 
}

.consistency-overlaps-info-modal__header {
	text-align: center;
	border-radius: 6px;
}

.consistency-overlaps-info-modal__header__close-icon {
	position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    background: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;

	&:focus {
		outline: none;
	}
}

.consistency-overlaps-info-modal__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.consistency-overlaps-info-modal__content__text {
	flex: 1 1;
	font-size: var(--font-text-m);
	font-weight: bold;
	text-align: center;
	color: black;
	margin-top: 16px;
	margin-bottom: 24px;
}

.consistency-overlaps-info-modal__button {
	align-items: center;
}
