@import "../../../../core/theme/variables";

.observation-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	border-radius: 10px;
	border: 2px solid transparent;
	background: var(--white);
	box-shadow: 0 3px 5px rgba(16, 70, 213, 0.2);
	padding: 0 20px;
	min-height: 12vh;

	&--selected {
		border: 2px solid var(--blue-light);
	}
}

.observation-card__label {
	color: var(--lilas);
	font-size: var(--font-text-xs);
	margin-bottom: 5px;
}

.observation-card__type {
	font-size: var(--font-text-mobile-l);
	color: black;
}
