/* Parisine Regular */
@font-face {
  font-family: Parisine;
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/parisineptf-regular.ttf') format('truetype');
}

/* Parisine Bold */
@font-face {
  font-family: Parisine;
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/parisineptf-bold.ttf') format('truetype');
}

/* Parisine Bold Italic */
@font-face {
  font-family: Parisine;
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/parisineptf-bolditalic.ttf') format('truetype');
}

/* Parisine Italic */
@font-face {
  font-family: Parisine;
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/parisineptf-italic.ttf') format('truetype');
}
