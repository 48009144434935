@import "../../../core/theme/variables";

.multi-radio__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.multi-radio__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

.multi-radio__field__container--two-lines {
    flex-wrap: wrap;
	width: 80%;
    .multi-radio__field__button {
        margin-right: 20px;
        margin-bottom: 10px;
    }
}

.multi-radio__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.multi-radio__field__button {
	font-size: var(--font-text-s);
	padding: 16px;
	border-radius: 8px;
	margin-right: 32px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.multi-radio__field__container {
		flex-direction: column;
	}

	.multi-radio__field__button {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	.radio-button__label {
		justify-content: flex-start;
	}
}
