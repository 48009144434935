.consistency-service-list {
	background: var(--white);
	padding: 20px 32px;
	display: flex;
	justify-content: space-between;
	position: relative;

	&:not(:last-of-type)::after {
		display: block;
		content: "";
		height: 1px;
		position: absolute;
		bottom: 0;
		left: 25px;
		right: 25px;
		background: var(--blue-light);
	}
}

.consistency-service-list__row {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.consistency-service-list__row__cell {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.consistency-service-list__row__cell__service-number {
	color: var(--black);
	font-size: var(--font-text-m);
}

.consistency-service-list__row__cell__button {
	right: 0;
}
