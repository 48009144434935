.driver-page__content {
	flex: 1;
}

.service-infos__title, .delays-title, .mission-list-and-obs__title, .no-mission-observations__title {
	color: var(--black);
}

@media print {
	.driver-page__content {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 100px 1fr;
		grid-template-areas: "print-header" "print-content"
	}
}

.driver-page__button {
	position: fixed;
	top: 285px;
	right: 0;
	background-color: #FFAD7A;
	border: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 2;
	width: 48px;
	height: 48px;
}

.driver-page__button::before {
	content: "";
	position: absolute;
	
	background-color: transparent;
	bottom: -20px;
	right: 0px;
	height: 20px;
	width: 10px;
	border-top-right-radius: 20px;
	box-shadow: 0 -12px 0 0 #FFAD7A;
  }

.driver-page__button__icon {
	width: 1.5em;
	height: 1.5em;
}
