@import "../../../theme/variables";

.multi-input-cells {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.multi-input-cells__container {
		display: flex;
		flex-direction: row;
	}

	.input-cell {
		max-width: 75px;
		max-height: 90px;
		padding: 20px 15px;
		margin: 0 15px;

		&:focus-within {
			border: 2px solid var(--blue-light);
		}
	}

	&__close {
		appearance: none;
		background: transparent;
		border: none;
		color: var(--blue);
		margin-left: 10px;
		font-size: 24px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;

		&:focus {
			outline: none;
		}
	}
}

.close-button-placeholder {
	width: 48px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.multi-input-cells {
		flex-direction: column;

		.input-cell {
			max-width: 40px;
			max-height: 60px;
			margin: 0 10px;
			padding: 10px 5px;
		}

		&__close {
			align-self: flex-end;
			font-size: var(--font-text-xs);
			text-decoration: underline;
		}
	}
}
