.observation-text {
	width: 100%;
}

.observation-text__label {
	display: block;
	margin-bottom: 20px;
	font-size: var(--font-h3);
	color: var(--grey-dark);
	font-weight: bold;
}
