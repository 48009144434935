.bottom-modal {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: var(--true-white);
	box-shadow: 0px -8px 24px rgba(10, 0, 130, 0.06);
};

.bottom-modal--show {
	animation: var(--fade-in) 800ms ease-in forwards ;
}

.bottom-modal--hide {
	animation: var(--fade-out) 800ms ease-out forwards;
	animation-delay: 3s;
}
