@import "../../../core/theme/variables";

.dl-table {
	width: 100%;
	.drive-log-row {
		display: grid;
		grid-template-columns: 100px 50px 90px 175px 175px 240px;
		&--amp-validated-view {
			grid-template-columns: 90px 50px 90px 175px 140px 240px 200px;
		}
		&--driver-view {
			grid-template-columns: 100px 50px 90px 175px 240px 210px;
		}
		&--hr-to-archive-view {
			grid-template-columns: 100px 50px 200px 175px 60px 60px 60px 60px 60px 210px;
		}
		&--hr-archived-view {
			grid-template-columns: 100px 50px 200px 175px 60px 60px 60px 60px 60px 210px;
		}
		&--consultant-view {
			grid-template-columns: 90px 60px 90px 175px 140px 240px 200px;
		}
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.dl-table {
		padding: 25px 0;
	}
}
