@import "../../../core/theme/variables";

.datePicker {
	background: #B1B1B3;
	opacity: 0.15;
	border: 2px solid transparent;
	border-radius: 10px;
	box-shadow: inset 0 2px 3px rgba(74, 74, 79, 0.7);
	line-height: 1;
	padding: 25px;
	text-align: center;
	outline: none;
	font-size: var(--font-text-xxl);
	font-weight: bold;
	width: 170px;
	color: var(--blue-light);

	&:focus-within {
		background: #FFFFFF;
		opacity: 1;
		border-color: var(--blue-light);
		box-shadow: 0 7px 24px rgba(16, 70, 213, 0.2);
	}
}

.datePicker--filled {
	background-color: var(--white);
	opacity: 1;
	box-shadow: 0 3px 5px rgba(16, 70, 213, 0.2);
}

.datePicker--large {
	width: 237px;
	height: 88px;
}

.datePicker--error {
	border: 2px solid var(--alert-red);
}

.react-datepicker__tab-loop {
	font-size: 2em;
}

.react-datepicker {
	font-size: 1em;
}

.react-datepicker__header {
	text-align: center;
	background-color: var(--blue-dark);
	padding-top: 1em;
	position: relative;
}

.react-datepicker__month {
	margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
	width: 1.9em;
	line-height: 1.9em;
	margin: 0.166em;
}

.react-datepicker__day-name, .react-datepicker__current-month {
	color: #FFFFFF;
}

.react-datepicker__today-button, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
	background: var(--blue-dark);
	border-radius: 50%;
	text-align: center;
	font-weight: bold;
}

.react-datepicker__navigation {
	top: 1.5em;
	line-height: 1.7em;
	border: 1em solid transparent;
}

.react-datepicker__navigation--previous {
	border-right-color: #ccc;
	left: 1em;
}

.react-datepicker__navigation--next {
	border-left-color: #ccc;
	right: 1em;
}

.react-datepicker__navigation--next, .react-datepicker__navigation--previous {
	outline: none;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-top: none;
	border-bottom-color: var(--blue-dark);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
	font-size: 1.5em;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.datePicker {
		padding: 15px 0;
		font-size: var(--font-text-mobile-l);
		width: 110px;
	}

	.react-datepicker {
		font-size: 0.8em;
	}

	.react-datepicker__navigation {
		border: 0.8em solid transparent;
	}
}
