.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: var(--linear-background);
}

@media print {
	.page {
		background: none;
		display: block;
		min-height: auto;
		max-height: 100%;
		border: none;
	}
}
