.plus-icon {
	margin-right: 10px;
	background: var(--blue-dark);
	border-radius: 50%;
	color: white;
	font-size: 25px;
	padding: 6px;
}

.plus-icon-span {
	color: var(--blue-dark);
	text-decoration: underline;
	font-size: var(--font-text-s);
	font-weight: bold;
}
