.archived-dl-page {
	padding: 25px 50px;
	display: flex;
	flex-direction: column;
}

.archived-dl-page__filters {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
}

.archived-dl-page__title {
	font-size: var(--font-h2);
	font-weight: bold;
	color: var(--black);
	margin-top: 40px;
}

.archived-dl-page__controls {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.archived-dl-page__empty-dls {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.archived-dl-page__searching-mouse-img {
	width: 300px;
	height: auto;
}

.archived-dl-page__empty-dls-label {
	margin-top: 40px;
	font-size: 28px;
	color: var(--black);
	font-weight: bold;
}
