.consistency-overlaps-list__row-container {
	&:not(:last-of-type) {
		border-bottom: 2px solid var(--blue-light-background);
	}
}

.consistency-overlaps-list__row {
	display: grid;
	grid-template-columns: 30% 30% 30% 10%;
	padding: 16px 0;
	align-items: center;
}

.consistency-overlaps-list__row__cell {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.consistency-overlaps-list__row__cell-identity {
	padding-left: 24px;
	justify-content: start;
}

.consistency-overlaps-list__row__cell-start {
	justify-content: end;
	padding-right: 24px;
}

.consistency-overlaps-list__row__cell-end {
	justify-content: start;
	padding-left: 24px;
}

.consistency-overlaps-list__row__cell-delete {
	padding-right: 9px;
}

.consistency-overlaps-list__row__identity {
	color: var(--black);
	font-size: 15px;

	&.consistency-overlaps-to-be-deleted {
		color: var(--grey-light);
	}
}

.consistency-overlaps-list__row__station {
	width: 100%;
	text-transform: capitalize;
	color: var(--black);
	font-size: var(--font-text-s);
	font-weight: bold;
	text-align: right;

	&.consistency-overlaps-to-be-deleted {
		color: var(--grey-light);
	}
}

.consistency-overlaps-list__row__station-end {
	text-align: left;
}

.consistency-overlaps-list__row__hour {
	font-size: var(--font-text-s);
	background: black;
	color: var(--yellow);
	border-radius: 9px;
	padding: 6px 8px;
	margin: 8px 0;
	font-weight: bold;

	&.consistency-overlaps-to-be-deleted {
		background: var(--grey-light);
		color: var(--grey-medium-light);
	}
}

.consistency-overlaps-list__row__delete {
	font-size: 14px;
}

.consistency-overlaps-list__row__validate {
	font-size: 14px;
}

.consistency-overlaps-edited {
	color: var(--green-dp);
}
