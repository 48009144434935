.update-train-composition-modal {
	padding: 32px;
}

.update-train-composition-modal__content {
	min-width: 360px;
	color: var(--dark);
	padding: 0 50px
}

.update-train-composition-modal__content__close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	cursor: pointer;
}

.update-train-composition-modal__content__block__title {
	flex: 1;
	font-size: var(--font-text-large);
	color: var(--klein-blue);
	font-weight: bold;
	margin: 26px 0 56px 0;
	text-transform: uppercase;
}

.update-train-composition-modal__content__block__subtitle {
	font-size: var(--font-text-sm);
	color: var(--grey-dark);
 }

.update-train-composition-modal__content__block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.update-train-composition-modal__content__btn {
	display: flex;
	justify-content: center;
	margin-top: 96px;
}

.update-train-composition-modal__content__validate-btn {
	display: flex;
	justify-content: center;
	font-size: var(--font-text-m);
}

.update-train-composition-modal__content__block__choice {

	.two-choices-buttons__choices {
		justify-content: center;
		margin-top: 12px;

		.two-choices-buttons__choices__radio {
			margin: 0 8px;
		}
	}
}

