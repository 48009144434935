@import "../../../../core/theme/variables";

.dl-pcc-notice-page {
	.popup-full-page__content {
		margin-top: 0;
	}
	.pfp__header {
		position: sticky;
		top: 0;
		z-index: 2;
	}
}

.dl-pcc-notice-page__no-result {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-content: center;
	align-items: center;
}

.no-result__searching-mouse-img {
	background-repeat: no-repeat;
	background-position: center center;
 	height: 250px;
	width: 300px;
	margin: 40px 0;
}

.no-result__text {
	text-align: center;
	font-size: var(--font-text-xl);
	font-weight: bold;
	margin: 60px 0;
	color: var(--black);
}

.dl-pcc-notice-page__pcc-notices {
	padding: 40px 0;
}
