.consistency-overlaps__list__group__container {
	display: grid;
	grid-template-columns: 15% 72.5% 12.5%;
	margin-bottom: 16px;
	border-radius: 16px;
	overflow: hidden;
	background: var(--white);
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
}

.consistency-overlaps__list__group__mission-code-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.consistency-overlaps__list__group__mission-code {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.consistency-overlaps__list__group-action {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.consistency-overlaps__list__group__mission-code-chip {
	width: fit-content;
	text-align: center;
	background: rgba(228, 178, 218, 0.3);
	color: var(--blue-light);
	border-radius: 8px;
	padding: 14px 16px;
	font-size: 20px;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}
