.feedbacks-list-row {
	display: grid;
	grid-template-columns: 280px 240px 360px 180px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	padding: 12px 24px;
	min-height: 86px;
	justify-content: space-between;
	color: var(--black);
	font-size: var(--font-text-m);
	cursor: pointer;
	align-content: center;

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	&:hover {
		background-color: #f9f9f9;
	}
}

.feedbacks-list-row__cell {

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border-radius: 8px;

	padding: 8px 14px;

	&.bold {
		font-weight: bold;
	}
}

.feedbacks-list-row__cell__suggestion {
	background: #11D8AF4D;
	width: 50%;
	justify-content: center;
	height: fit-content;

}

.feedbacks-list-row__cell__technicalProblem {
	background: #FF6D664D;
	width: 70%;
	justify-content: center;
	height: fit-content;
}

.feedbacks-list-row__cell__appreciation {
	background: #2C6CE64D;
	width: 70%;
	justify-content: center;
	height: fit-content;

}
