@import "../../../core/theme/variables";

.consistency-overlaps-edit-modal {
	width: 438px;
	min-height: 520px;
}

.consistency-overlaps-edit-modal__header {
	text-align: center;
	border-radius: 6px;
}

.consistency-overlaps-edit-modal__header__title {
	font-size: var(--font-text-l);
	font-weight: bold;
	color: var(--blue-light);
	margin-bottom: 8px;
}

.consistency-overlaps-edit-modal__header__subtitle {
	font-size: var(--font-text-m);
	color: var(--grey-dark);
	margin: 0px 0 24px;
}

.consistency-overlaps-edit-modal__header__close-icon {
	position: absolute;
	right: 12px;
	top: 12px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.consistency-overlaps-edit-modal__content {
}

.consistency-overlaps-edit-modal__form {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	border-radius: 8px;

	.time-picker {
		justify-content: center;
	}

	.dl-error-message {
		width: 250px;
	}
}

.consistency-overlaps-edit-modal__form__station {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.consistency-overlaps-edit-modal__form__station__label {
	font-size: var(--font-h3);
	color: var(--grey-dark);
	font-weight: bold;
	margin-bottom: 0;
}

.consistency-overlaps-edit-modal__form__station__sub-label {
	font-size: var(--font-text-s);
	color: var(--grey-dark);
	margin-top: 15px;
	margin-bottom: 10px;
}

.consistency-overlaps-edit-modal__form__time {
	margin-bottom: 50px;
	width: 250px;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto;
	grid-template-areas:
		"label reset"
		"time-picker time-picker";

	.observation-time__label {
		grid-area: label;
	}

	.time-picker {
		grid-area: time-picker;
	}

	.time-picker__reset {
		grid-area: reset;
		justify-self: start;
	}
}

.consistency-overlaps-edit-modal__form__button {
	align-items: center;
}
