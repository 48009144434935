@import "../../../core/theme/variables";

.multi-input__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.multi-input__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

.multi-input__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.multi-input__field__input {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.multi-input__field__input .multi-input-cells .input-cell {
	max-width: 48px;
	max-height: 56px;
	padding: 8px 2px;
	margin: 0 4px;

	font-size: var(--font-text-xl);
}

.multi-input__field__input .multi-input-cells__close {
	padding: 6px 8px;
	margin-left: 8px;
}

.multi-input__field__item__add-icon {
	font-size: 24px;
	margin-right: 8px;
	opacity: 0.1;
	filter: grayscale(100%);
}

.button--submit {
	border-radius: 8px;
	border: 2px solid rgba(177, 177, 179, 0.15);

	margin-left: 32px;

	&:not(:disabled) {
		border: 2px dotted rgba(10, 0, 130, 0.4);
		background: transparent;
		color: var(--blue-dark);

		.multi-input__field__item__add-icon {
			opacity: 1;
			filter: none;
		}
	}
}

.multi-input__field__list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	margin-top: 24px;
}

.multi-input__field__selected-item {
	background: var(--blue-intense);
	color: white;
	border: none;
	border-radius: 12px;
	line-height: 1;
	padding: 15px;
	width: 140px;
	height: 100%;
	font-size: var(--font-text-m);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin-top: 8px;

	&:not(last-child) {
		margin-right: 8px;
	}
}

.multi-input__field__selected-item__icon {
	background: white;
	border-radius: 30px;
	margin-left: 20px;
	font-size: 24px;
	font-weight: bold;
	color: var(--blue);
	padding: 5px;
	cursor: pointer;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.multi-input__field__container {
		flex-direction: column;
	}

	.multi-input__field__selected-item {
		margin-top: 0;
	}
}
