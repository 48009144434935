@import "../../../core/theme/variables";

.user-home-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url("../../../core/assets/images/background-home.svg");
	background-size: 1250px auto;
	background-repeat: no-repeat;
	background-position: bottom left;
}

.user-home-page__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 345px;
}

.user-home-page__title {
	font-size: var(--font-h2);
	margin-bottom: 30px;
}

.user-home-page__links {
	display: flex;
	flex-direction: column;
}

.user-home-page__links__container {
	display: flex;
	flex-direction: row;
	position: relative;
	margin-bottom: 30px;
}

.user-home-page__img {
	position: absolute;

	&.img--leaves {
		height: 35px;
		right: -60px;
		top: 20px;
	}

	&.img--grouped-arrows {
		height: 70px;
		bottom: -20px;
		left: -130px;
	}
}

@media screen and (max-width: $breakpoint-mobile){
	.user-home-page__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 15px;
	}

	.user-home-page__title {
		font-size: var(--font-text-mobile-l);
		margin: 25px 0;
		text-align: center;
	}

	.user-home-page__links {
		flex-direction: column;
	}

	.user-home-page__links__container {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
	}

	.user-home-page__img {
		display: none;
	}

	.user-home-page__no-smartphone-access {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 40px 40px 40px;
		color: var(--black);
		text-align: center;
	}

	.user-home-page__no-smartphone-access__title {
		font-size: var(--font-text-mobile-l);
		font-weight: bold;
		margin-bottom: 24px;
		line-height: 24px;
	}

	.user-home-page__no-smartphone-access__subtitle {
		font-size: var(--font-text-mobile-m);
	}

	.user-home-page__no-smartphone-access__img {
		height: 60vh;
	}
}
