@import "../../../../../../core/theme/variables";

.dl-mission-list {
	display: flex;
	flex-direction: column;
}

.header-list {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 25px 16px;
}

.header-category {
	font-weight: 700;
	color: var(--black);
  	font-size: var(--font-text-sm);
	text-align: center;

	&.vals {
		width: 15%;
	}

	&.faisceau {
		flex: 1;
	}

	&.color {
		color: var(--blue)
	}
}

.list-obs {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	background: white;
	padding: 6px 16px;
	margin-bottom: 15px;
	border-radius: 16px;
	font-size: var(--font-text-l);
	font-weight: bold;
	box-shadow: var(--list-box-shadow);
	position: relative;
}

.add-obs {
	display: flex;
	justify-content: center;
}

.dl-mission-list__mission--header {
	font-size: var(--font-text-sm);
	color: var(--black);
	padding: 0 16px;
}

.dl-mission-list__epe__delete {
	position: absolute;
	top: 50%;
	right: -50px;
	transform: translateY(-50%);
}
.dl-mission-list__tab__button_container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: var(--blue);
	border-radius: 5px;
	border: 3px solid var(--blue);
	width: 667px;
	height: 54px;
	margin: 24px auto;
	box-shadow: 0 8px 24px 0 rgba(10, 0, 130, 0.06);
	overflow: hidden;
}
.dl-mission-list__tab__button {
	background-color: var(--blue);
	border: 0;
	color: white;
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0;
	transition: background-color 0.3s, color 0.3s;
}

.dl-mission-list__tab__button--selected {
	background-color: white;
	border: 0;
	color: var(--blue);
	width: 333px;
	border-radius: 5px;
	transition: background-color 0.3s, color 0.3s;
}

.dl-mission-list__content__no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;

	.no-result__title {
		text-align: center;
		font-size: var(--font-text-xl);
		font-weight: bold;
		margin: 10px 0;
		color: var(--black);
	}

	.no-result__searching-mouse-img {
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;
		width: 300px;
	}
}

.dl-mission-list__content__result {
	justify-content: center;
	display: flex;
	align-self: center;
	max-height: 60vh;
	margin-bottom: 75px;
}

.dl-mission-list__epe__code, .dl-mission-list__epe__composition__value {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--blue-very-light);
	color: var(--blue);
	border-radius: 9px;
	box-shadow: var(--list-box-mission-shadow);
	cursor: pointer;
}
.dl-mission-list__epe__code {
	width: 112px;
	height: 51px;

	&--header {
		box-shadow: none;
		cursor: unset;
		background: transparent;
		color: var(--black);
		justify-content: flex-start;
		padding-left: 0;
		align-items: center;
	}
}

.dl-mission-list__epe__train-number, .dl-mission-list__epe__composition {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 12% 0;
	text-align: center;
	white-space: nowrap;
	.dl-mission-list__epe__train-number__add, .dl-mission-list__epe__composition__add  {
		color: var(--grey-dark);
		font-size: var(--font-text-m);
		text-decoration: underline;
		text-align: center;
		padding: 11px;
		display: flex;
		align-items: center;

		&.bordered {
			border: 2px dotted rgba(10, 0, 130, 0.4);
			border-radius: 8px;
			color: var(--blue-dark);
		}

		&.uncompleted {
			border-color: var(--alert-red);
		}

		.train-number__added, .composition__added {
			border-color: transparent;
		}
	}

	.dl-mission-list__epe__composition__value {
		font-weight: bold;
		width: 50%;
		height: 50px;
	}

	.train-number__add__icon, .composition__add__icon {
		font-size: 24px;
		margin-right: 10px;
	}
}
.dl-mission-list__epe__start {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid transparent;

	&--header {
		justify-content: center;
		padding: 10px;
	}

	&.uncompleted {
		border: 1px solid var(--alert-red);
		border-radius: 8px;
	}
}

.dl-mission-list__epe__start {

	align-items: flex-end;
	padding-right: 16px;
	text-align: end;
}

.dl-mission-list__epe__start__modify {
	justify-content: center;
}

.dl-mission-list__epe__start__station {
	font-weight: bold;
	font-size: var(--font-text-m);
	margin-bottom: 4px;
	color: var(--black);
}

.dl-mission-list__epe__start__modify__link {
	text-decoration: underline;
	color: var(--blue-dark);
	font-size: var(--font-text-s);
	display: flex;
	align-items: center;
}

.dl-mission-list__epe__start__modify__text {
	background: black;
	color: var(--yellow);
	font-size: var(--font-text-xs);
	font-weight: bold;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9px;
}

.dl-mission-list__epe__actions {
	display: flex;
	align-items: center;
	flex: 267px 0;
	&--header {
		text-decoration: none;
		justify-content: flex-start;
	}

	.actions__add-obs {
		font-size: var(--font-text-sm);
		background-color: var(--blue-very-light);
		white-space: nowrap;
	}

}
