.drivelog-printable-pdf {
	display: none;
}



@media print {
	.drivelog-printable-pdf {
		display: flex;
		flex-direction: column;
	}
}
