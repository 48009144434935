@import "../../../core/theme/variables";

.input-delay-page {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
}

.input-delay-page__input-cell-wrapper {
	display: flex;
	align-items: center;
}

.input-delay-page__input-cell {
	min-width: 120px;
	max-width: 150px;
}

.input-delay-page__minutes {
	font-size: var(--font-text-l);
	margin-left: 25px;
}

.input-delay-page__validate-button {
	margin-top: 40px;
	padding: 20px;
}

.input-delay-page .input-cell__field:focus {
	border-bottom: none;
}

.input-delay-page__error-message {
	color: var(--alert-red);
	margin-top: 15px;
	visibility: visible;
	&--hidden {
		visibility: hidden;
	}
}

.input-delay-page__reason{
	margin-top: 40px;
	width: 400px;
}

@media screen and (max-width: $breakpoint-mobile) {
	.input-delay-page {
		padding: 15px;
	}
	.input-delay-page__input-cell-wrapper {
		flex-direction: column;
	}
	.input-delay-page__minutes {
		margin-left: 0;
	}
	.input-delay-page__validate-button {
		margin-top: 25px;
		display: block;
		width: 100%;
	}
	.input-delay-page__reason{
		margin-top: 25px;
		width: 90%;
	}
}
