.feedbacks-filters__container {
	margin-bottom: 20px;
}

.feedbacks-filters__container__dates {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 20px;
}

.feedbacks-filters_dates {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	gap: 20px;
}
.feedbacks-filters__wrapper {
	display: flex;
	flex-direction: column;
}

.feedbacks-filters__wrapper__title {
	margin-bottom: 5px;
	color: var(--dark);
	font-size: var(--font-text-sm);
}

.feedbacks-filters__date {
	position: relative;
}

.feedbacks-filters__date-picker {
	width: 320px;
	display: flex;
	align-items: flex-end;
	padding: 15px;
	border-radius: 12px;
	font-size: var(--font-text-m);
	border: none;
	cursor: pointer;
}

.custom-date-picker-filter__calendar-icon {
	margin-left: 5px;
	height: 24px;
	width: 24px;
	position: absolute;
	top: 10px;
	right: 20px;
}

.custom-date-picker-filter__close_icon {
	position: absolute;
	margin-left: 5px;
	height: 15px;
	width: 15px;
	top: 15px;
	right: 49px;
}
