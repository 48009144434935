@import "../../../core/theme/variables";

.date-picker__section {
	display: flex;
	flex-direction: column;
	height: 211px;
	margin-top: 40px;
}

.date-picker__section__header {
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
}

.date-picker__section__header__title {
	color: var(--black);
	line-height: 22.78px;
	font-weight: 400;
	font-size: var(--font-text-ml);
}
.date-picker__section__header__title--bold {
	font-weight: 700;
	padding-right: 5px;
}

.date-picker__section__content {
	height: 100%;
	padding: 24px;
	border-radius: 16px;
	background-color: var(--blue-light-background);
	box-shadow: var(--light-background-shadow);
}

.date-picker__section__content__picker {
	display: flex;
}

.date-picker__section__content__picker__date {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	&:nth-of-type(1) {
		padding-right: 10px;
	}

	&:nth-of-type(2) {
		padding-left: 10px;
	}
}

.date-picker__section__content__picker__text {
	font-size: var(--font-text-sm);
	color: var(--grey-dark);
	margin-bottom: 12px;
}

.date-picker__section__content__picker__error {
	display: flex;
	justify-items: center;
	justify-content: center;
	align-items: center;
	margin-top: 7px;
}

.date-picker__section__content__picker__error__text {
	font-size: var(--font-text-sm);
	color: var(--alert-red);
}
