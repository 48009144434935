.drive-log-delay-details-table-cell__container {
	display: inline-grid;
	grid-template-columns: auto auto min-content;
	background-color: rgba(212, 226, 246, 0.6);
	border-radius: 8px;
	font-size: var(--font-text-s);
	font-weight: bold;
	color: var(--black);
}

.drive-log-delay-details-table-cell__container:not(:last-child) {
	margin-bottom: 8px;
	align-items: center;
}

.drive-log-delay-details-table-cell__container__pink-theme {
	background-color: var(--secondary-lilas);
}

.drive-log-delay-details-table-cell__container__transparent-theme {
	background-color: transparent;
	align-items: center;
}

.drive-log-delay-details-table-cell__label {
	padding: 13px 0 13px 16px;
}

.drive-log-delay-details-table-cell__value {
	padding: 13px 8px;
	color: var(--blue-light);
}

.drive-log-delay-details-table-cell__comment {
	padding: 13px 16px;
	font-weight: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.drive-log-delay-details-table-cell__value-editable {
	display: flex;
	flex-direction: row;
	padding: 11px 8px;
	color: var(--blue-light);
	text-align: center;
	border: 1.5px solid var(--blue-light);
	border-radius: 8px;
	box-sizing: border-box;
	width: 48px;
	input {
		outline: none;
		border: 0;
		background-color: transparent;
		padding: 0;
		color: var(--blue-light);
		font-size: var(--font-text-s);
		font-weight: bold;
		text-align: right;
	}
}

.drive-log-delay-details-table-cell__switch {
	display: flex;
	padding: 13px 16px;
	color: var(--black);
	font-weight: normal;
	align-items: center;
}
.drive-log-delay-details-table-cell__switch .toggle-switch {
	margin-right: 0;
}

.drive-log-delay-details-table-cell_toggle-status__slider {
	background-color: var(--grey-very-light);
}

.drive-log-delay-details-table-cell_toggle-status__slider.checked  {
	background-color: var(--blue-dark);
}
