.consistency-overlaps-list__title {
	display: flex;
	flex-direction: row;
	margin-bottom: 26px;
	font-size: var(--font-h2);
	color: var(--black);
	font-weight: bold;
}

.consistency-overlaps-list__title__count {
	color: var(--blue-light);
	margin-left: 8px;
}

.consistency-overlaps__list__header-container {
	display: grid;
	grid-template-columns: 15% 72.5% 12.5%;
}

.consistency-overlaps-list__header {
	display: grid;
	grid-template-columns: 30% 30% 30% 10%;
	font-size: var(--font-h5);
	color: var(--black);
	margin-bottom: 16px;
	padding: 0;
	font-weight: bold;
}

.consistency-overlaps-list__header-identity {
	padding-left: 32px;
}

.consistency-overlaps-list__header-start {
	text-align: end;
	padding-right: 24px;
}

.consistency-overlaps-list__header-end {
	text-align: start;
	padding-left: 24px;
}
