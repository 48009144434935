.consultant-dl-list-page {
	padding: 0 50px 60px 50px;
}

.consultant-dl-list-page__title {
	font-size: var(--font-h2);
	margin: 40px 0 30px 0;
}

.consultant-dl-list-page__filters {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
}

.consultant-dl-list-page__attachment-banner {
	margin: 0 -50px 32px -50px;
}

.consultant-dl-list-page__controls {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}
