@import "../../../../core/theme/variables";

.user-infos {
	display: grid;
	font-size: var(--font-text-s);

	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;

	grid-row-gap: 5px;
	grid-column-gap: 16px;
}

.user-infos__name {
	grid-column: 1;
	grid-row: 1;

	display: flex;
	justify-content: flex-end;
	font-weight: bold;
}

.user-infos__job {
	grid-column: 1;
	grid-row: 2;
	display: flex;
	justify-content: flex-end;
}

.user-infos__separator {
	color: var(--green-jade);
	margin: 0 5px;
}

.user-infos__initials {
	grid-column: 2;
	grid-row: 1 / 3;
	outline: none;
	border: none;

	background: white;
	border-radius: 50%;
	color: var(--grey-dark);

	display: flex;
	justify-content: center;
	align-items: center;

	height: 42px;
	width: 42px;

	position: relative;
}

.user-infos__initials__badge{
	position: absolute;
	display: flex;
	top: -10px;
	right: -10px;
	padding: 4px;
	border-radius: 50%;
	background: red;
	color: white;
}

.user-infos__initials__badge--icon {
	font-size: 17px;
}

.user-infos__menu {
	display: none;
	background-color: white;
	padding: 15px 25px;
	color: var(--black);
	font-size: var(--font-text-s);
	box-shadow: 0 0 24px rgba(8, 1, 91, 0.1);
	border-radius: 8px;
	position: absolute;
	top: 100%;
	z-index: 300;

	.user-infos__menu__link__logout {
		color: var(--blue-dark);
		font-size: var(--font-text-xs);
		font-weight: bold;

		&--icon {
			margin-right: 10px;
		}
	}

}

.user-infos__menu::before {
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
	border-left: 10px solid transparent;
	top: -20px;
	right: 10px;
}

.user-infos__menu--open {
	display: flex;
	flex-direction: column;
}

.user-infos__menu__link {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 25px;
	}

	&:last-child {
		margin-top: 0;
	}
}

.user-infos__menu__link__content {
	display: flex;
}

.user-infos__menu__separator {
	border: none;
	border-top: 1px solid var(--grey-very-light);
	margin: 16px 0;
}

@media screen and (max-width: $breakpoint-mobile) {
	.user-infos__name {
		display: none;
	}
	.user-infos__job {
		display: none;
	}
	.user-infos__initials {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}

	.user-infos__menu {
		width: 100%;
		right: 0;
	}

	.user-infos__menu::before {
		right: 35px;
	}
}
