@import "../../../core/theme/variables";

.multi-checkbox__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.multi-checkbox__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

.multi-checkbox__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.multi-checkbox__field__list {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	&--column {
        flex-direction: column;

		.multi-checkbox__field__item {
			margin-bottom: 16px;
		}
    }
}

.multi-checkbox__field__text {
	color: var(--dark);
	font-size: var(--font-text-s);
	margin-left: 0.5em;
}

.multi-checkbox__field__item {
	display: flex;
	align-items: center;
	background-color: var(--true-white);
	padding: 12px;
	border-radius: 8px;
	margin-right: 16px;
	cursor: pointer;

	input[type="checkbox"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		outline: 1px solid gray;
		background-color: white;
		font-size: var(--font-text-s);
		text-align: center;
		line-height: 1.2em;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	input[type="checkbox"]:checked:after {
		content: "✔";
		color: var(--green-jade);
	}
}

.multi-checkbox__field__item--checked {
	background-color: var(--klein-blue);
	box-shadow: var(--big-shadow);

	.multi-checkbox__field__text {
		color: white;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.multi-checkbox__field__container {
		flex-direction: column;
	}
}
