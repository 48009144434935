@import "../../../../core/theme/variables";

.search-field__selected {
	background: var(--blue-intense);
	color: white;
	border: none;
	border-radius: 12px;
	line-height: 1;
	padding: 15px;
	width: max-content;
	height: 100%;
	font-size: var(--font-text-m);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.search-field__selected__icon {
	background: white;
	border-radius: 30px;
	margin-left: 20px;
	font-size: 24px;
	font-weight: bold;
	color: var(--blue);
	padding: 5px;
	cursor: pointer;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.search-field__selected {
		margin-top: 0;
	}
}
