@import "../../../../../../core/theme/variables";

.dl-mission-list {
	display: flex;
	flex-direction: column;
}

.dl-mission-list__mission {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	background: white;
	padding: 6px 16px;
	margin-bottom: 15px;
	border-radius: 16px;
	font-size: var(--font-text-l);
	font-weight: bold;
	box-shadow: var(--list-box-shadow);

	position: relative;
	&--header {
		background: transparent;
		padding: 0 16px;
		margin-bottom: 10px;
		box-shadow: none;
	}
}

.dl-mission-list__mission--header {
	font-size: var(--font-text-sm);
	color: var(--black);
	margin-top: 32px;
	background: transparent;
	padding: 0 16px;
	margin-bottom: 10px;
}

.dl-mission-list__mission__delete {
	position: absolute;
	top: 50%;
	right: -50px;
	transform: translateY(-50%);
}
.dl-mission-list__tab__button_container {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: var(--blue);
	border-radius: 5px;
	border: 3px solid var(--blue);
	width: 667px;
	height: 54px;
	margin: 24px auto;
	box-shadow: 0 8px 24px 0 rgba(10, 0, 130, 0.06);
	overflow: hidden;
}
.dl-mission-list__tab__button {
	background-color: var(--blue);
	border: 0;
	color: white;
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0;
	transition: background-color 0.3s, color 0.3s;
}

.dl-mission-list__tab__button--selected {
	background-color: white;
	border: 0;
	color: var(--blue);
	width: 333px;
	border-radius: 5px;
	transition: background-color 0.3s, color 0.3s;
}

.dl-mission-list__content__no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;

	.no-result__title {
		text-align: center;
		font-size: var(--font-text-xl);
		font-weight: bold;
		margin: 10px 0;
		color: var(--black);
	}

	.no-result__searching-mouse-img {
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;
		width: 300px;
	}
}

.dl-mission-list__content__result {
	justify-content: center;
	display: flex;
	align-self: center;
	max-height: 60vh;
	margin-bottom: 75px;
}

.dl-mission-list__mission__code, .dl-mission-list__mission__composition__value, .actions__add-obs, .actions__launch-mission {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--blue-very-light);
	color: var(--blue);
	border-radius: 9px;
	box-shadow: var(--list-box-mission-shadow);
	cursor: pointer;
	margin-right: 16px;
}
.dl-mission-list__mission__code {
	width: 112px;
	height: 51px;

	&--header {
		box-shadow: none;
		cursor: unset;
		background: transparent;
		color: var(--black);
		justify-content: flex-start;
		padding-left: 0;
		align-items: center;
	}
}

.dl-mission-list__mission__train-number, .dl-mission-list__mission__composition {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 12% 0;
	text-align: center;
	white-space: nowrap;
	.dl-mission-list__mission__train-number__add, .dl-mission-list__mission__composition__add  {
		color: var(--grey-dark);
		font-size: var(--font-text-m);
		text-decoration: underline;
		text-align: center;
		padding: 11px;
		display: flex;
		align-items: center;

		&.bordered {
			border: 2px dotted rgba(10, 0, 130, 0.4);
			border-radius: 8px;
			color: var(--blue-dark);
		}

		&.uncompleted {
			border-color: var(--alert-red);
		}

		.train-number__added, .composition__added {
			border-color: transparent;
		}
	}

	.dl-mission-list__mission__composition__value {
		font-weight: bold;
		width: 50%;
		height: 50px;
	}

	.train-number__add__icon, .composition__add__icon {
		font-size: 24px;
		margin-right: 10px;
	}

	.dl-add-mission--readonly {
		text-decoration: none;
		font-size: var(--font-text-m);
		color: var(--grey-dark);
		font-weight: normal;
	}
}
.dl-mission-list__mission__start, .dl-mission-list__mission__end {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid transparent;

	&--header {
		justify-content: center;
		padding: 10px;
	}

	&.uncompleted {
		border: 1px solid var(--alert-red);
		border-radius: 8px;
	}
}

.dl-mission-list__mission__start {
	align-items: flex-end;
	padding-right: 16px;
	text-align: end;
}

.dl-mission-list__mission__start__modify {
	display: flex;
	flex-direction: row-reverse;

	> :first-child {
		margin-left: 10px;
	}
}

.dl-mission-list__mission__end {
	align-items: flex-start;
	padding-left: 16px;
}

.dl-mission-list__mission__end__modify {
	display: flex;
	justify-content: flex-start;
	> :first-child {
		margin-right: 10px;
	}
}


.dl-mission-list__mission__end__station, .dl-mission-list__mission__start__station {
	font-weight: bold;
	font-size: var(--font-text-m);
	margin-bottom: 4px;
	color: var(--black);
}

.dl-mission-list__mission__end__modify__link, .dl-mission-list__mission__start__modify__link {
	text-decoration: underline;
	color: var(--blue-dark);
	font-size: var(--font-text-s);
	display: flex;
	align-items: center;
}

.dl-mission-list__mission__end__modify__text, .dl-mission-list__mission__start__modify__text {
	background: black;
	color: var(--yellow);
	font-size: var(--font-text-xs);
	font-weight: bold;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9px;
}

.dl-mission-list__mission__actions {
	display: flex;
	align-items: center;
	flex: 267px 0;
	&--header {
		text-decoration: none;
		justify-content: flex-start;
	}
	.actions__link {
		text-decoration: none;
	}

	.actions__add-obs {
		font-size: var(--font-text-sm);
		background-color: var(--blue-very-light);
		height: 50px;
		white-space: nowrap;
	}

	.actions__launch-mission {
		margin-left: 32px;
		border-radius: 16px;
		padding: 15px 16px 15px 18px;
		display: flex;
		.actions__launch-mission__icon {
			font-size: var(--font-text-sm);
		}
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.dl-mission-list__missions {
		flex: 1;
		padding-right: 0;
	}
	.dl-mission-list__mission {
		display: grid;
		grid-template-columns: 80px 1fr;
		grid-template-rows: 1fr 1fr auto auto;
		grid-column-gap: 30px;
		grid-row-gap: 10px;
		padding: 12px;
	}

	.dl-mission-list__mission__code {
		grid-row: 1;
		grid-column: 1;
		color: var(--blue-light);
		font-size: var(--font-text-m);
		align-self: center;
	}
	.dl-mission-list__mission__start {
		grid-row: 1;
		grid-column: 2;
		align-items: flex-start;
		margin: 0;
		padding: 5px 0 5px 15px;
		&.uncompleted {
			margin-right: 0;
		}
	}
	.dl-mission-list__mission__end {
		grid-row: 2;
		grid-column: 2;
		&.uncompleted {
			margin-right: 0;
		}
	}

	.dl-mission-list__mission__actions {
		grid-row: 4;
		grid-column: 1 / 3;
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		font-size: var(--font-text-mobile-s);
		font-weight: normal;

		.actions__launch-mission {
			display: none;
		}
	}
	.dl-mission-list__mission__actions__add__text {
		font-weight: normal;
		flex: 1;
	}
	.dl-mission-list__mission__actions__plus-icon {
		display: none;
	}
	.dl-mission-list__mission__delete {
		position: static;
		transform: none;
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}

	.dl-mission-list__mission.dl-mission-list__mission--header {
		display: none;
	}

	.dl-mission-list__mission__train-number, .dl-mission-list__mission__composition {
		grid-row: 2;
		grid-column: 1;
		display: flex;
		flex-direction: column;
		&__title {
			// check size
			font-size: var(--font-text-mobile-m);
			color: var(--black);
			margin-bottom: 5px;
		}
	}

	.dl-mission-list__mission__train-number__add, .dl-mission-list__mission__composition__add {
		margin-left: 20px;

		.train-number__add__icon, .composition__add__icon {
			margin-right: 0;
			font-size: var(--font-text-m);
		}
	}

	.dl-mission-list__mission__code, .dl-mission-list__mission__train-number, .dl-mission-list__mission__composition {
		margin: 0;
	}
	.dl-mission-list__mission__start__station, .dl-mission-list__mission__end__station {
		// check size
		font-size: var(--font-text-mobile-m);
		word-break: break-word;

	}
	.dl-mission-list__mission__start__modify {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: 1fr;
		width: 100%;
		grid-column-gap: 10px;

		.dl-mission-list__mission__start__modify__link {
			grid-column: 2;
			grid-row: 1;
			display: flex;
			align-items: center;
		}
		.dl-mission-list__mission__start__modify__text {
			grid-column: 1;
			grid-row: 1;
			margin-left: 0;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

@media print {
	.dl-mission-list__mission {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 2fr 2fr;
		grid-template-rows: auto;
		grid-template-areas: "mission-code composition train-number start end" "observations observations observations observations observations";
	}

	.dl-mission-list__mission__code {
		grid-area: mission-code;
	}

	.dl-mission-list__mission__composition {
		grid-area: composition;
	}

	.dl-mission-list__mission__train-number {
		grid-area: train-number;
	}

	.dl-mission-list__mission__start {
		grid-area: start;
	}

	.dl-mission-list__mission__end {
		grid-area: end;
	}

	.dl-mission-list__mission__actions {
		display: none;
	}

	.mission__observations {
		grid-area: observations;
	}
}
