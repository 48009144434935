@import "../../../core/theme/variables";

.dl-details-subheader {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-template-areas: "names user-id attachment sheet-line";
	justify-content: space-between;
	padding: 25px 50px;
	font-size: var(--font-text-ml);
	background-color: var(--blue-very-light);
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 2;
}

.dl-details-subheader__item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.dl-details-subheader__icon {
	margin-left: 19px;
}

.dl-details-subheader__item__value {
	font-weight: bold;
	margin-left: 10px;
}

.dl-details-subheader__back-link {
	display: flex;
	align-items: center;
}

.dl-details-subheader__names {
	grid-area: names;
	cursor: pointer;
}

.dl-details-subheader__user-id {
	grid-area: user-id
}

.dl-details-subheader__attachment {
	grid-area: attachment;
}

.dl-details-subheader__sheet-line {
	grid-area: sheet-line;
	display: flex;
	justify-content: flex-end;
}

.dl-details-subheader__sheet-line--label {
	background-color: var(--dark-pink);
	border-radius: 8px;
	padding: 12px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.dl-details-subheader {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: "names names" "user-id attachment" "sheet-line sheet-line";
		font-size: var(--font-text-s);
		padding: 20px;
	}

	.dl-details-subheader__back-link {
		padding: 0;
	}

	.dl-details-subheader__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		&:not(:last-child) {
			margin-bottom: 10px
		}
	}

	.dl-details-subheader__user-id, .dl-details-subheader__attachment {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 25px;

		.dl-details-subheader__item__value {
			margin-left: 0;
		}
	}

	.dl-details-subheader__sheet-line {
		margin-left: 25px;
	}
}

@media print {
	.dl-details-subheader {
		grid-area: print-header;
		display: grid;
		margin: 0;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: "names attachment sheet-line" "user-id attachment sheet-line";
		align-items: center;
		justify-items: start;
		border-radius: 0 0 20px 20px;
		max-width: 100%;
		position: unset;
	}

	.dl-details-subheader__back-link {
		display: none;
	}

	.dl-details-subheader__names {
		.dl-details-subheader__item__value {
			margin-left: 0;
		}
	}

	.dl-details-subheader__user-id {
		display: flex;
		flex-flow: row nowrap;
	}

	.dl-details-subheader__attachment {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;

		.dl-details-subheader__item__value {
			margin-left: 0;
		}
	}

	.dl-details-subheader__icon {
		display: none;
	}
}
