.psg-option-card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--white);
	border-radius: 8px;
	box-shadow: var(--list-box-shadow);
	padding: 16px;
	width: 290px;
}

.psg-option-card__text-wrapper {
	display: flex;
	flex-direction: column;
}

.psg-option-card__text {
	font-size: var(--font-text-m);
	color: var(--black);
}

.psg-option-card__text--secondary {
	font-size: var(--font-text-mobile-s);
	line-height: 19px;
	color: var(--black);
}

.psg-option-card__action {
	display: flex;
	align-items: center;
}

.psg-option-card__action__value {
	font-size: var(--font-text-xs);
	color: var(--grey-dark);
}

.psg-option-card__toggle-status__slider {
	background-color: var(--grey-very-light);
}

.psg-option-card__toggle-status__slider.checked  {
	background-color: var(--blue-dark);
}
