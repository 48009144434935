@import "../../../../../core/theme/variables";

.survey__field {
	margin-top: 55px;
	font-size: var(--font-h3);

	.input-field {
	height: unset;
	}
}

.survey__field--check-list-button {
	display: flex;
	flex-direction: row;
}

.survey__field--ratpId {
	max-width: 140px;
}

@media screen and (max-width: $breakpoint-mobile) {
	.survey__field {
		margin-top: 30px;
	}
}
