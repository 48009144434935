@import "theme/normalize";
@import "theme/fonts";
@import "animations";

:root {
	font-family: Parisine, sans-serif;
	font-size: 10px;

	// color palette
	--dark: #040010;
	--blue-dark: #0a0082;
	--blue: #0009A4;
	--klein-blue: #0022C0;
	--pink: #E4B2DA;
	--dark-pink: #E8C2DE;
	--lilas: #CD58B0;
	--secondary-lilas: #F7E8F4;
	--light-purple: #9792DA;
	--light-pink: #F4E0F0;

	--light-green: #d1f0eb;
	--green: #00AA91;
	--blue-light: #1046D5;
	--blue-intense: #0022C0;
	--blue-light-background: #DAE5F6;
	--blue-white: #D8E4F5;
	--catskill-white: #E7EDF4;

	--black: #2A2A2E;
	--grey-dark: #38383D;
	--grey-light: #B1B1B3;
	--grey-medium-light: #EDEDF0;
	--grey-medium: #83828F;
	--grey-medium-dark: #737373;
	--grey-dark-border: #4A4A4F;

	--grey-very-light: #D7D7DB;
	--grey-extra-light: #E5E5E5;
	--grey-blue-light: #EEF3FB;

	--white: #F9F9FA;
	--light-white: #9cbcdf;
	--true-white: #fff;

	--green-jade: #11D8AF;
	--green-dp: #00C4A1;
	--yellow: #FFBE00;
	--red-light: #FF3C2F;
	--light-orchid: #E4B2DA;

	--red: #FF1400;
	--red-sheet: #df1b1b;
	--alert-red: #E80F00;
	--blue-faded: #3C91DC;
	--blue-very-light: #D4E2F6;
	--blue-very-light-transp: #E5EEFA;

	// colors
	--color-text: var(--blue-dark);

	// backgrounds
	--linear-background: linear-gradient(111.29deg, rgba(156, 188, 223, 0.16) -0.86%, rgba(217, 153, 201, 0.096) 148.84%);
	--color-bg-popup: var(--grey);
	--btn-disabled: #ECEBF1;
	--alert-red-bg: #F4CFD1;
	--icon-light-purple: rgba(228, 178, 218, 0.3);
	--overlay-opacity: rgba(0, 0, 0, 0.75);

	// shadow
	--big-shadow: 0px 7px 24px rgba(16, 70, 213, 0.2);
	--list-box-shadow: 0px 8px 24px rgba(10, 0, 130, 0.06);
	--light-background-shadow : 0px 8px 24px rgba(8, 1, 91, 0.05);
	--list-box-mission-shadow: 0px 4px 4px 0px rgba(13, 55, 165, 0.33);

	// fonts
	--font-family-parisine: Parisine, sans-serif;

	--font-h1: 2.8rem;
	--font-h2: 2.2rem;
	--font-h3: 1.8rem;
	--font-h4: 1.6rem;
	--font-h5: 1.5rem;

	--font-mobile-h1: 2.7rem;
	--font-mobile-h2: 1.4rem;
	--font-mobile-h3: 1.4rem;
	--font-mobile-h4: 1.4rem;

	--font-text-mobile-s: 1.2rem;
	--font-text-mobile-m: 1.4rem;
	--font-text-mobile-l: 1.8rem;
	--font-text-mobile-xl: 2rem;
	--font-text-mobile-xxl: 2.4rem;

	--font-text-xs: 1.3rem;
	--font-text-s: 1.4rem;
	--font-text-sm: 1.5rem;
	--font-text-m: 1.6rem;
	--font-text-ml: 1.7rem;
	--font-text-large: 1.8rem;
	--font-text-l: 2rem;
	--font-text-xl: 2.8rem;
	--font-text-xxl: 3.6rem;

	// animations
	--fade-in: fadeIn;
	--fade-out: fadeOut;
}

body {
	font-family: var(--font-family-parisine);
	color: var(--color-text);
}

h1, h2, h3, h4, h5, h6 {
	font-size: 1rem;
	font-family: var(--font-family-parisine);
}

@media print {
	body {
		overflow: visible;
		/* this affects the margin on the content before sending to printer */
		margin: 0;
	}

	* {
		-webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
	}

	* {
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		font-size: 100%;
		vertical-align: baseline;
		background: transparent;
	}

	@page {
		size: auto;   /* auto is the initial value */
		/* this affects the margin in the printer settings */
		margin: 20px 0;
	}

	@page :first {
		margin: 0 0 20px 0;
	}
}
