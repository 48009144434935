@import "../../../core/theme/variables";

.consistency-service__modal {
	width: 438px;
	height: 505px;
	padding: 58px 32px 40px;
}

.consistency-service__modal__form__container {
	display: flex;
	flex-direction: column;
}

.consistency-service__modal__form__container__header{
	display: flex;
	flex-direction: column;
}

.consistency-services__modal__form__container__header__title {
	color: var(--black);
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-large);
	text-align: center;
	margin-bottom: 5px;
}

.consistency-services__modal__form__container__header__sub-title {
	color: var(--grey-dark);
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-sm);
	text-align: center;
	margin-bottom: 20px;
}

.consistency-service__modal__form__container__content {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.consistency-service__modal__form__container__content__title {
	color: var(--grey-dark);
	display: flex;
	flex: 1;
	font-weight: bold;
	font-size: var(--font-text-m);
	text-align: start;
	margin-bottom: 2px;
}

.consistency-service__modal__form__container__content__title__comment {
	margin-bottom: 12px;
}

.consistency-service__modal__form__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.consistency-service__modal__form__button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}





