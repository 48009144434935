@import "../../../../../core/theme/variables";

.user-table-row--cell {
	cursor: pointer;
	.user__line--label {
		margin-left: 10px;
	}

	.user__line--icon {
		font-size: var(--font-text-l);
	}
}

.user-table-row--cell--disabled {
	background-color: var(--grey-light);
	cursor: default;
}

.user-row--cell {
	display: flex;
	align-items: center;

	&.user__names {
		font-weight: bold;
	}
}

.user__action__delete {
	border: 0;
	padding: 12px 4px;
	font-size: var(--font-text-m);
	color: var(--blue-dark);
	background: transparent;
	text-decoration-line: underline;
	justify-content: flex-start;
}

.user__names__first {
	margin-right: 5px;
}

.user__status__card {
	border: 1px solid;
	border-radius: 10px;
	padding: 10px;
	min-width: 90px;
	display: flex;
	align-items: center;
	font-size: var(--font-text-s);

	&--active {
		border: 1px solid rgba(17,216,175, 0.2);
		background-color: rgba(17,216,175, 0.2);
	}

	&--blocked {
		border: 1px solid rgba(255,60,47, 0.2);
		background-color: rgba(255,60,47, 0.2);
	}
}

.user__status__card--icon {
	&--active {
		color: var(--green-jade);
	}
	&--blocked {
		color: var(--red-light);
	}
}

.user__status__card--label {
	margin-left: 8px;
	font-weight: bold;
}
