@import "../../../core/theme/variables";

.footer-dl-details {
	padding: 25px 150px;
	background-color: var(--blue-very-light-transp);
	font-size: var(--font-text-s);
	color: var(--grey-dark);
	display: flex;
}

.footer-dl-details__icon {
	&--validate {
		color: var(--green-jade);
		font-size: var(--font-text-m);
	}

	&--time {
		color: var(--green-jade);
		font-size: var(--font-text-m);
	}
}

.footer-dl-details__text {
	margin-left: 10px;
}

.footer-dl-details__text__amp {
	font-weight: bold;
	margin-left: 5px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.footer-dl-details {
		padding: 25px 15px;
	}
}

@media print {
	.footer-dl-details {
		display: none;
	}
}
