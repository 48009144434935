@import "../../../../core/theme/variables";

.input-cell {
	background: rgba(177, 177, 179, 0.15);
	border: 2px solid transparent;
	border-radius: 10px;
	line-height: 1;
	padding: 20px 0;
	text-align: center;
	display: flex;
}

.input-cell:focus-within {
	background: white;
	box-shadow: 0 7px 24px rgba(16, 70, 213, 0.2);
	border: 2px solid var(--blue-light);

	.input-cell__field {
		opacity: 1;
		border-color: var(--blue-light);
		color: inherit;
	}
}

.input-cell__field {
	font-size: var(--font-text-xxl);
	max-width: 100%;
	font-weight: bold;
	border: none;
	border-bottom: 2px solid transparent;
	text-align: center;
	background: none;
	box-shadow: none;
	color: var(--blue-light);
	text-transform: uppercase;

	&:focus {
		border-bottom: 2px solid var(--blue-light);
		outline: none;
	}
}

.input-cell--filled {
	background-color: var(--white);
	opacity: 1;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09)
}

@media only screen and (max-width: $breakpoint-mobile) {
	.input-cell {
		margin: 0 8px;
		padding: 10px 0;
		border-radius: 5px;
	}

	.input-cell__field {
		font-size: var(--font-text-mobile-l);
	}
}
