@import "../../../core/theme/variables";

.users-list-page {
	flex: 1;
	display: flex;
	flex-direction: column;

	padding: 40px 50px 0 50px;
}

.users-list-page__top-options {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
}

.top-options__add-user-btn {
	position: absolute;
	right: 50px;
	min-height: 60px;
}

.top-options__add-user-btn__label {
	margin-left: 10px;
}

.users-list-page__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 30px;
}

.user-list-page__table {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.user-list-page__table--row {
	display: grid;
	grid-template-columns: 110px 270px 160px 120px 180px 120px 120px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	padding: 20px 50px;
	min-height: 70px;
	justify-content: space-between;
	color: var(--black);
	font-size: var(--font-text-m);

	&:not(:last-child) {
		margin-bottom: 15px;
	}
}

.users-list-page__load-more {
	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.users-list-page__purge-banner {
	margin: auto -50px 0 -50px;
}

.user-row--cell.user__digiplan {
	display: flex;
	justify-content: center;
}
