@import "../../../../../app";

.input-dropdown {
	position: relative;
}

.input-dropdown__field {
	position: relative;
	z-index: 100;
}

.input-dropdown__results {
	background: white;
	opacity: 1;
	box-shadow: 0 0 24px rgba(8, 1, 91, 0.1);
	border-radius: 12px;
	padding: 0 0 10px 0;
	display: flex;
	flex-flow: column nowrap;
	position: relative;
	width: 100%;
}

.input-dropdown__results:before {
	content: "";
	position: absolute;
	top: -40px;
	left: 0;
	height: 40px;
	width: 100%;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	background: transparent;
	box-shadow: 0 20px 0 0 white;
	z-index: 50;
}

.input-dropdown__results__field {
	font-size: var(--font-text-m);
	color: var(--black);
	padding: 10px 15px 10px 45px;
	background: white;
	border: none;
	width: 100%;
	text-align: left;
	z-index: 300;

	&:hover, &:focus {
		background: rgba(244, 224, 240, 1);
		color: var(--blue-light)
	}
}
