@import "../../../../core/theme/variables";

.file-attachment-card {
	--margin-size: 0 10px 10px 0;

	background-color: var(--true-white);
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	min-width: 210px;
	height: 58px;
	justify-content: space-between;
	align-items: center;
	width: 25%;
	margin: var(--margin-size);

	&:last-child {
		--margin-size: 0 0 10px 0;
	}
}

.file-attachment-card--pointer {
	cursor: pointer;
}

.file-attachment-card__content {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	height: 58px;
	width: 90%;
}

.file-attachment-card__content__trombone-icon {
	width: 14px;
	height: 27px;
	margin: 0 8px 0 12px;
}

.file-attachment-card__content__label {
	font-size: var(--font-text-m);
	color: var(--black);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
	margin: 0;
	width: 150px;
}

.file-attachment-card__content__label--pointer {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.file-attachment-card__close-icon {
	margin-right: 13px;
	margin-top: 5px;
}

.file-attachment-card__close-icon__button {
	font-size: 14px;
	background: none;
	border: none;

	&:focus {
		outline: none;
	}
}
