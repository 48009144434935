@import "../../../core/theme/variables";

.nudges-list-row {
	display: grid;
	grid-template-columns: 280px 240px 180px 180px 180px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	padding: 12px 24px;
	min-height: 86px;
	justify-content: space-between;
	color: var(--black);
	font-size: var(--font-text-m);
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.nudges-list-row__cell {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
}

.nudges-list-row__cell-station {
	font-weight: bold;
}

.nudges-list-row__cell-action {
	border: 0;
	padding: 12px 4px;
	font-size: var(--font-text-m);
	color: var(--blue-dark);
	background: transparent;
	text-decoration-line: underline;
}
