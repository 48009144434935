.dbl-spinner,
.dbl-spinner:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.dbl-spinner {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 255, 255, 1);
	border-right: 1.1em solid rgba(255, 255, 255, 1);
	border-bottom: 1.1em solid rgba(255, 255, 255, 1);
	border-left: 1.1em solid var(--blue-dark);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

.dbl-spinner-modal,
.dbl-spinner-modal:after {
	border-radius: 50%;
	width: 2.15em;
	height: 2.15em;
}

.dbl-spinner-modal {
	margin: auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.4em solid rgba(255, 255, 255, 1);
	border-right: 0.4em solid rgba(255, 255, 255, 1);
	border-bottom: 0.4em solid rgba(255, 255, 255, 1);
	border-left: 0.4em solid var(--blue-dark);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
