.simple-dls__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.simple-dls__title {
	font-size: var(--font-h2);
	color: var(--grey-dark);
	margin-bottom: 10px;
}

.simple-dls__controls {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}
