.radio-button {
	display: flex;
	flex-direction: row;
	background-color: white;
	cursor: pointer;
}
.radio-button__input {
	appearance: none;
	display: none;
}

.radio-button__tick {
	display:flex;
	align-items: center;
	justify-content: center;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background-color: white;
	border: 2px solid var(--grey-dark);
	margin-right: 0.5em;
	margin-top: 0.05em;

	&:before {
		background-color: var(--green-jade);
		border-radius: 50%;
	}
}

.radio-button__label {
	color: var(--grey-dark);
	line-height: 1;
	flex-grow: 1;
	display: flex;
	flex-flow: row;
	justify-content: center;
}

.radio-button--checked {
	background-color: var(--klein-blue);
	box-shadow: var(--big-shadow);

	.radio-button__label {
		color: white;
	}

	.radio-button__tick {
		border: none;
		&::before {
			content: " ";
			width: 0.4em;
			height: 0.4em;
		}
	}
}
