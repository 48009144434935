@import "../../../../../core/theme/variables";

.observation-file-attachment {
	display: flex;
	flex-direction: column;
}

.observation-file-attachment__content__button {
	display: flex;
	color: var(--blue-dark);
	background: var(--white);
	border: 3px solid var(--blue-dark);
	border-radius: 5px;
	appearance: none;
	padding: 12px 15px;
	font-size: var(--font-text-ml);
	font-weight: normal;
}

.observation-file-attachment__content__button-group {
	align-self: center;
}

.observation-file-attachment__content__button--disabled {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--red);
	background: var(--btn-disabled);
	border: 2px solid var(--btn-disabled);
	border-radius: 5px;
	appearance: none;
	padding: 12px 15px;
	font-size: var(--font-text-ml);
	font-weight: normal;
	cursor: not-allowed;
}

.observation-file-attachment__content__input {
	display: none;
}

.observation-file-attachment__content__error-message {
	color: var(--red);
	font-size: var(--font-text-m);
	margin-bottom: 15px;
}

.observation-file-attachment__content__instruction {
	color: var(--grey-medium);
	font-size: var(--font-text-m);
	margin-bottom: 20px;
	align-self: center;
}

.observation-file-attachment__content__card {
	--padding-bottom-size: 30px;
	padding-bottom: var(--padding-bottom-size);
	display:flex;
	max-width: 475px;
	flex-wrap: wrap;

	&.observation-file-attachment__content__card--read-only {
		--padding-bottom-size: O;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.observation-file-attachment__content__card {
		--padding-bottom-size: 30px;

		padding-bottom: var(--padding-bottom-size);
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;

		&.observation-file-attachment__content__card--read-only {
			--padding-bottom-size: O;
		}
	}
}

