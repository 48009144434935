@import "../../../../core/theme/variables";

.dl-schedule-document-page__content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.dl-schedule-document-page__tab__button_container{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: var(--blue);
	border-radius: 5px;
	border: 3px solid var(--blue);
	width: 667px;
	height:54px;
	margin-bottom: 24px
}

.dl-schedule-document-page__tab__button {
	background-color: var(--blue);
	border: 0;
	color: white;
	width: 333px;
}
.dl-schedule-document-page__tab__button--selected {
	background-color: white;
	border: 0;
	color: var(--blue);
	width: 333px;
}

.dl-schedule-document-page__content__no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 10px;

	.no-result__title {
		text-align: center;
		font-size: var(--font-text-xl);
		font-weight: bold;
		margin: 10px 0;
		color: var(--black);
	}

	.no-result__searching-mouse-img {
		background-repeat: no-repeat;
		background-position: center center;
		height: 250px;
		width: 300px
	}
}

.dl-schedule-document-page__content__result {
	display: flex;
	align-self: center;
	max-height: 60vh;
	margin-bottom: 75px

}

.dl-schedule-document-page__actions {
	display: flex;
	justify-content: center;
	.button:not(:last-of-type) {
		margin-right: 40px;
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.dl-schedule-document-page__actions {
		display: flex;
		flex-direction: column;

		.button:not(:last-of-type) {
			margin: 0;
		}
	}
}
