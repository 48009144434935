*, *::before, *::after {
	box-sizing: border-box;
	font-family: Parisine, sans-serif;
}

body {
	margin: 0;
	color: var(--color-text);
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

button {
	cursor: pointer;
}

:disabled {
	pointer-events: none;
}

input, textarea {
	width: 100%;
	display: block
}

input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

a:focus, button:focus {
	box-shadow: none;
}

:-moz-focusring {
	outline: none;
	box-shadow: none;
}

ul, ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

li {
	padding: 0;

	margin: 0;
}
