.modify-attachment-schedule-document-page__title {
	font-size: var(--font-h1);
	color: var(--black);
	margin-bottom: 80px;
	text-align: center;
}

.modify-attachment-schedule-document-page__content {
	padding: 0 130px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modify-attachment-schedule-document-page__attachment-list {
	width: 100%;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1250px;
}
