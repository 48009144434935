.export-page__content {
	flex: 1;
}

.export-page__content__title {
	font-size: var(--font-text-xl);
	font-weight: 700;
	color: var(--black);
	margin-top: 40px;
	line-height: 124%
}

.export-page__loader {
	display: flex;
	height: 100vh;
	align-items: center;
}
