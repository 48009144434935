@import "../../../core/theme/variables";

.special-notice-list-row {
	display: grid;
	grid-template-columns: 280px 240px 240px 60px 160px 80px;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	padding: 12px 24px;
	min-height: 86px;
	justify-content: space-between;
	color: var(--black);
	font-size: var(--font-text-m);
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.special-notice-list-row--enclosed {
	grid-template-columns: 80px 80px 280px 240px 240px 60px 80px;
}

.special-notice-list-row__cell {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
}

.special-notice-list-row__cell-station {
	font-weight: bold;
}

.special-notice-list-row__cell-mission-container {
	width: 72px;
	height: 32px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;

	&:nth-child(n + 1):nth-child(-n + 2),
	&:nth-child(n + 4) {
		margin-right: 8px;
	}

	&:nth-child(n + 4):nth-child(-n + 5) {
		margin-top: 8px;
	}
}

.special-notice-list-row__cell-mission {
	width: 100%;
	text-align: center;
	background: rgba(228, 178, 218, 0.3);
	color: var(--blue-light);
	border-radius: 8px;
	padding: 8px 4px;
	font-size: 14px; // var(--font-text-m);
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.special-notice-list-row__cell-mission--all {
	background: transparent;
	border: 2px solid var(--blue-light);
	color: var(--blue-light);
	padding: 6px 4px;
}

.special-notice-list-row__cell-schedule {
	font-weight: bold;
	color: var(--blue-light);
	text-align: center;
}

.special-notice-list-row__cell-schedule__arrow {
	transform: rotate(90deg);
	font-size: var(--font-text-xs);
	margin-top: 2px;
}

.special-notice-list-row__cell-action {
	border: 0;
	padding: 12px 4px;
	font-size: var(--font-text-m);
	color: var(--blue-dark);
	background: transparent;
	text-decoration-line: underline;
}
