.survey-check-list-button {
	margin-top: 25px;
	margin-right: 25px;
	background-color: var(--white);
	border-radius: 10px;
}
.survey-check-list-button__input {
	margin-left: 25px;
	align-self: center;
	display: block;
	width: 32px;
}

.survey-check-list-button__choices {
	display: flex;
	flex-flow: nowrap;
	flex-direction: row;
}

.survey-check-list-button__comment {
	width: 90%;
	margin: 25px;
}

.survey-check-list-button__label-container {
	display: flex;
	flex-direction: column;
	margin: 25px;
}

.survey-check-list-button__label {
	color: var(--grey-dark);
	line-height: 1;
	flex-grow: 1;
	display: flex;
	flex-flow: row;
}

.survey-check-list-button__sub-label {
	color: var(--grey-dark);
	line-height: 1;
	flex-grow: 1;
	display: flex;
	flex-flow: row;
	font-style: italic;
}

.survey-check-list-button--checked {
	background-color: var(--klein-blue);
	box-shadow: var(--big-shadow);
	display: flex;
	flex-direction: column;
	border-radius: 10px;

	.survey-check-list-button__label {
		color: white;
	}

	.survey-check-list-button__sub-label {
		color: white;
	}
}
