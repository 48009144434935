.schedule-sheet-table-content-cell {
	white-space: nowrap;
	padding: 2px 8px;
	color: var(--blue);
	/* For content cells, apply the border to one of each side only (right but not left, bottom but not top) */
	border-bottom: 2px solid var(--blue);
	border-right: 2px solid var(--blue);
}

.schedule-cell-yellow {
	background-color: yellow;
	color: inherit;
}

.schedule-cell-red {
	background-color: var(--red-sheet);
	color: white;
}

.schedule-cell-blue-dot {
	height: 8px;
	width: 8px;
	display: flex;
	align-self: center;
	justify-content: center;
	background-color: var(--blue);
	border-radius: 50%;
	margin: auto;
}

