@import "../../../../../core/theme/variables";

.home-profile-card {
	display: flex;
	flex : 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: white;
	border: 3px solid transparent;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	color: var(--dark);
	border-radius: 15px;
	padding: 20px;
	z-index: 1;

	&:not(:last-of-type) {
		margin-right: 30px;
	}

	&.card-supervisor, &.card-driver, &.card-notice {
		padding: 25px;
	}

	&:hover {
		border: 3px solid var(--blue-light);
		box-shadow: 0 7px 24px rgba(16, 70, 213, 0.2);
	}
}

.home-profile-card__title {
	font-size: var(--font-text-m);
	font-weight: bold;
}

.home-profile-card__img {
	min-height: 120px;
	margin-top: 15px;
}

.home-profile-card__description {
	font-size: var(--font-text-m);
	flex: 1;
	text-align: center;
	margin-top: 10px;
}

.home-profile-card__button {
	margin-top: 15px;
}

@media screen and (max-width: $breakpoint-mobile){

	.home-profile-card {
		&:not(:last-of-type) {
			margin-right: 0;
			margin-bottom: 10px;
		}

		&.card-supervisor, &.card-driver, &.card-notice {
			padding: 15px;
		}

		&.card-supervisor, &.card-notice {
			margin-right: 15px;
			margin-bottom: 0;
		}
	}

	.home-profile-card__title {
		text-align: center;
	}

	.home-profile-card__img {
		min-height: 70px;
		margin-top: 0;
	}

	.home-profile-card__description, .home-profile-card__button {
		display: none;
	}
}
