.consistency-services__table__title {
	font-size: var(--font-h2);
	color: var(--black);
	margin-bottom: 26px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
}

.consistency-services__table__title__count {
	color: var(--blue-light);
	margin-left: 8px;
}

.consistency-services__table__sub-title {
	font-size: var(--font-h5);
	color: var(--black);
	margin-bottom: 16px;
	margin-left: 32px;
	font-weight: bold;
}

.consistency-service__table__row {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.09);
}
