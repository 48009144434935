@import "../../../core/theme/variables";

.add-sheet-line {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.add-sheet-line__cells {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.add-sheet-line__icon-close {
	font-size: 24px;
	position: absolute;
	right: -50px;
}

.add-sheet-line__button {
	margin-top: 60px;
}

.add-sheet-line__cell {
	width: 370px;

	.input-cell__field {
		width: 100%;

		&:focus {
			border-bottom: none;
		}
	}
}

.add-sheet-line__link {
	margin-top: 15px;
	font-size: var(--font-text-xs);
	text-decoration: underline;
}

.add-sheet-line__wrapper__header__title {
	display: flex;
	justify-content: center;
	color: var(--dark);
	font-size: var(--font-h1);
	font-weight: bold;
	margin-top: 26px;
}

.add-sheet-line__wrapper__header__text {
	display: flex;
	justify-content: center;
	color: var(--dark);
	font-size: var(--font-text-l);
	margin-top: 8px;
	margin-bottom: 19px;
}

.add-sheet-line__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: #{$breakpoint-mobile}) {
	.add-sheet-line {
		padding: 0 20px;
	}

	.add-sheet-line__cell {
		width: 100%;
	}

	.add-sheet-line__icon-close {
		font-size: 20px;
		right: -25px;
	}

	.add-sheet-line__button {
		width: 100%;
	}
}

