@import "../../../../core/theme/variables";

.observation__field {
	margin-top: 55px;
	font-size: var(--font-h3);

	&:first-of-type {
		margin-top: 0;
	}

	.input-field {
		height: unset;
	}
}

.observation__field--ratpId {
	max-width: 140px;
}

@media screen and (max-width: $breakpoint-mobile) {
	.observation__field {
		margin-top: 30px;
	}
}
