.modal__container {
	display: flex;
	width: 100%;
}

.modal__container__content {
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-h3);
	text-align: center;
}

.modal__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.modal__container__button {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	margin-top: 50px;
}
