@import "../../../core/theme/_variables.scss";

.survey-form {
	max-width: 700px;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	color: var(--grey-dark);
	font-size: var(--font-text-m);
	min-width: 475px;
}

.survey-form__button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.survey-form__button-block {
	display: flex;
	flex-direction: row;
	margin: 60px;
}

.survey-form__button-disabled {
	color: var(--grey-light);
	background: var(--btn-disabled);
	border: 2px solid var(--btn-disabled);
}

.survey__subfields {
	margin-top: 40px;
	width: 100%;
	background-color: var(--catskill-white);
	border-radius: 8px;
}

.survey__group {
	border-radius: 10px;
	margin-top: 55px;
	background-color: var(--catskill-white);
	width: 100%;
	break-inside: avoid;

	.survey__group__container {
		display: flex;
		flex-direction: column;
		background-color: var(--catskill-white);
		margin: 55px;
	}

	.survey__group__children-container {
		margin-top: 12px;
	}

	.survey__group__title {
		font-weight: bold;
		font-size: large;
	}

	.survey__field {
		margin-top: 20px;
	}

	&__separator {
	 	align-self: flex-end;
		margin: 20px;
 	}
}


@media screen and (max-width: $breakpoint-mobile) {
	.survey-form {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		color: var(--grey-dark);
		min-width: 300px;
		margin: 0 15px;
	}

	.survey-form__validate-button {
		width: 100%;
		margin: 30px 0;
	}

	.survey-form__pass-button {
		width: 100%;
		margin: 30px 0;
	}
}
