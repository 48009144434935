@import "../../../core/theme/variables";

.attachment-list {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.attachment-list__content {
	display: flex;
	flex-wrap: wrap;
	padding-left: 50px;
	margin-top: 40px;
}

.attachment-list__psg-option {
	display: flex;
	align-items: center;
}

.attachment-list__psg-checkbox {
	width: auto
}

.attachment-list__psg-label{
	width: auto;
	margin-right: 15px;
	font-size: var(--font-text-xs);
	color: var(--grey-dark);
}

.attachment__button {
	margin-top: 40px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.attachment-list {
		margin-left: 0;
	}

	.attachment-list__content {
		display: flex;
		padding: 0 15px;
		justify-content: center;
		max-height: 70vh;
		overflow: scroll;
		margin-top: -25px;
	}

	.attachment__button {
		position: fixed;
		width: 90%;
		border-radius: 4px;
		bottom: 10px;
		left: 5%;
	}
}
