@import "../../../../../core/theme/variables";

.user-table-row--header {
	background: transparent;
	box-shadow: none;
	font-weight: bold;
	padding: 0 50px;
	min-height: max-content;
}

.user-row--cell {
	display: flex;
	align-items: center;
}
