.modify-attachment-consultation-page__title {
	font-size: var(--font-h1);
	color: var(--black);
	margin-bottom: 80px;
	text-align: center;
}

.modify-attachment-consultation-page__content {
	padding: 0 130px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modify-attachment-consultation-page__attachment-list {
	width: 100%;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1250px;
}

.modify-attachment-consultation-page__attachment-card {
	background: white;
	flex: 0 0 210px;
	margin: 0 32px 40px 0;
	border-radius: 10px;
	box-shadow: inset 0 0 0 2px var(--light-purple);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	min-height: 110px;
}

.modify-attachment-consultation-page__attachment-card__content {
	flex: 1;
	padding: 20px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: var(--black);
	font-size: 1.7rem;
	opacity: 0.5;
	cursor: pointer;
}

.modify-attachment-consultation-page__attachment-card__select {
	flex: 0 0 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 2px solid var(--light-purple);
	color: var(--blue-dark);
	font-size: 1.5rem;
	cursor: pointer;
}

.modify-attachment-consultation-page__attachment-card--selected {
	box-shadow: inset 0 0 0 2px var(--blue-dark);
}

.modify-attachment-consultation-page__attachment-card__content--selected {
	opacity: 1;
}
.modify-attachment-consultation-page__attachment-card__select--selected {
	border-color: var(--blue-dark);
	background: var(--blue-dark);
	color: white;
}
