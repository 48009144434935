@import "../../../core/theme/variables";

.multi-search__field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.multi-search__field__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	margin-top: 12px;
}

.multi-search__field__label {
	color: var(--dark);
	font-size: var(--font-text-sm);
	margin-top: 24px;
}

.multi-search__field__input {
	width: 240px;

	&:not(:last-child) {
		margin-right: 24px;
	}
}

.multi-search__field__input .search-field__selected {
	margin-top: 0;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.multi-search__field__container {
		flex-direction: column;
	}

	.multi-search__field__input {
		width: 100%;

		&:not(:last-child) {
			margin-right: 0;
			margin-bottom: 24px;
		}
	}
}
