.feedback-menu__button {
	position: fixed;
	top: 165px;
	right: 0;
	background-color: var(--green-jade);
	border: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	z-index: 1;
	height: 120px;
	max-width: 48px;
	display: flex;
	flex-direction: row;

}

.feedback-modal__button__text {
	writing-mode: vertical-rl;
	text-orientation: mixed;
}
