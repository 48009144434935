@import "../../../../core/theme/variables";

.daily-dl-page {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 50px 50px 20px 50px;
}

.daily-dl-page__controls {
	margin-top: 40px;
	display: flex;
	font-size: var(--font-text-m);
	> :first-child {
		margin-right: 40px;
	}
}

.daily-dl-page__title {
	font-size: var(--font-h1);
	color: var(--grey-dark);
	margin-bottom: 40px;
}

.daily-dl-page__dl-information {
	flex: 0 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 800px;
	border-radius: 16px;
	overflow: hidden;
}

.daily-dl-page__service-information {
	background: var(--blue-dark);
	display: flex;
	padding: 30px;
	align-items: center;
}

.daily-dl-page__service-number {
	margin-right: 30px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	min-height: 100px;
	background: var(--pink);
	color: var(--blue-dark);
	&__title {
		color: var(--black);
		font-size: var(--font-text-sm);
		margin-bottom: 10px;
	}
	&__number  {
		font-size: var(--font-text-xxl);
		font-weight: bold;
		display: flex;
		justify-content: center;
	}
}

.daily-dl-page__attachment {
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__title {
		font-size: var(--font-text-m);
		margin-bottom: 10px;
	}

	&__name {
		font-size: var(--font-text-xxl);
		font-weight: bold;
	}
}

.daily-dl-page__date-information {
	background: var(--blue-very-light);
	display: flex;
	padding: 30px 25px;
	justify-content: space-between;
}

.daily-dl-page__date-information__day {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: white;
	padding: 15px 30px;
	border-radius: 16px;
	flex: 1;
	margin-right: 30px;
	&__title {
		display: flex;
		justify-content: center;
		font-size: var(--font-text-m);
		color: var(--black);
		margin-bottom: 5px;
	}
	&__service-date {
		display: flex;
		justify-content: center;
		color: var(--blue-light);
		font-size: var(--font-text-xl);
		font-weight: bold;
	}
}

.daily-dl-page__date-information__timetable {
	background: white;
	padding: 15px 30px;
	border-radius: 16px;
	flex: 1;
	&__title {
		display: flex;
		justify-content: center;
		font-size: var(--font-text-m);
		color: var(--black);
		margin-bottom: 5px;
	}
}

.daily-dl-page__date-information__timetable__hours {
	display: flex;
	align-items: center;
	font-size: var(--font-text-m);
	.separator {
		display: none;
	}
	&__start {
		color: var(--blue-light);
		padding: 0 10px;
		display: inline-block;
		font-size: var(--font-text-xl);
		font-weight: bold;
	}
	&__end {
		color: var(--blue-light);
		padding: 0 10px;
		display: inline-block;
		font-size: var(--font-text-xl);
		font-weight: bold;
	}
	&__duration{
		border: 2px solid var(--blue-light);
		border-radius: 30px;
		padding: 10px 15px;
		font-weight: bold;
	}
}

.daily-dl-page__empty-dl {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	font-size: var(--font-text-xs);
	&__description {
		margin-right: 10px;
	}
	&__link {
		text-decoration: underline;
	}
}

.daily-dl-page--invalid {
	.daily-dl-page__dl-information {
		opacity: 0.6;
	}
	.daily-dl-page__service-number__number {
		height: 45px;
		width: 73px;
		background: var(--blue-light);
	}
	.daily-dl-page__attachment {
		color: transparent;
		flex: 1;
		background: white;
		height: 45px;
	}
	.daily-dl-page__date-information__day__service-date {
		height: 25px;
		width: 100%;
		background: var(--blue-light);
	}
	.daily-dl-page__date-information__timetable__hours {
		height: 25px;
		width: 100%;
		background: var(--blue-light);
		color: transparent;
	}
	.daily-dl-page__date-information__timetable__hours__duration {
		display: none;
	}

	.daily-dl-page__controls {
		display: none;
	}
	.daily-dl-page__empty-dl {
		flex-direction: column;
	}
	.daily-dl-page__empty-dl__bottom-wrapper {
		flex-direction: row;
		display: flex;
		> :first-child {
			margin-right: 40px;
		}
	}
	.daily-dl-page__empty-dl__description {
		margin-bottom: 15px;
		margin-left: 100px;
	}
	.daily-dl-page__empty-dl__link {
		background: var(--blue-dark);
		color: white;
		text-decoration: none;
		padding: 16px 16px;
		text-align: center;
		border-radius: 4px;
	}
}

@media screen and (max-width: $breakpoint-mobile) {
	.daily-dl-page {
		padding: 0 0 50px 0;
	}
	.daily-dl-page__title {
		font-size: var(--font-h2);
		margin: 25px 0;
	}

	.daily-dl-page__dl-information {
		border-radius: 0;
	}

	.daily-dl-page__service-information {
		padding: 15px;
	}

	.daily-dl-page__service-number {
		min-height: auto;
		margin-right: 15px;
		padding: 15px 8px;
		&__title {
			text-align: center;
			white-space: nowrap;
			font-size: var(--font-text-mobile-m);
			font-weight: bold;
		}
		&__number {
			font-size: var(--font-text-mobile-xxl);
		}
	}
	.daily-dl-page__attachment {
		&__title {
			font-size: var(--font-text-mobile-m);
			font-weight: bold;
		}
		&__name {
			font-size: var(--font-text-mobile-xl);
		}
	}

	.daily-dl-page__date-information {
		flex-direction: column;
		padding: 25px 15px;
	}

	.daily-dl-page__date-information__day, .daily-dl-page__date-information__timetable {
		margin-right: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 10px 15px;
		flex: 50px 0;
		&__title {
			margin-bottom: 0;
			flex: 100px 0;
			justify-content: flex-start;
		}

	}
	.daily-dl-page__date-information__day__service-date {
		font-size: var(--font-text-mobile-m);
	}
	.daily-dl-page__date-information__timetable {
		margin-top: 15px;
	}
	.daily-dl-page__date-information__timetable__hours {
		.from, .to {
			display: none;
		}
		.separator {
			display: block;
			padding: 0 2px;
		}
		&__start {
			padding: 0;
			font-size: var(--font-text-mobile-m);
		}

		&__end {
			padding: 0;
			font-size: var(--font-text-mobile-m);
			margin-right: 10px;
		}
		&__duration {
			font-size: var(--font-text-mobile-m);
			padding: 4px 10px;
		}
	}
	.daily-dl-page__controls {
		justify-content: space-between;
		width: 100%;
		margin-top: 0;
		padding: 0 15px 25px 15px;
		background: var(--blue-very-light);
		> :first-child {
			margin-right: 15px;
		}
		.button {
			font-weight: bold;
			flex: 1;
		}
		.button--outline {
			background: transparent;
			flex: 1;
		}
	}
}
