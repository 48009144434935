@import "../../../../../../core/theme/variables";

.dl-document-card__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	justify-content: space-between;
	background-color: var(--white);
	width: 198px;
	border-radius: 16px;
	cursor: pointer;
	margin-right: 16px;
}

.dl-document-card__container--disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.dl-document-card__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
}

.dl-document-card__icon-wrapper {
	background-color: var(--light-green);
	border-radius: 50%;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 12px 0;
	transform: scale(1.2);
}

.dl-document-card__icon {
	width: 20px;
	height: 26px;
}

.dl-document-card__text {
	display: flex;
	flex-direction: column;
	align-items: center;

	.dl-document-card__main {
		color: black;
		font-size: var(--font-text-s);
		margin-bottom: 7px;
		margin-top: 5px;
		font-weight: bold;
	}

	.dl-document-card__secondary {
		color: var(--grey-dark);
		font-size: var(--font-text-xs);
		font-weight: initial;
		margin-bottom: 20px;
		text-align: center;
	}
}

.dl-document-card__button {
	width: 100%;
	border-radius: 0 0 16px 16px;
	font-size: var(--font-text-s);
}

.dl-document-card__button--disabled {
	cursor: not-allowed;
}
