@import "../../../../../../core/theme/variables";
.car-selector {
	display: grid;
	grid-template-columns: repeat(5, 70px);
	grid-auto-rows: 70px;
	gap: 24px 32px;
	font-size: var(--font-text-l);

	&.car-selector--error {
		border: 2px solid var(--red-light);
		border-radius: 8px;
	}
}

.car-selector.location,
.car-selector.locationStart,
.car-selector.locationEnd,
.car-selector.doorSide,
.car-selector.stationLocation,
.car-selector.communicationMode,
.car-selector.messageType,
.car-selector.equipmentA,
.car-selector.equipmentB,
.car-selector.equipmentNight,
.car-selector.nightTrain,
.car-selector.pattern,
.car-selector.period,
.car-selector.reasonAlarmSignal,
.car-selector.reasonOrderOrNotice,
.car-selector.reasonOrderOrNoticeSecurity,
.car-selector.reasonOrderOrNoticeTraffic {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
}

.car-selector__item {
	align-items: center;
	justify-items: center;
	box-sizing: border-box;
	border-radius: 8px;
}

.car-selector__item.input-field {
	border-color: white;
	margin-top: 0;
	min-width: 200px;
	width: auto;
}

.car-selector__item.location,
.car-selector__item.locationStart,
.car-selector__item.locationEnd,
.car-selector__item.doorSide,
.car-selector__item.stationLocation,
.car-selector__item.communicationMode,
.car-selector__item.messageType,
.car-selector__item.equipmentA,
.car-selector__item.equipmentB,
.car-selector__item.equipmentNight,
.car-selector__item.nightTrain,
.car-selector__item.pattern,
.car-selector__item.period,
.car-selector__item.reasonAlarmSignal,
.car-selector__item.reasonOrderOrNotice,
.car-selector__item.reasonOrderOrNoticeSecurity,
.car-selector__item.reasonOrderOrNoticeTraffic {
	min-width: fit-content;
	font-size: var(--font-text-m);
	padding: 0 20px;
	min-height: 55px;
}

.car-selector__item--selected {
	background-color: var(--klein-blue);
	color: white;
	box-shadow: var(--big-shadow);
}

.car-selector__item__field{
	appearance: none;
	display: none;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.car-selector {
		grid-template-columns: repeat(4, 60px);
		grid-auto-rows: 60px;
		gap: 16px;
		font-size: var(--font-text-mobile-l);
		justify-content: center;
	}

	.car-selector.location,
	.car-selector.locationStart,
	.car-selector.locationEnd,
	.car-selector.doorSide,
	.car-selector.stationLocation,
	.car-selector.communicationMode,
	.car-selector.messageType,
	.car-selector.equipmentA,
	.car-selector.equipmentB,
	.car-selector.equipmentNight,
	.car-selector.nightTrain,
	.car-selector.pattern,
	.car-selector.period,
	.car-selector.reasonAlarmSignal,
	.car-selector.reasonOrderOrNotice,
	.car-selector.reasonOrderOrNoticeSecurity,
	.car-selector.reasonOrderOrNoticeTraffic {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.car-selector__item.location,
	.car-selector__item.locationStart,
	.car-selector__item.locationEnd,
	.car-selector__item.doorSide,
	.car-selector__item.stationLocation,
	.car-selector__item.communicationMode,
	.car-selector__item.messageType,
	.car-selector__item.equipmentA,
	.car-selector__item.equipmentB,
	.car-selector__item.equipmentNight,
	.car-selector__item.nightTrain,
	.car-selector__item.pattern,
	.car-selector__item.period,
	.car-selector__item.reasonAlarmSignal,
	.car-selector__item.reasonOrderOrNotice,
	.car-selector__item.reasonOrderOrNoticeSecurity,
	.car-selector__item.reasonOrderOrNoticeTraffic {
		min-width: fit-content;
		font-size: var(--font-text-m);
		padding: 0 15px;
		min-height: 55px;
		flex: 0 1;
	}
}
