.delay-header {
	display: flex;
	justify-content: center;
	align-items: center;
}

.delay-cell {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-h4);
}

.input-delay-cell {
	text-align: center;
	font-size: var(--font-h4);
	border-radius: 12px;
	padding: 16px 8px;
	border: 2px solid transparent;
	width: 5em;
	&:focus {
		border: 2px solid #1046D5;
	}
}

.archived-controls {
	display: flex;
	align-items: center;
}
