.special-notice-details-modal {
	max-width: 640px;
	width: 640px;
	max-height: 640px;
	padding: 32px;
}

.special-notice-details-modal__icon {
	position: absolute;
	right: 12px;
	top: 12px;
	font-size: var(--font-h3);
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.special-notice-details-modal__container {
	display: flex;
	flex-direction: column;
}

.special-notice-details-modal__container__header {
	text-align: center;
}

.special-notice-details-modal__container__header__title {
	margin-top: 24px;
	font-size: var(--font-h2);
	font-weight: bold;
	color: var(--grey-dark);
}

.special-notice-details-modal__container__header__icon {
	text-align: center;
	width: 58px;
	height: 58px;
	margin-top: 24px;
	// margin-left: 150px;
}

.special-notice-details-modal__container__content {
	display: flex;
	flex-direction: column;
	left: 0;
	color: var(--grey-dark);
}

.special-notice-details-modal__container__content__title {
	font-size: 17px;
	font-weight: bold;
	line-height: 22px;
	margin-top: 24px;
}

.special-notice-details-modal__container__content__title--no-margin {
	margin-top: 0;
}

.special-notice-details-modal__container__content__block {
	display: flex;
	flex-direction: column;
	font-size: 15px;
	line-height: 20px;
}

.special-notice-details-modal__container__content--row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 24px;
}

.special-notice-details-modal__container__content--right {
	margin-left: 32px;
}

.special-notice-details-modal__container__content__block--bold {
	font-weight: bold;
}

.special-notice-details-modal__container__content__block__missions {
	display: flex;
	flex-direction: row;
}

.special-notice-details-modal__container__content__block__missions__item {
	text-transform: uppercase;

	&:not(:last-child) {
		margin-right: 16px;
	}
}

.special-notice-details-modal__container__content__block__schedule {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.special-notice-details-modal__container__content__block__schedule__icon {
	width: 0.8em;
	height: 0.8em;
	margin-left: 16px;
	margin-right: 16px;
}

.special-notice-details-modal__container__content__block__title {
	font-size: 17px;
	color: var(--grey-medium-dark);
	margin-bottom: 16px;
}

.special-notice-details-modal__container__content__block__message {
	max-height: 160px;
	overflow: auto;
	font-size: 17px;
	color: var(--grey-medium-dark);
}
