.thanks-modal__content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.thanks-modal__content__title {
	color: var(--grey-dark);
	font-weight: bold;
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-l);
	text-align: center;
	margin-bottom: 15px;
}

.thanks-modal__content__text {
	color: var(--grey-dark);
	display: flex;
	flex: 1;
	font-size: var(--font-text-s);
	text-align: start;
	margin-bottom: 15px;

}

.thanks-modal__close-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.thanks-modal__content__icon {
	padding-top: 10px;
	padding-bottom: 25px;
}

.thanks-modal__content__thumb-icon {
	width: 7em;
	height: 6em;
}

.thanks-modal__button {
	display: flex;
	flex-direction: row;
	justify-content: center
}

.feedback-modal__form__container__content__checkbox {
	display: flex;
	align-items: center;
	gap: 8px;
}

.feedback-modal__form__container__content__checkbox input[type='checkbox'] {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.feedback-modal__form__container__content__checkbox label {
	font-size: var(--font-text-s);
	color: var(--grey-dark);
}
