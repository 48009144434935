.change-attachment-banner {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background: var(--blue-very-light);
	font-size: var(--font-h2);
	color: var(--black);
	font-weight: bold;
	min-height: 90px;
	padding: 20px;
}

.change-attachment-banner__attachment-name {
	margin: 0 8px;
	color: var(--blue);
	font-size: var(--font-h2);
}

.change-attachment-banner__icon {
	color: var(--blue);
}

.change-attachment-banner__link {
	font-size: 16px;
}
