@import "../../theme/variables";

.two-choices-buttons {
	width: 100%;
	font-size: var(--font-h3);
	color: var(--grey-dark);
}

.two-choices-buttons__label {
	font-weight: bold;
}

.two-choices-buttons__choices {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 25px;

	.two-choices-buttons__choices__radio {
		font-size: var(--font-text-s);
		padding: 16px;
		border-radius: 8px;
		margin-right: 32px;
	}
}

@media only screen and (max-width: $breakpoint-mobile) {
	.two-choices-buttons__choices {
		flex-direction: column;
	}

	.two-choices-buttons__choices {
		.two-choices-buttons__choices__radio {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}

		.radio-button__label {
			justify-content: flex-start;
		}
	}
}
