@import "../../../core/theme/variables";

.notification-modal__form__container {
	display: flex;
	flex-direction: column;
}

.notification-modal__form__container__header{
	display: flex;
	flex-direction: column;
}

.notification-modal__form__container__header__title {
	color: var(--blue-intense);
	font-weight: bold;
	text-transform: uppercase;
	justify-self: center;
	flex: 1;
	font-size: var(--font-text-l);
	text-align: center;
	margin-bottom: 15px;
}

.notification-modal__form__container__header__subtitle {
	display: block;
	color: var(--grey-dark);
	font-size: var(--font-text-m);
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	padding: 16px;
	text-align: center;
}

.notification-modal__form__icon {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	background: none;
	appearance: none;
	border: none;

	&:focus {
		outline: none;
	}
}

.notification-modal__form__container__content{
	display: flex;
	flex-direction: column;
	font-size: var(--font-text-m);
	padding: 40px 10px;
	justify-content: center;
	align-items: center;
}

.notification-modal__form__container__content__label{
	color: var(--grey-dark)
}

.notification-modal__form__button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
}

.notification-modal__form__container__content__form {
	display: flex;
	margin: 20px;
}

.notification-modal__field__item {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--true-white);
	padding: 12px;
	border-radius: 8px;
	cursor: pointer;
	min-width: 119px;
	margin: 0 8px;

	input[type="checkbox"] {
		-webkit-appearance: none;
		appearance: none;
		outline: 1px solid gray;
		background-color: white;
		font-size: var(--font-text-s);
		text-align: center;
		line-height: 1.2em;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	input[type="checkbox"]:checked:after {
		content: "✔";
		color: var(--green-jade);
	}
}

.notification-modal__field__text {
	margin: 8px;
}

.notification-modal__field__item--checked {
	background-color: var(--klein-blue);
	box-shadow: var(--big-shadow);

	.notification-modal__field__text {
		color: white;
	}
}

.notification-modal__field__item--readonly {
	background-color: var(--blue-very-light);
	color:var(--blue-light);
}





