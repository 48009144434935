.observation-time {
	width: 100%;
}

.observation-time__label {
	font-size: var(--font-h3);
	color: var(--grey-dark);
	font-weight: bold;
}

.observation-time__input {
	margin-top: 25px;
}
