.satisfaction-survey-thx-modal {
	height: 20vh;
	padding: 12px;
}

.satisfaction-survey-thx-modal__container {
	width: 100%;
	height: 100%;
}

.satisfaction-survey-thx-modal__container__icon-close {
	display: flex;
	flex-direction: row-reverse;
}

.icon-close {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.satisfaction-survey-thx-modal__content-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 78%;
}

.satisfaction-survey-thx-modal__content-wrapper__content {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.satisfaction-survey-thx-modal__content-wrapper__icon-thx {
	width: 32px;
	height: 45px;
	cursor: pointer;
	margin-right: 17px;
}

.satisfaction-survey-thx-modal__content-wrapper__text {
	color: var(--black);
	font-size:  var(--font-h2);
	font-weight: bold;
}
