.custom-modal {
	display: flex;
	position: relative;
	flex-direction: column;
	background: var(--white);
	min-height: 200px;
	min-width: 400px;
	max-width: 480px;
	padding: 60px 30px;
	border-radius: 6px;
}
