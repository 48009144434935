.purge-banner {
	background: var(--blue-very-light-transp);
	display: flex;
	padding: 25px;
}

.purge-banner__oldest-dl {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	padding-right: 60px;
	border-right: 2px solid #00AA91;
}
.purge-banner__oldest-dl__label, .purge-banner__last-purge__label {
	color : var(--blue-intense);
	font-size: 15px;
	margin-right: 10px;
}

.purge-banner__oldest-dl__date, .purge-banner__last-purge__date {
	color: var(--grey-dark);
	font-size: 15px;
}

.purge-banner__last-purge {
	flex: 1;
	margin-left: 60px;
}
