@import "../../../core/theme/variables";

.disable-line-sheets__table {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.disable-line-sheets__table__rows-wrapper__rows {
	box-shadow: 0 8px 24px rgba(10, 0, 130, 0.06);
	border-radius: 16px;
	background: white;
	min-height: 70px;
	color: var(--black);
	font-size: var(--font-text-m);
	font-weight: bold;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	padding: 24px 0 24px 0;
	margin-bottom: 16px;

	&:not(:last-child) {
		margin-bottom: 15px;
	}
}

.disable-line-sheets__table__headers {
	color: var(--black);
	font-size: var(--font-text-sm);
	background: transparent;
	box-shadow: none;
	font-weight: bold;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	margin-bottom: 32px;
	margin-top: 40px;
}

.drive-log-row__cell {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.disable-line-sheets__table__headers__cell {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: var(--font-text-m);
	margin-left: 20%;
}

.disable-line-sheets__table__rows-wrapper__rows__line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 20%;
}

.disable-line-sheets__table__rows-wrapper__rows__line__text {
	color: var(--black);
	font-size: var(--font-text-sm);
	font-weight: bold;
}
.disable-line-sheets__table__cells__text {
	color: var(--black);
	font-size: var(--font-text-sm);
	font-weight: normal;
	align-items: center;
	margin-left: 20%;
	// justify-content: center;
	display: flex;
}

.disable-line-sheets__table__rows-wrapper__rows__line__icon {
	width: 24px;
	height: 24px;
	margin: 0 25px 0 25px;
}
