@import "../../../../core/theme/variables";

.dl-list-page {
	padding: 60px 65px;
}
.dl-list-page__title {
	color: var(--black);
	font-size: var(--font-h2);
	margin-bottom: 30px;
}

.dl-list-page__controls {
	display: flex;
	justify-content: center;
}


@media screen and (max-width: $breakpoint-mobile) {
	.dl-list-page {
		padding: 25px 15px;
	}
	.dl-list-page__title {
		text-align: center;
	}
	.dl-list-page .dl-month__title {
		margin: 0 -15px;
	}
}
