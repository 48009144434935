@import "../../../../../core/theme/variables";

.observation-checkbox-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.observation-checkbox-text {
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	margin-left: 8px;
}

.observation-checkbox-text__code {
	margin: 0 10px;
	color: var(--blue-light);
	font-weight: bold;
	background-color: var(--secondary-lilas);
	border-radius: 10px;
	padding: 15px;
}

.observation-checkbox-input {
	margin: 0 10px;
	width: 24px;
	height: 24px;
}

input[type="checkbox"] {
	-webkit-appearance: none;
	appearance: none;
	outline: 2px solid var(--klein-blue);
	border-radius: 2px;
	background-color: white;
	font-size: var(--font-text-s);
	text-align: center;
	line-height: 1.2em;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

input[type="checkbox"]:checked {
	outline: none;
	box-shadow: 0 3px 5px rgba(16, 70, 213, 0.2)
}

input[type="checkbox"]:checked:after {
	content: "✔";
	color: var(--green-jade);
}
