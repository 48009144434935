.complex-dls__title {
	font-size: var(--font-h2);
	color: var(--grey-dark);
	margin-bottom: 10px;
}

.complex-dls__controls {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}
