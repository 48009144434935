@import "../../../core/theme/variables";

.observation-details {
	display: flex;
	flex-direction: column;
	font-size: var(--font-text-m);

	.content__row {
		display: grid;
		grid-template-columns: 1fr 3fr;
		padding: 8px 0;
		color: var(--grey-dark);

		.content__row__field-name {
			font-weight: bold;
		}
	}

	.content__row:first-child {
		padding-top: 24px;
	}
}

.content__row__field-value {
	max-width: 600px;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.observation-details {
		margin-bottom: 40px;

		.content__row {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid var(--blue-light);
			padding: 25px 0;
			margin: 0 20px;

			.content__row__field-name {
				margin-bottom: 10px;
			}
		}
	}
}
