@import "../../core/theme/variables";

.terms-of-service {
	padding: 0 320px 60px 320px;
	font-size: 	var(--font-text-xs);
	color: var(--black);
	line-height: 20px;
}

.terms-of-service__app__name {
	font-size: var(--font-h3);

	&.digiplan {
		margin-top: 60px;
	}
}

.terms-of-service__tasks, .terms-of-service__authorized-list {
	li {
		list-style: inside;
		padding-left: 5px;
	}
}

.terms-of-service__paragraph {
	margin-top: 20px;
}

.terms-of-service__link {
	text-decoration: underline;
	color: var(--blue);
}

.terms-of-service--bold {
	font-weight: bold;
}

@media screen and (max-width: $breakpoint-mobile){
	.terms-of-service {
		padding: 0 15px;
	}
}
