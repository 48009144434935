@import "../../../core/theme/variables";

.observation-list__subheader {
	display: flex;
	align-items: center;
}

.observation-list__wrapper {
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 40px;
}

.observation-list__subheader__code {
	margin-left: 20px;
	color: var(--blue-light);
	background-color: var(--secondary-lilas);
	border-radius: 10px;
	padding: 15px;
}

@media only screen and (max-width: $breakpoint-mobile) {
	.observation-list__wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 20px;
		overflow: auto;
		max-height: 60vh;
	}
}
